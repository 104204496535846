import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';

import {WEB_PREFERENCE_CENTER} from '../../consts/tnc/tncTypes';
import appRouterService from '../../services/route/appRouterService';
import tncService from '../../services/tncService';
import {
    makeSelectAcceptedTncByType,
    selectConsumerAcceptedTnc,
    selectConsumerTnc,
} from '../../state/selectors/consumer';
import {selectAggregatedSettings} from '../../state/selectors/consumerAggregatedSettings';
import DataCollectionPage from './DataCollectionPage/DataCollectionPage';
import TermsOfUsePage from './TermsOfUsePage/TermsOfUsePage';

const TNC = () => {
    const aggregatedSettings = useSelector(selectAggregatedSettings);
    const termsOfUse = useSelector(selectConsumerTnc);
    const acceptedTermsOfUse = useSelector(selectConsumerAcceptedTnc);
    const acceptedTncPrefCenter = useSelector(makeSelectAcceptedTncByType(WEB_PREFERENCE_CENTER));

    const isAggregatedSettingsLoaded = !!aggregatedSettings;
    const {allNonMandatoryTermsReviewed, allMandatoryTermsApproved} = aggregatedSettings || {};
    const isUpdatedTnc = !!acceptedTncPrefCenter;

    useEffect(() => {
        if (allMandatoryTermsApproved && !allNonMandatoryTermsReviewed && !isUpdatedTnc) {
            appRouterService.forwardToTncDetailsPage(WEB_PREFERENCE_CENTER);
        }
    }, [allMandatoryTermsApproved, allNonMandatoryTermsReviewed, isUpdatedTnc]);

    useEffect(() => {
        if (!isAggregatedSettingsLoaded) return;

        if (!allMandatoryTermsApproved || !allNonMandatoryTermsReviewed) {
            tncService.getTncData(null, appRouterService.forwardToErrorPage);
        } else {
            appRouterService.forwardToHomePageIfNeed();
        }
    }, [isAggregatedSettingsLoaded, allMandatoryTermsApproved, allNonMandatoryTermsReviewed]);

    if (!termsOfUse || !acceptedTermsOfUse || !isAggregatedSettingsLoaded) return;

    if (!allMandatoryTermsApproved) {
        return <TermsOfUsePage />;
    }
    if (allNonMandatoryTermsReviewed) return;

    if (isUpdatedTnc) {
        return <DataCollectionPage />;
    }

    return;
};

export default TNC;
