import * as storageKeys from '@/consts/app/storageKeys';
import {selectConsumerId} from '@/state/selectors/consumer';
import {getState} from '@/state/store';

import localStorageClient from './localStorageClient';
import sessionStorageClient from './sessionStorageClient';

const getConsumerItem = (key) => {
    const consumerId = getConsumerId();
    const data = localStorageClient.getEncodedItem(key);

    return data?.[consumerId];
};
const setConsumerItem = (key, data) => {
    const consumerId = getConsumerId();
    const dataFromStore = localStorageClient.getEncodedItem(key) || {};
    const newData = {...dataFromStore, ...{[consumerId]: data}};

    localStorageClient.setDecodedItem(key, newData);
};

const getConsumerId = () => selectConsumerId(getState());

const getAppInstanceIdFromStorage = () => localStorageClient.getItem(storageKeys.APP_INSTANCE_ID);
const setAppInstanceIdToStorage = (data) => localStorageClient.setItem(storageKeys.APP_INSTANCE_ID, data);

const setAuthDataToStorage = (data) => localStorageClient.setDecodedItem(storageKeys.AUTH_DATA, data);
const removeAuthDataFromStorage = () => localStorageClient.removeItem(storageKeys.AUTH_DATA);
const getAuthDataFromStorage = () => localStorageClient.getEncodedItem(storageKeys.AUTH_DATA);

const setLanguageCodeToLocalStorage = (data) => localStorageClient.setDecodedItem(storageKeys.LANGUAGE_CODE, data);
const getLanguageCodeFromLocalStorage = () => localStorageClient.getEncodedItem(storageKeys.LANGUAGE_CODE);

const setSubscriptionToLocalStorage = (data) =>
    localStorage.setItem(storageKeys.PUSH_NOTIFICATION_SUBSCRIPTION_DATA, data);
const getSubscriptionFromLocalStorage = () => localStorage.getItem(storageKeys.PUSH_NOTIFICATION_SUBSCRIPTION_DATA);
const removeSubscriptionFromLocalStorage = () =>
    localStorageClient.removeItem(storageKeys.PUSH_NOTIFICATION_SUBSCRIPTION_DATA);

const getFeedbackFromLocalStorage = () => localStorageClient.getItem(storageKeys.FEEDBACK);
const setFeedbackToLocalStorage = (data) => localStorageClient.setItem(storageKeys.FEEDBACK, data);

const getPermissionDeniedLocalStorage = () => localStorageClient.getItem(storageKeys.IS_NOTIFICATION_PERMISSION_DENIED);
const setPermissionDeniedLocalStorage = (data) =>
    localStorageClient.setItem(storageKeys.IS_NOTIFICATION_PERMISSION_DENIED, data);

const getMissingDeviceNotificationDataFromLocalStorage = () =>
    localStorageClient.getItem(storageKeys.MISSING_DEVICE_NOTIFICATION);
const setMissingDeviceNotificationDataToLocalStorage = (data) =>
    localStorageClient.setItem(storageKeys.MISSING_DEVICE_NOTIFICATION, data);

const getSelectedDeviceCodentifyFromLocalStorage = () =>
    localStorageClient.getEncodedItem(storageKeys.SELECTED_DEVICE_CODENTIFY);
const setSelectedDeviceCodentifyToLocalStorage = (data) =>
    localStorageClient.setDecodedItem(storageKeys.SELECTED_DEVICE_CODENTIFY, data);

const getMarketCodeFromLocalStorage = () => localStorageClient.getItem(storageKeys.MARKET_CODE);
const setMarketCodeToLocalStorage = (data) => localStorageClient.setItem(storageKeys.MARKET_CODE, data);

const getHasSeenWelcomeScreen = () => getConsumerItem(storageKeys.HAS_SEEN_WELCOME_SCREEN);
const setHasSeenWelcomeScreen = () => setConsumerItem(storageKeys.HAS_SEEN_WELCOME_SCREEN, true);

const getIsGetStartedLanguageSelectDismissed = () =>
    sessionStorageClient.getItem(storageKeys.IS_GET_STARTED_LANGUAGE_SELECT_DISMISSED);
const setIsGetStartedLanguageSelectDismissed = (data) =>
    sessionStorageClient.setItem(storageKeys.IS_GET_STARTED_LANGUAGE_SELECT_DISMISSED, data);

export default {
    setMarketCodeToLocalStorage,
    getMarketCodeFromLocalStorage,
    getAppInstanceIdFromStorage,
    getAuthDataFromStorage,
    getLanguageCodeFromLocalStorage,
    getSubscriptionFromLocalStorage,
    removeAuthDataFromStorage,
    removeSubscriptionFromLocalStorage,
    setAppInstanceIdToStorage,
    setAuthDataToStorage,
    setLanguageCodeToLocalStorage,
    setSubscriptionToLocalStorage,
    getFeedbackFromLocalStorage,
    setFeedbackToLocalStorage,
    getMissingDeviceNotificationDataFromLocalStorage,
    setMissingDeviceNotificationDataToLocalStorage,
    getSelectedDeviceCodentifyFromLocalStorage,
    setSelectedDeviceCodentifyToLocalStorage,
    getPermissionDeniedLocalStorage,
    setPermissionDeniedLocalStorage,
    getHasSeenWelcomeScreen,
    setHasSeenWelcomeScreen,
    getIsGetStartedLanguageSelectDismissed,
    setIsGetStartedLanguageSelectDismissed,
};
