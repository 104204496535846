import React from 'react';

import {getLocalizedStrings} from '@/services/localization/localizationService';

import amplitudeHomePageTracking from '../../../../../services/amplitude/amplitudeHomePageTracking';
import appRouterService from '../../../../../services/route/appRouterService';
import {QUICK_START_GUIDE_ACTION_TYPE} from '../consts/howToUseActionTypes';
import HomePageForYouContainer from './HomePageForYouContainer/HomePageForYouContainer';

const HomePageLearnHowToUseItem = (props) => {
    const {banner, deviceType, isGradient, defaultImage, action: actionType} = props;
    const {titleKey, subTitleKey, imagePath, videoPath, isDarkBannerText, amplitudeTrackingValue} = banner;

    const isQuickStartGuide = actionType === QUICK_START_GUIDE_ACTION_TYPE;
    const localizedStrings = getLocalizedStrings();

    const onClick = () => {
        amplitudeHomePageTracking.trackForYouTileOpened(amplitudeTrackingValue);
        isQuickStartGuide
            ? appRouterService.forwardToQuickStartGuidePage(deviceType)
            : appRouterService.forwardToTipsAndTutorialsList(deviceType);
    };

    return (
        <HomePageForYouContainer
            imagePath={imagePath}
            videoPath={videoPath}
            defaultImage={defaultImage}
            onClick={onClick}
            isDarkText={isDarkBannerText}
            titleText={localizedStrings[titleKey]}
            subTitleText={localizedStrings[subTitleKey]}
            linearGradient={isGradient}
        />
    );
};

export default HomePageLearnHowToUseItem;
