import {AEM_SECTION_TYPES} from '@/consts/aem/aemSectionTypes';

import usePageSections from './usePageSections';

const useIsTopBannerEnabled = () => {
    const pageSections = usePageSections();
    const isTopBannerEnabled = pageSections.some(({type}) => type === AEM_SECTION_TYPES.TOP_BANNER);

    return isTopBannerEnabled;
};

export default useIsTopBannerEnabled;
