export const AUTH_DATA = 'a_d';
export const APP_INSTANCE_ID = 'a_i';
export const LANGUAGE_CODE = 'l_c';
export const PUSH_NOTIFICATION_SUBSCRIPTION_DATA = 'push_notification_s_d';
export const FEEDBACK = 'feedback';
export const MISSING_DEVICE_NOTIFICATION = 'missingDeviceNotification';
export const SELECTED_DEVICE_CODENTIFY = 's_d_c';
export const MARKET_CODE = 'm_c';
export const IS_NOTIFICATION_PERMISSION_DENIED = 'is_notification_permission_denied';
export const HAS_SEEN_WELCOME_SCREEN = 'hasSeenWelcomeScreen';
export const IS_GET_STARTED_LANGUAGE_SELECT_DISMISSED = 'isGetStartedLanguageSelectDismissed';
