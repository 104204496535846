import React from 'react';

import AemEditableText from '../../../../../../../components/Aem/AemEditableText/AemEditableText';
import {AppButton} from '../../../../../../../components/Button';
import tncService from '../../../../../../../services/tncService';
import {hideLoader, showLoader} from '../../../../../../../state/slices/loadersSlice';
import {dispatch} from '../../../../../../../state/store';

const SaveContinueButton = ({
    legals,
    onApproved,
    legalsSettingsNotNull,
    acceptedSettingList,
    itemName,
    isSecondary,
}) => {
    const onSaveButtonClick = async () => {
        dispatch(showLoader());

        await tncService.setPreferenceCenterSettings(legals, legalsSettingsNotNull, acceptedSettingList, onApproved);

        dispatch(hideLoader());
    };

    return (
        <AppButton className='mt-0' light primary={!isSecondary} secondary={isSecondary} onClick={onSaveButtonClick}>
            <AemEditableText pageName='tnc' itemName={itemName} />
        </AppButton>
    );
};

export default SaveContinueButton;
