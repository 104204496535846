import React from 'react';

import AemComponent from '@/components/Aem/AemComponent/AemComponent';
import EditableText from '@/components/Aem/EditableText/EditableText';
import {AppButton} from '@/components/Button';
import appConfig from '@/config/appConfig';
import useConfigExternalLink from '@/hooks/externalLink/useConfigExternalLink';
import {twx} from '@/utils/tailwindUtils';

import {HelpBlueIcon, LocationBlueIcon} from '../Icons';
import {ContentWrapper, MaxWidthContainer} from '../Layout';

const SupportAndStoreTemplate = (props) => {
    const {className, isTitleVisible, onNearestStoreClick} = props;

    const supportExternalLink = appConfig.getSupportExternalLink();
    const redirectToSupport = useConfigExternalLink(supportExternalLink);

    const storeExternalLink = appConfig.getIqosStoreExternalLink();
    const redirectToStore = useConfigExternalLink(storeExternalLink);

    const isSupportTitleVisible = supportExternalLink || storeExternalLink;

    return (
        <div className='w-full'>
            <ContentWrapper>
                {isSupportTitleVisible && isTitleVisible && (
                    <h2 className='mb-10 mt-10 text-24 md:mt-16'>
                        <AemComponent component={EditableText} pageName='ad' itemName='supportothertitle' />
                    </h2>
                )}
                <div
                    className={twx(
                        'flex w-full flex-col items-center gap-10 md:w-auto md:flex-row md:gap-6',
                        {'mt-[60px]': !isSupportTitleVisible || !isTitleVisible},
                        className
                    )}
                >
                    {supportExternalLink && (
                        <Section
                            icon={<HelpBlueIcon />}
                            titleKey='supportcontactustitle'
                            onClick={redirectToSupport}
                            buttonTextKey='supportcontactusbutton'
                        />
                    )}
                    {storeExternalLink && (
                        <Section
                            icon={<LocationBlueIcon />}
                            titleKey='supportfindstoretitle'
                            onClick={onNearestStoreClick ? onNearestStoreClick : redirectToStore}
                            buttonTextKey='supportfindstorebutton'
                        />
                    )}
                </div>
            </ContentWrapper>
        </div>
    );
};

const Section = (props) => {
    const {icon, titleKey, buttonTextKey, onClick} = props;

    return (
        <MaxWidthContainer className='w-full md:self-stretch'>
            {icon && icon}
            <h1 className='mt-3 flex-1 text-18 md:mt-4'>
                <AemComponent component={EditableText} pageName='ad' itemName={titleKey} />
            </h1>
            <AppButton secondary light onClick={onClick} className='mt-6 sm:min-w-[312px]'>
                <AemComponent component={EditableText} pageName='ad' itemName={buttonTextKey} />
            </AppButton>
        </MaxWidthContainer>
    );
};

export default SupportAndStoreTemplate;
