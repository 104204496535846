import React from 'react';

import {ButtonRaw} from '@/components/Button';
import RemoteIcon from '@/components/Icon/RemoteIcon';
import useConfigExternalLink from '@/hooks/externalLink/useConfigExternalLink';
import amplitudeHomePageTracking from '@/services/amplitude/amplitudeHomePageTracking';
import {getLocalizedStrings} from '@/services/localization/localizationService';
import {twx} from '@/utils/tailwindUtils';

const HomePageLoyaltyButtonsBar = ({buttons, className}) => {
    const localizedStrings = getLocalizedStrings();

    return (
        <div
            className={twx(
                'flex flex-row items-baseline justify-between rounded-xl bg-secondary px-4 py-2 md:bg-romance md:px-8 md:py-4',
                className
            )}
        >
            {buttons.map(({imagePath, titleKey, action, amplitudeTrackingValue}, index) => (
                <LoyaltyButton
                    key={index}
                    imagePath={imagePath}
                    title={localizedStrings[titleKey]}
                    action={action}
                    trackingValue={amplitudeTrackingValue}
                />
            ))}
        </div>
    );
};

const LoyaltyButton = ({imagePath, title, action, trackingValue}) => {
    const redirectToExternalLink = useConfigExternalLink(action);

    const onButtonClick = () => {
        if (!action) return;

        amplitudeHomePageTracking.trackLoyaltyButtonsBarClick(trackingValue);
        redirectToExternalLink();
    };

    return (
        <ButtonRaw
            className='flex w-full min-w-18 flex-col justify-start gap-2 p-0 md:flex-row md:justify-center'
            onClick={onButtonClick}
        >
            <div className='h-12 w-12 md:h-16 md:w-16'>
                <RemoteIcon className='h-full object-contain' imagePath={imagePath} />
            </div>
            <span className='text-xs max-w-full break-words text-center font-normal text-primary'>{title}</span>
        </ButtonRaw>
    );
};

export default HomePageLoyaltyButtonsBar;
