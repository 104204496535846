import {useEffect} from 'react';

import AMPLITUDE_EVENT_VALUES from '../../../../consts/amplitude/amplitudeEventValues';
import {AUTO_DELIVERY_ON_HOLD_HEADER_TITLE} from '../../../../consts/localization/localizationKeys';
import useDocumentTitle from '../../../../hooks/useDocumentTitle';
import useIsDesktopLayout from '../../../../hooks/useIsDesktopLayout';
import amplitudeAutodeliveryTracking from '../../../../services/amplitude/amplitudeAutoDeliveryTracking';
import {getLocalizedStrings} from '../../../../services/localization/localizationService';
import StatusOnHoldDesktop from './StatusOnHoldDesktop';
import StatusOnHoldMobile from './StatusOnHoldMobile';

const StatusOnHold = () => {
    const isDesktopLayout = useIsDesktopLayout();
    const localizedStrings = getLocalizedStrings();

    useDocumentTitle(localizedStrings[AUTO_DELIVERY_ON_HOLD_HEADER_TITLE]);

    useEffect(() => {
        amplitudeAutodeliveryTracking.trackAutoDeliveryOrderScreen(AMPLITUDE_EVENT_VALUES.ON_HOLD);
    }, []);

    const amplitudeEvent = (trackingValue) => {
        amplitudeAutodeliveryTracking.trackAutoDeliveryOrderButtonClick(trackingValue, AMPLITUDE_EVENT_VALUES.ON_HOLD);
    };

    return isDesktopLayout ? (
        <StatusOnHoldDesktop amplitudeEvent={amplitudeEvent} />
    ) : (
        <StatusOnHoldMobile amplitudeEvent={amplitudeEvent} />
    );
};

export default StatusOnHold;
