import React from 'react';

import Icon from '../Icon/Icon';

const ArrowSmallIcon = (props) => {
    return (
        <Icon width='24' height='24' fill='none' {...props}>
            <g clipPath='url(#clip0_3068_33391)'>
                <path
                    d='M9.29 6.70978C8.9 7.09978 8.9 7.72978 9.29 8.11978L13.17 11.9998L9.29 15.8798C8.9 16.2698 8.9 16.8998 9.29 17.2898C9.68 17.6798 10.31 17.6798 10.7 17.2898L15.29 12.6998C15.68 12.3098 15.68 11.6798 15.29 11.2898L10.7 6.69978C10.32 6.31978 9.68 6.31978 9.29 6.70978Z'
                    fill='currentColor'
                />
            </g>
            <defs>
                <clipPath id='clip0_3068_33391'>
                    <rect width='24' height='24' fill='white' />
                </clipPath>
            </defs>
        </Icon>
    );
};

export default ArrowSmallIcon;
