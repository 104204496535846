import React from 'react';
import {useSelector} from 'react-redux';

import AemEditableText from '../../components/Aem/AemEditableText/AemEditableText';
import AppButton from '../../components/Button/AppButton/AppButton';
import * as localizationKeys from '../../consts/localization/localizationKeys';
import * as tncTypes from '../../consts/tnc/tncTypes';
import {getLocalizedStrings} from '../../services/localization/localizationService';
import tncService from '../../services/tncService';
import {makeSelectTncByType} from '../../state/selectors/consumer';
import {hideLoader, showLoader} from '../../state/slices/loadersSlice';
import {setNotificationFailed} from '../../state/slices/notificationsSlice';
import {dispatch} from '../../state/store';

const TermsOfUseButtons = ({className, onAccept}) => {
    const {legals} = useSelector(makeSelectTncByType(tncTypes.TERMS_OF_USE)) || {};

    if (!legals) return null;

    const localizedStrings = getLocalizedStrings();

    const handleAcceptButtonClick = async () => {
        dispatch(showLoader());

        await tncService.setTnc(legals);

        dispatch(hideLoader());
        onAccept && onAccept();
    };

    const handleDeclineButtonClick = () => {
        dispatch(setNotificationFailed(localizedStrings[localizationKeys.TERMS_OF_USE_DECLINED_ERROR]));
    };

    return (
        <div className={className}>
            <AppButton className='mt-0' primary light onClick={handleAcceptButtonClick}>
                <AemEditableText pageName='tnc' itemName='termsOfUseAgree' />
            </AppButton>
            <AppButton className='mt-0' secondary light onClick={handleDeclineButtonClick}>
                <AemEditableText pageName='tnc' itemName='termsOfUseDecline' />
            </AppButton>
        </div>
    );
};

export default TermsOfUseButtons;
