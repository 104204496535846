import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';

import defaultHeaderImage from '../../../../assets/images/enable-notification/enable-notification-image.jpg';
import {StickyPageLayout} from '../../../components/Layout';
import PageHeaderMedia from '../../../components/PageHeaderMedia/PageHeaderMedia';
import PopupResponsive from '../../../components/Popup/PopupResponsive';
import useOneModalOnPageAtATime from '../../../components/Popup/useOneModalOnPageAtATime';
import appConfig from '../../../config/appConfig';
import ModalTypes from '../../../consts/app/modalTypes';
import amplitudeNotificationTracking from '../../../services/amplitude/amplitudeNotificationTracking';
import domService from '../../../services/domService';
import pushNotificationService from '../../../services/pushNotificationService';
import storageService from '../../../services/storage/storageService';
import {selectIsMobileLayout} from '../../../state/selectors/global';
import {hideModalAction} from '../../../state/slices/modalSlice';
import {dispatch} from '../../../state/store';
import EnableNotificationModalButton from './EnableNotificationModalButton';
import EnableNotificationModalContent from './EnableNotificationModalContent';

const EnableNotificationModal = () => {
    const isMobile = useSelector(selectIsMobileLayout);
    const imageSrc = appConfig.getEnableNotificationPageHeaderImagePath();
    const videoSrc = appConfig.getEnableNotificationPageHeaderVideoSrc();
    const videoThumbnailSrc = appConfig.getEnableNotificationPageHeaderVideoThumbnailSrc();

    useOneModalOnPageAtATime();

    const onClick = async () => {
        amplitudeNotificationTracking.trackEnableNotificationScreenNextButton();
        await pushNotificationService.subscribe();
        storageService.setPermissionDeniedLocalStorage(false);

        dispatch(hideModalAction(ModalTypes.ENABLE_NOTIFICATION));
    };

    const onClose = () => {
        storageService.setPermissionDeniedLocalStorage(true);
        dispatch(hideModalAction(ModalTypes.ENABLE_NOTIFICATION));
    };

    useEffect(() => {
        amplitudeNotificationTracking.trackEnableNotificationScreen();
    }, []);

    return (
        <PopupResponsive
            className='flex md:max-w-[458px] md:rounded-3xl md:p-8 md:text-center'
            onClose={onClose}
            parentRef={domService.getPageElement()}
        >
            {isMobile ? (
                <StickyPageLayout
                    className='items-center'
                    contentClassName='flex flex-1 flex-col items-center'
                    footer={<EnableNotificationModalButton onClick={onClick} />}
                >
                    <PageHeaderMedia
                        defaultImage={defaultHeaderImage}
                        isVideoAvailable={!!videoThumbnailSrc}
                        videoThumbnailSrc={videoThumbnailSrc}
                        videoSrc={videoSrc}
                        imageSrc={imageSrc}
                    />
                    <EnableNotificationModalContent onBtnClick={onClick} isMobile={isMobile} />
                </StickyPageLayout>
            ) : (
                <EnableNotificationModalContent>
                    <EnableNotificationModalButton onClick={onClick} />
                </EnableNotificationModalContent>
            )}
        </PopupResponsive>
    );
};

export default EnableNotificationModal;
