import {showLanguageSelectModalAction} from '@/state/slices/modalSlice';
import {dispatch} from '@/state/store';

import useLanguageSelect from '../../hooks/useLanguageSelect';
import LanguageSelectTrigger from '../LanguageSelectTrigger';

const LanguageSelectPopupTrigger = (props) => {
    const {languageName} = useLanguageSelect();
    const onClick = () => dispatch(showLanguageSelectModalAction());

    return <LanguageSelectTrigger languageName={languageName} onClick={onClick} {...props} />;
};

export default LanguageSelectPopupTrigger;
