import React from 'react';

import Icon from '../Icon/Icon';

const CancelIcon12 = (props) => {
    return (
        <Icon width='12' height='12' {...props}>
            <path
                id='Path3'
                d='M10.9882 1.01448L1.01723 10.9855'
                stroke='#34303D'
                strokeWidth='0.999258'
                strokeLinecap='round'
            />
            <path
                id='Path3_2'
                d='M1.01713 1.01448L10.9881 10.9855'
                stroke='#34303D'
                strokeWidth='0.999258'
                strokeLinecap='round'
            />
        </Icon>
    );
};

export default CancelIcon12;
