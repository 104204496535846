import React from 'react';

import {useGetInboxStatus} from '../../../api/iccNotificationApi';
import useIsDesktopLayout from '../../../hooks/useIsDesktopLayout';
import InboxIconDesktop from './InboxIconDesktop';
import InboxIconMobile from './InboxIconMobile';

const InboxIcon = () => {
    const {data} = useGetInboxStatus();
    const {isUnreadExists} = data || {};
    const isDesktopLayout = useIsDesktopLayout();

    return isDesktopLayout ? (
        <InboxIconDesktop isUnreadExists={isUnreadExists} />
    ) : (
        <InboxIconMobile isUnreadExists={isUnreadExists} />
    );
};

export default InboxIcon;
