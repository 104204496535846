import React from 'react';

import {AppButton} from '@/components/Button';
import RemoteIcon from '@/components/Icon/RemoteIcon';
import {MaxWidthContainer} from '@/components/Layout';
import PageHeaderMedia from '@/components/PageHeaderMedia/PageHeaderMedia';
import appConfig from '@/config/appConfig';
import InstallPwaLanguageSelect from '@/containers/InstallPwaPage/components/InstallPwaLanguageSelect';
import useIsMobileLayout from '@/hooks/useIsMobileLayout';
import {getLocalizedStrings} from '@/services/localization/localizationService';
import marketConfigService from '@/services/marketConfigService';
import appRouterService from '@/services/route/appRouterService';

import InstallPwaPageContent from './components/InstallPwaPageContent';
import useInstalledAppRedirection from './useInstalledAppRedirection';

const InstallPwaPage = () => {
    const localizedStrings = getLocalizedStrings();
    const currentGetStartedPage = marketConfigService.getGetStartedPage();

    const {page, icons, skipButton} = currentGetStartedPage || {};
    const {titleKey, descriptionKey, imagePath, videoPath, videoThumbnailPath} = page || {};

    const imageSrc = appConfig.getCombinedPathAssetPath(imagePath);

    useInstalledAppRedirection();

    const onClick = () => {
        appRouterService.forwardToLoginPage();
    };

    return (
        <div className='flex w-full flex-col'>
            <InstallPwaLanguageSelect />
            <div className='flex h-full w-full flex-col items-center justify-items-center lg:mx-8 lg:grid lg:max-w-[1024px] lg:grid-cols-[1fr_1fr] lg:grid-rows-[auto_auto] lg:gap-x-8 lg:self-center'>
                <MobileScreenHeightContainer>
                    <PageHeaderMedia
                        className='lg:order-1 lg:row-span-2 lg:min-h-[370px]'
                        autoPlay
                        loop
                        playsInline
                        isVideoAvailable={!!videoThumbnailPath}
                        videoClassName='lg:rounded-[24px]'
                        imgClassName='lg:object-none'
                        videoThumbnailSrc={videoThumbnailPath}
                        videoSrc={videoPath}
                        imageSrc={imageSrc}
                        content={[{isControlsAvailable: false}]}
                    />
                    <MaxWidthContainer className='m-8 flex grow flex-col items-center lg:m-0 lg:mb-8 lg:items-start lg:self-end'>
                        <InstallPwaPageContent titleKey={titleKey} descriptionKey={descriptionKey} icons={icons} />
                    </MaxWidthContainer>
                </MobileScreenHeightContainer>

                <div className='order-2 lg:w-full lg:self-start'>
                    <AppButton
                        className='m-0 py-[10px] text-14 underline shadow-none lg:px-8 lg:py-0 lg:text-16 lg:no-underline'
                        dark
                        onClick={onClick}
                    >
                        {localizedStrings[skipButton?.titleKey]}
                        <div className='ml-3 hidden lg:inline'>
                            <RemoteIcon className='inline fill-primary' imagePath={skipButton?.imagePath} />
                        </div>
                    </AppButton>
                </div>
            </div>
        </div>
    );
};

const MobileScreenHeightContainer = ({children}) => {
    const isMobileLayout = useIsMobileLayout();

    return isMobileLayout ? <div className='min-h-svh'>{children}</div> : <>{children}</>;
};

export default InstallPwaPage;
