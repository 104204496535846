const ModalTypes = {
    UWM_UNAVAILABILITY: 'UWM_UNAVAILABILITY',
    LEVIA_LEAR_MORE: 'LEVIA_LEAR_MORE',
    BROWSER_NOT_COMPATIBLE: 'BROWSER_NOT_COMPATIBLE',
    CONSUMER_FEEDBACK_GATHERING: 'CONSUMER_FEEDBACK_GATHERING',
    LANGUAGE_SELECT: 'LANGUAGE_SELECT',
    FIRMWARE_AVAILABLE: 'FIRMWARE_AVAILABLE',
    MAINTENANCE: 'MAINTENANCE',
    ENABLE_NOTIFICATION: 'ENABLE_NOTIFICATION',
    AUTO_DELIVERY_STATUS_NONE_DETAILS: 'AUTO_DELIVERY_STATUS_NONE_DETAILS',
    AUTO_DELIVERY_STATUS_INACTIVE_DETAILS: 'AUTO_DELIVERY_STATUS_INACTIVE_DETAILS',
};

export default ModalTypes;
