import {PopoverClose} from '@/components/Popover/Popover';

import LanguageSelectContentSelectList from '../LanguageSelectContent/LanguageSelectContentSelectList';
import LanguageSelectContentTitle from '../LanguageSelectContent/LanguageSelectContentTitle';

const LanguageSelectPopoverContent = ({selectedLanguageCode, selectListData, onLanguageChange}) => {
    return (
        <div className='absolute w-[360px] rounded-xl bg-romance p-6 shadow-[0_0_8px_0_rgba(0,0,0,0.1)]'>
            <div className='flex flex-col gap-6'>
                <div className='flex items-center justify-between'>
                    <LanguageSelectContentTitle className='text-24 leading-[24px]' />
                    <PopoverClose />
                </div>
                <LanguageSelectContentSelectList
                    selectedValue={selectedLanguageCode}
                    data={selectListData}
                    onChange={onLanguageChange}
                />
            </div>
        </div>
    );
};

export default LanguageSelectPopoverContent;
