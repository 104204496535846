import React from 'react';

import Icon from '../Icon/Icon';

const TrashIcon = (props) => {
    return (
        <Icon width='24' height='24' fill='none' {...props}>
            <path
                d='M5.81812 6.40918H18.1818V18.591C18.1818 19.4194 17.5102 20.091 16.6818 20.091H7.31811C6.48969 20.091 5.81812 19.4194 5.81812 18.591V6.40918Z'
                stroke='#34303D'
                stroke-width='1.5'
            />
            <path d='M4 6.40936H20' stroke='#34303D' stroke-width='1.5' stroke-linecap='round' />
            <path
                d='M9.45459 6.2271V6.2271C9.45459 4.82128 10.5942 3.68164 12 3.68164V3.68164C13.4059 3.68164 14.5455 4.82128 14.5455 6.2271V6.2271'
                stroke='#34303D'
                stroke-width='1.5'
            />
            <path d='M10 9.75V16.5' stroke='#34303D' stroke-width='1.5' stroke-linecap='round' />
            <path d='M14.1135 9.75V16.5' stroke='#34303D' stroke-width='1.5' stroke-linecap='round' />
        </Icon>
    );
};

export default TrashIcon;
