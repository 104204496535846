import appConfig from '@/config/appConfig';

const pushToDataLayer = (message) => {
    const dataLayer = window.dataLayer;
    const isGAEnabled = appConfig.getIsGAEnabled();

    if (!isGAEnabled) return;

    if (dataLayer) {
        dataLayer.push(message);
    }
};

const pushTestEvent = () => {
    pushToDataLayer({
        event: 'iqosConnectPwa',
        eventCategory: 'test event category',
        eventAction: 'test event action',
        eventLabel: 'test event label',
    });
};

export default {
    pushTestEvent,
};
