import cn from 'classnames';

import {useGetAutoDeliveryDetails} from '@/api/consumerApi';
import SectionLoader from '@/components/Loader/AppLoader/SectionLoader';

import {useAutoDeliverySectionIdContext} from '../../contexts/AutoDeliverySectionIdContext';

const AutoDeliveryWidgetContainer = ({className, children}) => {
    const id = useAutoDeliverySectionIdContext();
    const {isFetching} = useGetAutoDeliveryDetails();

    return (
        <section
            id={id}
            className={cn('relative bg-romance px-4 py-8 text-start md:bg-transparent md:px-16 md:py-6', className)}
        >
            {children}
            {isFetching && <SectionLoader />}
        </section>
    );
};

const ContentContainer = ({className, children}) => {
    return <section className={cn('mt-6 md:mt-8 md:rounded-xl md:bg-white md:p-8', className)}>{children}</section>;
};

AutoDeliveryWidgetContainer.ContentContainer = ContentContainer;

export default AutoDeliveryWidgetContainer;
