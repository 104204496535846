import {useMemo} from 'react';

import appConfig from '@/config/appConfig';
import useIsMobileLayout from '@/hooks/useIsMobileLayout';
import storageService from '@/services/storage/storageService';

const useIsWelcomeMode = () => {
    const isMobileLayout = useIsMobileLayout();

    const result = useMemo(() => {
        const isWelcomeScreenEnabled = appConfig.getIsWelcomeScreenEnabled();
        const hasSeenWelcomeScreen = storageService.getHasSeenWelcomeScreen();

        return isWelcomeScreenEnabled && isMobileLayout && !hasSeenWelcomeScreen;
    }, [isMobileLayout]);

    return result;
};

export default useIsWelcomeMode;
