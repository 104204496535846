import {useDispatch} from 'react-redux';

import {hideLoader, showLoader} from '@/state/slices/loadersSlice';

const REDIRECT_TO_PAGE_SECTION_LOADER_NAME = 'REDIRECT_TO_PAGE_SECTION_LOADER_NAME';

const useExternalLinkRedirectLoader = () => {
    const dispatch = useDispatch();
    const showRedirectLoader = () => dispatch(showLoader(REDIRECT_TO_PAGE_SECTION_LOADER_NAME));
    const hideRedirectLoader = () => dispatch(hideLoader(REDIRECT_TO_PAGE_SECTION_LOADER_NAME));

    return {
        showLoader: showRedirectLoader,
        hideLoader: hideRedirectLoader,
    };
};

export default useExternalLinkRedirectLoader;
