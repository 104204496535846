import React from 'react';

import {AppButton} from '@/components/Button';
import Image from '@/components/Image/Image';
import PromotedBadge from '@/components/PromotedBadge/PromotedBadge';
import useConfigExternalLink from '@/hooks/externalLink/useConfigExternalLink';
import amplitudeShopPageTracking from '@/services/amplitude/amplitudeShopPageTracking';
import {getLocalizedStrings} from '@/services/localization/localizationService';
import productHelpers from '@/services/product/productHelpers';
import helpers from '@/utils/helpers';

const DeviceItem = ({device, promotedTitleKey, externalLinkData, actionLocalizationKey, amplitudeButtonType}) => {
    const localizedStrings = getLocalizedStrings();
    const {defaultProductName, image} = device || {};
    const name = productHelpers.getProductName(device);

    const redirectToExternalLink = useConfigExternalLink(externalLinkData);
    const onClick = () => {
        amplitudeShopPageTracking.trackYourDeviceButtonClick(amplitudeButtonType, defaultProductName);
        helpers.runFunction(redirectToExternalLink);
    };

    return (
        <div className='relative mr-4 flex h-full w-65 flex-col rounded-2xl bg-secondary px-4 pb-4 pt-11'>
            <div className='absolute left-4 top-4'>
                <PromotedBadge badgeText={localizedStrings[promotedTitleKey]} />
            </div>
            <Image className='h-auto w-full' src={image} alt={name} ariaHidden />
            <div className='mb-4 mt-2.5 flex justify-center'>
                <b className='overflow-hidden text-ellipsis text-center text-14'>{name}</b>
            </div>
            <AppButton secondary dark className='m-0 w-full min-w-0' onClick={onClick}>
                {localizedStrings[actionLocalizationKey]}
            </AppButton>
        </div>
    );
};

export default DeviceItem;
