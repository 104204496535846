import cn from 'classnames';
import React, {useState} from 'react';

import {PopoverArrow, PopoverContent, PopoverStickyTitle} from '../../../components/Popover/Popover';
import reactUtils from '../../../utils/reactUtils';

const InboxPopoverContent = ({className, children}) => {
    const [title, content] = reactUtils.filterChildrenByType(children, [Title, Content]);
    const [contentElement, setContentElement] = useState();
    const {props: titleProps} = title;

    return (
        <PopoverContent
            align='end'
            arrowPadding={40}
            className={cn('flex h-[480px] flex-col overflow-auto', className)}
            ref={(ref) => setContentElement(ref)}
        >
            {titleProps.children && <PopoverStickyTitle contentElement={contentElement} {...titleProps} />}
            <div className='flex-1 p-6 pt-0'>{content}</div>
            <PopoverArrow />
        </PopoverContent>
    );
};

const Title = (props) => props;
const Content = ({children}) => children;

InboxPopoverContent.Title = Title;
InboxPopoverContent.Content = Content;

export default InboxPopoverContent;
