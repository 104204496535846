import queryParameters from './app/consts/route/queryParameters';
import log from './app/services/logger/log';
import marketService from './app/services/marketService';
import storageService from './app/services/storage/storageService';
import urlUtils from './app/utils/urlUtils';

export const setupManifest = () => {
    try {
        const manifest = getManifestData();

        addManifest(manifest, '#manifest');
    } catch (e) {
        log.info(`setupManifest error: ${e}`);
    }
};

const MANIFEST = {
    short_name: 'IQOS',
    name: 'IQOS Connect WEB',
    icons: [
        {
            src: process.env.PUBLIC_URL + '/icons/pwa-iqos-icon.svg',
            sizes: 'any',
            type: 'image/svg+xml',
        },
        {
            src: process.env.PUBLIC_URL + '/icons/pwa-iqos-icon_48.png',
            sizes: '48x48',
            type: 'image/png',
        },
        {
            src: process.env.PUBLIC_URL + '/icons/pwa-iqos-icon_72.png',
            sizes: '72x72',
            type: 'image/png',
        },
        {
            src: process.env.PUBLIC_URL + '/icons/pwa-iqos-icon_96.png',
            sizes: '96x96',
            type: 'image/png',
        },
        {
            src: process.env.PUBLIC_URL + '/icons/pwa-iqos-icon_128.png',
            sizes: '128x128',
            type: 'image/png',
        },
        {
            src: process.env.PUBLIC_URL + '/icons/pwa-iqos-icon_144.png',
            sizes: '144x144',
            type: 'image/png',
        },
        {
            src: process.env.PUBLIC_URL + '/icons/pwa-iqos-icon_152.png',
            sizes: '152x152',
            type: 'image/png',
        },
        {
            src: process.env.PUBLIC_URL + '/icons/pwa-iqos-icon_192.png',
            sizes: '192x192',
            type: 'image/png',
        },
        {
            src: process.env.PUBLIC_URL + '/icons/pwa-iqos-icon_256.png',
            sizes: '256x256',
            type: 'image/png',
        },
        {
            src: process.env.PUBLIC_URL + '/icons/pwa-iqos-icon_384.png',
            sizes: '384x384',
            type: 'image/png',
        },
        {
            src: process.env.PUBLIC_URL + '/icons/pwa-iqos-icon_512.png',
            sizes: '512x512',
            type: 'image/png',
            purpose: 'maskable',
        },
    ],
    display: 'standalone',
    orientation: 'portrait',
    start_url: process.env.PUBLIC_URL,
    theme_color: '#34303d',
    background_color: '#fffdfb',
};

const getManifestData = () => {
    const [languageFromUrl] = urlUtils.getDataFromUrl(queryParameters.LANGUAGE);
    const languageFromLocalStorage = storageService.getLanguageCodeFromLocalStorage();
    const language = languageFromUrl || languageFromLocalStorage;
    const marketCode = marketService.getMarketCode();

    MANIFEST.start_url =
        `${MANIFEST.start_url}/${marketCode}` + (language ? `/?${queryParameters.LANGUAGE}=${language}` : '/');

    return MANIFEST;
};

const addManifest = (manifest, selector) => {
    const stringManifest = JSON.stringify(manifest);
    const blob = new Blob([stringManifest], {type: 'application/json'});
    const manifestURL = URL.createObjectURL(blob);

    document.querySelector(selector)?.setAttribute('href', manifestURL);
};
