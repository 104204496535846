import React, {useEffect} from 'react';

import useInboxListView from '../../../hooks/inbox/useInboxListView';
import amplitudeInboxTracking from '../../../services/amplitude/amplitudeInboxTracking';
import homePageNavigationService from '../../../services/homePageNavigationService';
import inboxMessageService from './inboxMessageService';
import InboxView from './InboxView';

const InboxViewPage = () => {
    const {inboxMessages, sentryRef, hasNextPage, onDeleteMessage} = useInboxListView();

    const onCloseClick = () => {
        homePageNavigationService.forwardToSourcePage();
        inboxMessageService.deleteInboxMessages();
    };

    useEffect(() => {
        amplitudeInboxTracking.trackInboxScreen();
    }, []);

    return (
        <InboxView
            notifications={inboxMessages}
            sentryRef={sentryRef}
            hasNextPage={hasNextPage}
            onCloseClick={onCloseClick}
            onDeleteMessage={onDeleteMessage}
        />
    );
};

export default InboxViewPage;
