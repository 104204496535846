import {useSelector} from 'react-redux';

import appConfig from '@/config/appConfig';
import {AEM_GREETING_TYPES} from '@/consts/aem/aemGreetingTypes';
import ROUTE_PATHS from '@/consts/route/routePaths';
import useLocationPathname from '@/hooks/useLocationPathname';
import {getLocalizedStrings} from '@/services/localization/localizationService';
import userService from '@/services/user/userService';
import {selectConsumerFirstName} from '@/state/selectors/consumer';

const useGreeting = () => {
    const localizedStrings = getLocalizedStrings();
    const type = useGreetingType();
    const name = useSelector(selectConsumerFirstName);
    const greetings = appConfig.getGreetings();
    const {descriptionKey, titleKey, isDarkText} = greetings?.find((g) => g.type === type) || {};
    const timeOfDayGreeting = userService.getLocalizedTimeOfDayGreeting();
    const title = titleKey && localizedStrings.formatString(localizedStrings[titleKey], timeOfDayGreeting);
    const description = descriptionKey && localizedStrings.formatString(localizedStrings[descriptionKey], name);

    return {title, description, isDarkText, type};
};

const useGreetingType = () => {
    const {pathname} = useLocationPathname();

    switch (pathname) {
        case ROUTE_PATHS.HOME_PAGE:
            return AEM_GREETING_TYPES.HOME_PAGE;
        case ROUTE_PATHS.SHOP_PAGE:
            return AEM_GREETING_TYPES.SHOP_PAGE;
        default:
            break;
    }
};

export default useGreeting;
