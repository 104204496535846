import React from 'react';
import {useDispatch, useSelector} from 'react-redux';

import Banner from '@/components/Banner/Banner';
import {AppButton} from '@/components/Button';
import PromotedBadge from '@/components/PromotedBadge/PromotedBadge';
import appConfig from '@/config/appConfig';
import amplitudeAutoDeliveryTracking from '@/services/amplitude/amplitudeAutoDeliveryTracking';
import {getLocalizedStrings} from '@/services/localization/localizationService';
import {selectIsAem, selectIsMobileLayout} from '@/state/selectors/global';
import {showAutoDeliveryStatusNoneDetailsPopup} from '@/state/slices/modalSlice';

import AutoDeliveryWidgetContainer from '../components/AutoDeliveryWidgetContainer';
import AutoDeliveryWidgetHeader from '../components/AutoDeliveryWidgetHeader';

const AutoDeliveryStatusNone = ({className}) => {
    const dispatch = useDispatch();
    const isMobileLayout = useSelector(selectIsMobileLayout);
    const banner = appConfig.getHomePageAutoDeliveryBanner();
    const {imagePath, videoPath, isGradient, titleKey, subTitleKey, descriptionKey, buttonActionKey} = banner || {};
    const isAem = useSelector(selectIsAem);
    const isBannerDataExist = imagePath || videoPath || titleKey || subTitleKey || descriptionKey;

    const onBannerClick = () => {
        amplitudeAutoDeliveryTracking.trackAutoDeliveryBannerClick();
        dispatch(showAutoDeliveryStatusNoneDetailsPopup());
    };

    if (!isBannerDataExist && !isAem) return;

    return (
        <AutoDeliveryWidgetContainer className={className}>
            {isMobileLayout && (
                <>
                    <AutoDeliveryWidgetHeader />
                    <Banner
                        className='mt-4 h-60 overflow-hidden rounded-xl bg-primary text-start'
                        imagePath={imagePath}
                        videoPath={videoPath}
                        isGradient={isGradient}
                        gradient='linear-gradient(180deg, rgba(52, 48, 61, 0) 39.99%, #1C1A21 100%)'
                        onClick={onBannerClick}
                    >
                        <AutoDeliveryStatusNoneContent
                            titleKey={titleKey}
                            subTitleKey={subTitleKey}
                            descriptionKey={descriptionKey}
                        />
                    </Banner>
                </>
            )}
            {!isMobileLayout && (
                <div className='flex w-full items-center rounded-xl bg-primary'>
                    <AutoDeliveryStatusNoneContent
                        titleKey={titleKey}
                        subTitleKey={subTitleKey}
                        descriptionKey={descriptionKey}
                        buttonActionKey={buttonActionKey}
                        onClick={onBannerClick}
                    />
                    <Banner
                        className='h-100 w-1/2 overflow-hidden rounded-r-xl'
                        imagePath={imagePath}
                        videoPath={videoPath}
                        isGradient={isGradient}
                        gradient='linear-gradient(180deg, rgba(52, 48, 61, 0) 39.99%, #1C1A21 100%)'
                    />
                </div>
            )}
        </AutoDeliveryWidgetContainer>
    );
};

const AutoDeliveryStatusNoneContent = ({titleKey, subTitleKey, descriptionKey, buttonActionKey, onClick}) => {
    const localizedStrings = getLocalizedStrings();
    const description = localizedStrings[descriptionKey];

    return (
        <div className='relative flex h-full flex-col justify-end gap-2 p-4 md:w-1/2 md:gap-3.5 md:bg-primary md:p-16'>
            <PromotedBadge
                className='max-w-full whitespace-nowrap [&>*]:overflow-hidden [&>*]:text-ellipsis'
                badgeText={localizedStrings[subTitleKey]}
            />
            <h2 className='mb-0 overflow-hidden text-ellipsis whitespace-nowrap text-24 text-white md:text-44'>
                {localizedStrings[titleKey]}
            </h2>
            {description && <p className='line-clamp-3 whitespace-pre-line text-white md:text-18'>{description}</p>}
            {buttonActionKey && (
                <div>
                    <AppButton primary dark className='mt-6 w-auto' onClick={onClick}>
                        {localizedStrings[buttonActionKey]}
                    </AppButton>
                </div>
            )}
        </div>
    );
};

export default AutoDeliveryStatusNone;
