import cn from 'classnames';
import React from 'react';
import {useLocation} from 'react-router-dom';

import AemEditableText from '@/components/Aem/AemEditableText/AemEditableText';
import {ContentWrapper, MaxWidthContainer, StickyHeader} from '@/components/Layout';
import appConfig from '@/config/appConfig';
import {SELECT_YOUR_DEVICE_PAGE_HEADER_TITLE} from '@/consts/localization/localizationKeys';
import ROUTE_PATHS from '@/consts/route/routePaths';
import useDocumentTitle from '@/hooks/useDocumentTitle';
import {getDefaultDeviceData} from '@/services/device/deviceData';
import {getLocalizedStrings} from '@/services/localization/localizationService';
import appRouterService from '@/services/route/appRouterService';
import routerService from '@/services/route/routerService';
import arrayUtils from '@/utils/arrayUtils';

import SelectDeviceItem from './components/SelectDeviceItem';

const MAX_COUNT_ITEMS_FOR_CENTERING = 3;

const SelectYourDevicePage = () => {
    const location = useLocation();
    const {onBackRoute} = location.state || {};
    const localizedStrings = getLocalizedStrings();
    const selectDeviceData = getDefaultDeviceData();
    const isRestrictedMarket = appConfig.getIsRestrictedMarket();
    const supportedDeviceData = arrayUtils.sortArrayWithOrderField(
        selectDeviceData.filter((device) => device.isSupported)
    );
    const isFlexContainer = supportedDeviceData.length <= MAX_COUNT_ITEMS_FOR_CENTERING;
    const onBack =
        onBackRoute === ROUTE_PATHS.DEVICES_PAGE ? appRouterService.forwardToDevicesPage : routerService.goBack;

    useDocumentTitle(localizedStrings[SELECT_YOUR_DEVICE_PAGE_HEADER_TITLE]);

    return (
        <ContentWrapper
            className={cn('mx-4 mb-4 items-start pt-safe-offset-14', {
                'sm-max:mx-0': isRestrictedMarket,
            })}
        >
            <MaxWidthContainer className={cn({'mx-8': isRestrictedMarket})}>
                <StickyHeader onBack={onBack} title={localizedStrings[SELECT_YOUR_DEVICE_PAGE_HEADER_TITLE]} />
                <h1 className='m-0 text-28'>
                    <AemEditableText pageName='devices' itemName='SelectYourDeviceTitle' />
                </h1>
            </MaxWidthContainer>
            <MaxWidthContainer size='l' className={cn({'sm-max:w-screen': isRestrictedMarket})}>
                <ul
                    className={cn(
                        'mt-8 max-w-[400px] gap-4 lg:max-w-none',
                        {'flex flex-col justify-center lg:flex-row': isFlexContainer},
                        {'grid grid-cols-1 lg:grid-cols-3 xl:grid-cols-4': !isFlexContainer},
                        {'sm-max:w-full sm-max:max-w-none sm-max:gap-0': isRestrictedMarket}
                    )}
                >
                    {supportedDeviceData.map(({type, name, images}, i) => {
                        return (
                            <SelectDeviceItem
                                alt={name}
                                image={images}
                                key={type}
                                name={name}
                                type={type}
                                isLast={i === supportedDeviceData.length - 1}
                            />
                        );
                    })}
                </ul>
            </MaxWidthContainer>
        </ContentWrapper>
    );
};

export default SelectYourDevicePage;
