import {useEffect, useRef} from 'react';
import {useSelector} from 'react-redux';

import iccMarketService from '../../../services/icc/iccMarketService';
import {selectAggregatedSettings} from '../../../state/selectors/consumerAggregatedSettings';

const ICC_AGGREGATED_SETTINGS_REFRESH_TIMEOUT = 20 * 60; // 20min

const useConsumerSettingsChecker = () => {
    const aggregatedSettings = useSelector(selectAggregatedSettings);
    const isAggregatedSettings = !!aggregatedSettings;
    const intervalRef = useRef();

    useEffect(() => {
        if (!isAggregatedSettings) return;

        const {allMandatoryTermsApproved, allNonMandatoryTermsReviewed, settingsRefreshTimeoutSec} = aggregatedSettings;
        const isTncAccepted = allMandatoryTermsApproved && allNonMandatoryTermsReviewed;

        if (!isTncAccepted) return;

        const settingsRefreshTimeout = settingsRefreshTimeoutSec
            ? settingsRefreshTimeoutSec
            : ICC_AGGREGATED_SETTINGS_REFRESH_TIMEOUT;

        clearInterval(intervalRef.current);
        intervalRef.current = setInterval(async () => {
            await iccMarketService.getConsumerAggregatedSettings(false);
        }, settingsRefreshTimeout * 1000);

        return () => clearInterval(intervalRef.current);
    }, [isAggregatedSettings]);
};

export default useConsumerSettingsChecker;
