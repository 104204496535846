import React from 'react';

import Icon from '../Icon/Icon';

const CancelIcon = (props) => {
    return (
        <Icon width='24' height='24' {...props}>
            <path d='M19.4838 4.52196L4.52731 19.4785' stroke='currentColor' strokeWidth='1.5' strokeLinecap='round' />
            <path d='M4.52691 4.52196L19.4834 19.4785' stroke='currentColor' strokeWidth='1.5' strokeLinecap='round' />
        </Icon>
    );
};

export default CancelIcon;
