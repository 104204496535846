import {useSelector} from 'react-redux';

import {StickyHeader} from '../../../../../components/Layout';
import {TNC_PAGE_HEADER_TITLE} from '../../../../../consts/localization/localizationKeys';
import useIsMobileLayout from '../../../../../hooks/useIsMobileLayout';
import useTermsOfUsePopup from '../../../../../hooks/useTermsOfUsePopup';
import {getLocalizedStrings} from '../../../../../services/localization/localizationService';
import routerService from '../../../../../services/route/routerService';
import {makeSelectTncLegalDataByCode, selectTermsOfUseLegalCode} from '../../../../../state/selectors/consumer';
import {selectIsAllMandatoryTermsApproved} from '../../../../../state/selectors/consumerAggregatedSettings';
import TermsOfUseButtons from '../../../../../views/Tnc/TermsOfUseButtons';
import TermsOfUseDescription from '../../../../../views/Tnc/TermsOfUseDescription/TermsOfUseDescription';
import PageWarningBannersContainer from '../../../../WarningBanners/PageWarningBannersContainer';

const TncDetailsTermsOfUse = ({onMandatoryTermsAccept}) => {
    const isAllMandatoryTermsApproved = useSelector(selectIsAllMandatoryTermsApproved);
    const isMobileLayout = useIsMobileLayout();
    const legalCode = useSelector(selectTermsOfUseLegalCode);
    const legalData = useSelector(makeSelectTncLegalDataByCode(legalCode));
    const {openPopup} = useTermsOfUsePopup({
        overlayClassName: 'md:left-[var(--tnc-sidebar-width)] md:w-[calc(100%-var(--tnc-sidebar-width))]',
    });
    const localizedStrings = getLocalizedStrings();
    const {text, title} = legalData;

    const isAcceptButtonsVisible = !isMobileLayout && !isAllMandatoryTermsApproved;

    return (
        <>
            <div className='px-4 pb-4 pt-safe-offset-14 md:p-16 md:pb-4'>
                <StickyHeader onBack={routerService.goBack} title={localizedStrings[TNC_PAGE_HEADER_TITLE]} />
                <h1 className='mb-0 text-center text-28 md:text-start lg:text-32'>{title}</h1>
                <TermsOfUseDescription
                    innerHtml={text}
                    className='mt-4 break-words text-justify md:mt-8 [&_a]:bg-none [&_span]:text-inherit'
                    onTncLinkClick={openPopup}
                />
                {isAcceptButtonsVisible && (
                    <TermsOfUseButtons
                        className='align-end mt-8 flex flex-row-reverse gap-4'
                        onAccept={onMandatoryTermsAccept}
                    />
                )}
            </div>
            <PageWarningBannersContainer />
        </>
    );
};

export default TncDetailsTermsOfUse;
