export const EXTERNAL_LINK_TYPES = {
    SECTION_LINK: 'Section link',
    SSO_LINK_IN_APP: 'SSO link in app',
    SSO_LINK: 'SSO link name',
    URL_IN_APP: 'URL in app',
    URL: 'URL',
    PAGE_SECTION: 'Page section',
};

export const APP_SECTION_TYPES = {
    LEVIA: 'levia',
    FIRMWARE_AVAILABLE: 'fw',
    DETAILS: 'detailed',
};
