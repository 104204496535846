import React from 'react';
import {useSelector} from 'react-redux';

import AemEditableText from '../../../components/Aem/AemEditableText/AemEditableText';
import {TncIcon} from '../../../components/Icons';
import {StickyPageLayout} from '../../../components/Layout';
import * as localizationKeys from '../../../consts/localization/localizationKeys';
import {TERMS_OF_USE} from '../../../consts/tnc/tncTypes';
import useDocumentTitle from '../../../hooks/useDocumentTitle';
import useIsMobileLayout from '../../../hooks/useIsMobileLayout';
import useTermsOfUsePopup from '../../../hooks/useTermsOfUsePopup';
import {getLocalizedStrings} from '../../../services/localization/localizationService';
import {makeSelectAcceptedTncByType, makeSelectTncByType} from '../../../state/selectors/consumer';
import TermsOfUseButtons from '../../../views/Tnc/TermsOfUseButtons';
import TermsOfUseDescription from '../../../views/Tnc/TermsOfUseDescription/TermsOfUseDescription';
import PageWarningBannersContainer from '../../WarningBanners/PageWarningBannersContainer';
import TncPageContainer from '../TncPageContainer';

const TermsOfUsePage = () => {
    const isMobileLayout = useIsMobileLayout();
    const termsOfUse = useSelector(makeSelectTncByType(TERMS_OF_USE));
    const acceptedTermsOfUse = useSelector(makeSelectAcceptedTncByType(TERMS_OF_USE));
    const {openPopup} = useTermsOfUsePopup();
    const localizedStrings = getLocalizedStrings();

    useDocumentTitle(localizedStrings[localizationKeys.TERMS_OF_USE_HEADER_TITLE]);

    if (!termsOfUse) return null;

    const {description, legals, updateDescription} = termsOfUse;

    if (!legals) return null;

    const isUpdatedTnc = !!acceptedTermsOfUse;
    const descriptionText = isUpdatedTnc ? updateDescription : description;

    return (
        <TncPageContainer>
            <StickyPageLayout
                className='items-center'
                desktopClassName='w-[506px] justify-center py-5'
                desktopContentClassName='flex flex-col items-center'
                desktopFooterClassName='mt-6 md:mt-8'
                headerOptions={{
                    title: localizedStrings[localizationKeys.TERMS_OF_USE_HEADER_TITLE],
                }}
                contentClassName='flex flex-1 flex-col items-center pt-safe-offset-14'
                footer={
                    <>
                        <TermsOfUseButtons className='flex flex-col gap-3 md:mb-10' />
                        {!isMobileLayout && <PageWarningBannersContainer />}
                    </>
                }
            >
                <div className='mb-4 flex flex-1 flex-col items-center px-4 md:mb-0'>
                    <TncIcon />
                    <h1 className='mt-9 text-28 md:mb-0 md:mt-4'>
                        {isUpdatedTnc && <AemEditableText pageName='tnc' itemName='termsOfUseTitleUpdate' />}
                        {!isUpdatedTnc && <AemEditableText pageName='tnc' itemName='termsOfUseTitle' />}
                    </h1>
                    <TermsOfUseDescription
                        className='mt-4'
                        innerHtml={descriptionText}
                        tncType={TERMS_OF_USE}
                        onTncLinkClick={openPopup}
                    />
                    {isMobileLayout && <PageWarningBannersContainer />}
                </div>
            </StickyPageLayout>
        </TncPageContainer>
    );
};

export default TermsOfUsePage;
