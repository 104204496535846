import React from 'react';

import Icon from '@/components/Icon/Icon';
import {twx} from '@/utils/tailwindUtils';

const GlobeIcon = ({className}) => {
    return (
        <Icon width='32' height='32' fill='none' className={twx('stroke-[1.5]', className)}>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M26.6667 16.0938C26.6667 21.9848 21.8911 26.7605 16 26.7605C10.109 26.7605 5.33337 21.9848 5.33337 16.0938C5.33337 10.2028 10.109 5.42712 16 5.42712C21.8911 5.42712 26.6667 10.2028 26.6667 16.0938Z'
                stroke='currentColor'
                strokeLinejoin='round'
            />
            <path d='M16.0705 5.33337V26.6667' stroke='currentColor' strokeLinejoin='round' />
            <path d='M26.9405 16.0705H5.60718' stroke='currentColor' strokeLinejoin='round' />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M21.3334 16.1667C21.3334 21.9657 18.9457 26.6667 16.0001 26.6667C13.0545 26.6667 10.6667 21.9657 10.6667 16.1667C10.6667 10.3678 13.0545 5.66675 16.0001 5.66675C18.9457 5.66675 21.3334 10.3678 21.3334 16.1667Z'
                stroke='currentColor'
                strokeLinejoin='round'
            />
            <path
                d='M23.9639 9.33337C22.0528 10.4794 19.1758 11.2087 15.9582 11.2087C12.7671 11.2087 9.91111 10.4914 8 9.3616'
                stroke='currentColor'
                strokeLinejoin='round'
            />
            <path
                d='M8 22.5875C9.9104 21.4154 12.8207 20.6667 16.0806 20.6667C19.3895 20.6667 22.3381 21.438 24.2466 22.6406'
                stroke='currentColor'
                strokeLinejoin='round'
            />
        </Icon>
    );
};

export default GlobeIcon;
