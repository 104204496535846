import React from 'react';

import AemEditableText from '@/components/Aem/AemEditableText/AemEditableText';
import {ButtonRaw} from '@/components/Button';
import {CommentIcon} from '@/components/Icons';
import useConfigExternalLink from '@/hooks/externalLink/useConfigExternalLink';

const ContactUsSocialChannels = ({socialChannels}) => {
    if (!socialChannels || !socialChannels.some((c) => c.action)) return null;

    return (
        <div className='flex w-full flex-col items-center'>
            <CommentIcon className={'mb-3 min-h-12'} />
            <h3 className='text-24'>
                <AemEditableText pageName='contact-us' itemName='socialChannelsTitle' />
            </h3>
            <AemEditableText className='my-4' pageName='contact-us' itemName='socialChannelsDesc' />
            <div className='flex justify-between gap-3'>
                {socialChannels.map((socialChannel, index) => (
                    <ContactUsSocialChannel key={index} socialChannel={socialChannel} />
                ))}
            </div>
        </div>
    );
};

const ContactUsSocialChannel = ({socialChannel}) => {
    const {icon, action} = socialChannel || {};
    const redirectToExternalLink = useConfigExternalLink(action);

    return (
        <ButtonRaw className='md:min-w-16' onClick={redirectToExternalLink}>
            {icon}
        </ButtonRaw>
    );
};

export default ContactUsSocialChannels;
