import cn from 'classnames';
import React, {useState} from 'react';

import {ExpansionPanelArrowIcon} from '../../../../../../../components/Icons';
import domService from '../../../../../../../services/domService';
import TermsOfUseDescription from '../../../../../../../views/Tnc/TermsOfUseDescription/TermsOfUseDescription';

const ExpansionPanel = ({title, innerText, children, index, onTncLinkClick}) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const onKeyPress = (e) => domService.handleBtnKeyPress(e, onExpansionPanelClick);

    const onExpansionPanelClick = () => {
        setIsExpanded(!isExpanded);
    };

    return (
        <div
            className={cn(
                'relative w-full cursor-pointer select-none md:min-h-18',
                'after:absolute after:bottom-0 after:right-1/2 after:h-px after:w-screen after:translate-x-1/2 after:bg-alto-second after:content-[""] md:after:w-full'
            )}
        >
            <div className={'flex items-center justify-between'}>
                <div
                    className={'flex w-full items-center'}
                    onClick={onExpansionPanelClick}
                    aria-expanded={isExpanded}
                    onKeyPress={onKeyPress}
                    aria-label={title}
                    tabIndex={0}
                    role='button'
                >
                    <ExpansionPanelArrowIcon
                        className={cn('w-3 min-w-[12px] duration-200 ease-in-out', {'rotate-180': isExpanded})}
                        onClick={onExpansionPanelClick}
                    />
                    <p className='w-full px-4 py-6 text-start' id={`${index}`}>
                        {title}
                    </p>
                </div>
                {children}
            </div>
            {isExpanded && innerText && (
                <TermsOfUseDescription
                    innerHtml={innerText}
                    className='pb-6 text-start'
                    onTncLinkClick={onTncLinkClick}
                />
            )}
        </div>
    );
};

export default ExpansionPanel;
