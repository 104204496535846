import log from './logger/log';

const registerServiceWorker = async () => {
    try {
        const swReg =
            (await navigator.serviceWorker.getRegistration()) ||
            (await navigator.serviceWorker.register(`/service-worker.js`));

        return swReg;
    } catch (e) {
        log.info('Unable to register service worker.', e);
    }

    return null;
};

const postMessage = async (message) => {
    const swReg = await registerServiceWorker();

    if (!swReg) return;

    try {
        const registration = await navigator.serviceWorker.ready;

        registration.active.postMessage(message);
        log.debug(`postMessage to serviceWorker success, message: ${JSON.stringify(message)}`);
    } catch (e) {
        log.debug(`postMessage to serviceWorker error: ${JSON.stringify(e)}`);
    }
};

export default {
    registerServiceWorker,
    postMessage,
};
