import * as httpErrorsTypes from '../../consts/app/httpErrorsTypes';
import {showMaintenancePopup} from '../../state/slices/modalSlice';
import {dispatch} from '../../state/store';
import iccService from './iccService';

const iccResponseErrorCheckService = async ({status, error, skipError}) => {
    const isServerUnavailable = status === httpErrorsTypes.SERVICE_UNAVAILABLE;
    const isAuthError = status === httpErrorsTypes.NOT_AUTHORIZED;

    if (isServerUnavailable) {
        dispatch(showMaintenancePopup());
    }

    if (isAuthError) {
        return iccService.refreshIccTokenIfAllowed();
    } else {
        if (skipError) return;
        throw error;
    }
};

export default iccResponseErrorCheckService;
