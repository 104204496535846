import React, {forwardRef} from 'react';

import {AEM_GREETING_TYPES} from '@/consts/aem/aemGreetingTypes';
import {twx} from '@/utils/tailwindUtils';
import useGreeting from '@/views/Greeting/useGreeting';

const HomePageGreeting = forwardRef(({className, style}, ref) => {
    const {title, description, isDarkText, type} = useGreeting();
    const isShopPage = type === AEM_GREETING_TYPES.SHOP_PAGE;

    return (
        <div className={twx({'text-primary': isDarkText}, className)} style={style} ref={ref}>
            <p className={twx('text-14', {'text-28': isShopPage})}>{title}</p>
            <p className={twx('text-28', {'text-14': isShopPage})}>{description}</p>
        </div>
    );
});

export default HomePageGreeting;
