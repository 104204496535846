import {useEffect, useState} from 'react';

const useOnScroll = ({scrollElement = window, onScroll}) => {
    const [scrollY, setScrollY] = useState(0);

    useEffect(() => {
        if (!scrollElement) return;

        const handleScroll = (e) => {
            setScrollY(scrollElement.scrollY || scrollElement.scrollTop || 0);

            if (onScroll) onScroll(e);
        };

        scrollElement.addEventListener('scroll', handleScroll);

        return () => scrollElement.removeEventListener('scroll', handleScroll);
    }, [scrollElement, onScroll]);

    return scrollY;
};

export default useOnScroll;
