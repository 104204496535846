import {useEffect, useState} from 'react';

import serviceWorkerService from '../../../../services/serviceWorkerService';

const useAppUpdate = () => {
    const [isNewVersionAvailable, setIsNewVersionAvailable] = useState(false);

    useEffect(() => {
        const registerServiceWorker = async () => {
            const swReg = await serviceWorkerService.registerServiceWorker();

            if (!swReg) return;

            swReg.onupdatefound = () => {
                const newWorker = swReg.installing;

                newWorker.onstatechange = () => {
                    if (newWorker.state === 'installed') {
                        if (navigator.serviceWorker.controller) {
                            setIsNewVersionAvailable(true);
                        }
                    }
                };
            };
        };

        registerServiceWorker();
    }, []);

    return isNewVersionAvailable;
};

export default useAppUpdate;
