import cn from 'classnames';
import React from 'react';
import {useSelector} from 'react-redux';

import AemEditableText from '@/components/Aem/AemEditableText/AemEditableText';
import Banner from '@/components/Banner/Banner';
import appConfig from '@/config/appConfig';
import amplitudeShopPageTracking from '@/services/amplitude/amplitudeShopPageTracking';
import {getLocalizedStrings} from '@/services/localization/localizationService';
import appRouterService from '@/services/route/appRouterService';
import {selectIsAem} from '@/state/selectors/global';

const YourUsualSection = ({className}) => {
    const banner = appConfig.getShopPageTopBanner();
    const {imagePath, videoPath, isGradient, titleKey, descriptionKey} = banner || {};
    const localizedStrings = getLocalizedStrings();
    const isAem = useSelector(selectIsAem);
    const isBannerDataExist = imagePath || videoPath || titleKey || descriptionKey;
    const onBannerClick = () => {
        amplitudeShopPageTracking.trackQuickOrderBannerClick();
        appRouterService.forwardToQuickOrderPage();
    };

    return (
        (isAem || isBannerDataExist) && (
            <article className={cn('bg-romance px-4 py-8', className)}>
                <h2 className='text-24'>
                    <AemEditableText pageName='shop' itemName='yourUsualTitle' />
                </h2>
                <Banner
                    className='h-60 cursor-pointer overflow-hidden rounded-xl bg-tertiary'
                    imagePath={imagePath}
                    videoPath={videoPath}
                    isGradient={isGradient}
                    gradient='linear-gradient(180deg, rgba(52, 48, 61, 0) 39.99%, #1C1A21 100%)'
                    onClick={onBannerClick}
                >
                    <div className='relative flex h-full flex-col justify-end p-4'>
                        <h2 className='mb-2 overflow-hidden text-ellipsis whitespace-nowrap text-24 text-white'>
                            {localizedStrings[titleKey]}
                        </h2>
                        <p className='line-clamp-2 whitespace-pre-line text-white'>
                            {localizedStrings[descriptionKey]}
                        </p>
                    </div>
                </Banner>
            </article>
        )
    );
};

export default YourUsualSection;
