import React from 'react';

import AemEditableText from '../../../components/Aem/AemEditableText/AemEditableText';
import {PopoverClose} from '../../../components/Popover/Popover';

const InboxPopoverMainTitle = () => {
    return (
        <>
            <AemEditableText pageName='home' itemName='notificationsinboxtitle' />
            <PopoverClose />
        </>
    );
};

export default InboxPopoverMainTitle;
