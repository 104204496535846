import React, {useState} from 'react';

import {Popover, PopoverAnchor} from '../../components/Popover/Popover';
import useOnScroll from '../../hooks/useOnScroll';
import InboxPopoverList from './components/InboxPopoverList';
import InboxPopoverNotificationDetails from './components/InboxPopoverNotificationDetails';
import useOpenInboxDetails from './useOpenInboxDetails';

const InboxPopover = ({isOpen, setIsOpen, children}) => {
    const [messageId, setMessageId] = useState();

    useOpenInboxDetails({setMessageId, setIsOpen});
    useOnScroll({
        onScroll: () => {
            if (isOpen) setIsOpen(false);
        },
    });

    const onMessageClick = (messageId) => {
        setMessageId(messageId);
    };
    const onOpenChange = (isOpen) => {
        setIsOpen(isOpen);

        if (!isOpen) {
            closeNotificationDetails();
        }
    };
    const closeNotificationDetails = () => {
        setMessageId();
    };

    return (
        <Popover open={isOpen} onOpenChange={onOpenChange}>
            <PopoverAnchor>{children}</PopoverAnchor>
            {isOpen &&
                (messageId ? (
                    <InboxPopoverNotificationDetails
                        messageId={messageId}
                        closeNotificationDetails={closeNotificationDetails}
                    />
                ) : (
                    <InboxPopoverList onMessageClick={onMessageClick} />
                ))}
        </Popover>
    );
};

export default InboxPopover;
