import React from 'react';

import {twx} from '../../utils/tailwindUtils';
import PopupRaw from './PopupRaw';

const PopupCentered = (props) => {
    const {className, ...rest} = props;

    return <PopupRaw className={twx('max-sm:-translate-y-1/2 absolute top-1/2 h-min w-auto', className)} {...rest} />;
};

export default PopupCentered;
