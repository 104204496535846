import cn from 'classnames';
import React, {useState} from 'react';

import {PopupFullPage} from '@/components/Popup';
import appConfig from '@/config/appConfig';
import useScrollToPreviousPosition from '@/hooks/useScrollToPreviousPosition';
import amplitudeHomePageTracking from '@/services/amplitude/amplitudeHomePageTracking';

import HighlightsCarousel from './components/HighlightsCarousel';

let highlightsSorted;

const getHighlights = () => {
    if (highlightsSorted) return highlightsSorted;
    const highlightsFromConfig = appConfig.getHighlights();

    highlightsSorted = highlightsFromConfig?.sort(({banner: a}, {banner: b}) => a?.order - b?.order);

    return highlightsSorted;
};

const HomePageHighlights = ({id, className}) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isFullscreen, setIsFullscreen] = useState(false);

    const trackBannerClick = () => {
        const currentHighlight = highlights[currentIndex];

        if (!currentHighlight) {
            return null;
        }

        const trackingValue = currentHighlight.banner?.amplitudeTrackingValue;

        amplitudeHomePageTracking.trackHighlightBannerClick(trackingValue);
    };

    const onSlideClick = () => {
        trackBannerClick();
        setIsFullscreen(true);
    };
    const onSlideChange = (index) => {
        setCurrentIndex(index);
    };
    const highlights = getHighlights();
    const highlightsAutoplay = appConfig.getHighlightsAutoplay();

    if (!highlights) return;

    return (
        <section id={id} className={cn('bg-romance px-4 py-8 md:bg-transparent md:px-16 md:py-6', className)}>
            {isFullscreen ? (
                <HighlightsCarouselFullscreen
                    setIsFullscreen={setIsFullscreen}
                    currentIndex={currentIndex}
                    highlights={highlights}
                    autoplay={highlightsAutoplay}
                    onSlideChange={onSlideChange}
                />
            ) : (
                <HighlightsCarousel
                    initialSlide={currentIndex}
                    currentIndex={currentIndex}
                    onSlideClick={onSlideClick}
                    highlights={highlights}
                    autoplay={highlightsAutoplay}
                    onSlideChange={onSlideChange}
                />
            )}
        </section>
    );
};

const HighlightsCarouselFullscreen = ({setIsFullscreen, currentIndex, highlights, autoplay, onSlideChange}) => {
    useScrollToPreviousPosition();

    return (
        <PopupFullPage
            className='z-10 bg-romance'
            isCloseVisible
            onClose={() => setIsFullscreen(false)}
            closeButtonClass='text-white top-safe-offset-2 right-2'
        >
            <HighlightsCarousel
                initialSlide={currentIndex}
                currentIndex={currentIndex}
                isFullscreen
                highlights={highlights}
                autoplay={autoplay}
                onSlideChange={onSlideChange}
            />
        </PopupFullPage>
    );
};

export default HomePageHighlights;
