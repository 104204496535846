import cn from 'classnames';
import React, {useState} from 'react';

import SignedImage from '../../containers/System/SignedImage';
import helpers from '../../utils/helpers';
import {twx} from '../../utils/tailwindUtils';
import GuideVideo from '../Video/GuideVideo/GuideVideo';

const PageHeaderMedia = ({
    isVideoAvailable,
    videoThumbnailSrc,
    videoSrc,
    imageSrc,
    defaultImage,
    className,
    videoClassName,
    onVideoError,
    imgClassName,
    ...rest
}) => {
    const [isVideoError, setIsVideoError] = useState(false);

    const handleVideoError = () => {
        setIsVideoError(true);
        helpers.runFunction(onVideoError);
    };

    return (
        <div className={twx('relative min-h-[256px] w-full', className)}>
            {isVideoAvailable && !isVideoError ? (
                <GuideVideo
                    src={videoSrc}
                    thumbnailSrc={videoThumbnailSrc}
                    containerClassName='absolute top-0 left-0 w-full h-full'
                    className={cn('h-full w-full object-cover', videoClassName)}
                    controlsClassName='m-0 right-4 left-[unset] z-20 rtl:left-4 rtl:right-[unset]'
                    onError={handleVideoError}
                    {...rest}
                />
            ) : (
                <SignedImage
                    imgClassName={twx('absolute w-full h-full object-cover shrink-0 top-0 left-0', imgClassName)}
                    mediaData={imageSrc}
                    defaultImage={defaultImage}
                    signIfNull
                    isThumbnailAvailable
                    key={imageSrc}
                />
            )}
        </div>
    );
};

export default PageHeaderMedia;
