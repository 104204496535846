import React from 'react';

import {ButtonRaw} from '@/components/Button';
import {GlobeIcon} from '@/components/Icons';
import {twx} from '@/utils/tailwindUtils';

const LanguageSelectTrigger = ({languageName, isDark, onClick}) => {
    return (
        <ButtonRaw
            className={twx('flex gap-2 self-end font-bold leading-[24px] text-romance underline', {
                'text-primary': isDark,
            })}
            onClick={onClick}
        >
            <div className='flex self-end text-14'>{languageName}</div>
            <GlobeIcon className='h-6 w-6 stroke-2' />
        </ButtonRaw>
    );
};

export default LanguageSelectTrigger;
