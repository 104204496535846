import React, {useState} from 'react';

import InboxPopover from '../../InboxPopover/InboxPopover';
import InboxIconComponent from './InboxIconComponent';

const InboxIconDesktop = ({isUnreadExists}) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleClick = () => {
        setIsOpen(!isOpen);
    };

    return (
        <InboxPopover isOpen={isOpen} setIsOpen={setIsOpen}>
            <InboxIconComponent isUnreadExists={isUnreadExists} onClick={handleClick} />
        </InboxPopover>
    );
};

export default InboxIconDesktop;
