import appConfig from '@/config/appConfig';
import useLocationPathname from '@/hooks/useLocationPathname';
import arrayUtils from '@/utils/arrayUtils';

const usePageSections = () => {
    const {pathname} = useLocationPathname();
    const dynamicPages = appConfig.getDynamicPages();
    const sections = dynamicPages?.find((x) => x.path === pathname)?.pageSections;

    return arrayUtils.sortArrayWithOrderField(sections);
};

export default usePageSections;
