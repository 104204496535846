import {useEffect} from 'react';

import AMPLITUDE_EVENT_VALUES from '../../../../consts/amplitude/amplitudeEventValues';
import {AUTO_DELIVERY_PAYMENT_PENDING_HEADER_TITLE} from '../../../../consts/localization/localizationKeys';
import useDocumentTitle from '../../../../hooks/useDocumentTitle';
import useIsDesktopLayout from '../../../../hooks/useIsDesktopLayout';
import amplitudeAutodeliveryTracking from '../../../../services/amplitude/amplitudeAutoDeliveryTracking';
import {getLocalizedStrings} from '../../../../services/localization/localizationService';
import StatusPaymentPendingDesktop from './StatusPaymentPendingDesktop';
import StatusPaymentPendingMobile from './StatusPaymentPendingMobile';

const StatusPaymentPending = () => {
    const isDesktopLayout = useIsDesktopLayout();
    const localizedStrings = getLocalizedStrings();

    useDocumentTitle(localizedStrings[AUTO_DELIVERY_PAYMENT_PENDING_HEADER_TITLE]);

    const amplitudeEvent = () => {
        amplitudeAutodeliveryTracking.trackAutoDeliveryOrderButtonClick(
            AMPLITUDE_EVENT_VALUES.CHECK_ORDER_STATUS,
            AMPLITUDE_EVENT_VALUES.PAYMENT_PENDING
        );
    };

    useEffect(() => {
        amplitudeAutodeliveryTracking.trackAutoDeliveryOrderScreen(AMPLITUDE_EVENT_VALUES.PAYMENT_PENDING);
    }, []);

    return isDesktopLayout ? (
        <StatusPaymentPendingDesktop amplitudeEvent={amplitudeEvent} />
    ) : (
        <StatusPaymentPendingMobile amplitudeEvent={amplitudeEvent} />
    );
};

export default StatusPaymentPending;
