import {useGetAutoDeliveryDetailsExtended} from '../../../../api/consumerApi';
import AemEditableText from '../../../../components/Aem/AemEditableText/AemEditableText';
import {AppButton} from '../../../../components/Button';
import {StickyPageLayout} from '../../../../components/Layout';
import {AUTO_DELIVERY_HEADER_TITLE} from '../../../../consts/localization/localizationKeys';
import {getLocalizedStrings} from '../../../../services/localization/localizationService';
import appRouterService from '../../../../services/route/appRouterService';
import routerService from '../../../../services/route/routerService';
import AutoDeliveryActiveProgress from '../../../../views/AutoDelivery/AutoDeliveryActive/AutoDeliveryActiveProgress';
import AutoDeliveryActiveSubtitle from '../../../../views/AutoDelivery/AutoDeliveryActive/AutoDeliveryActiveSubtitle';
import AutoDeliveryActiveTitle from '../../../../views/AutoDelivery/AutoDeliveryActive/AutoDeliveryActiveTitle';
import PageWarningBannersContainer from '../../../WarningBanners/PageWarningBannersContainer';
import AutoDeliveryDetailsFooter from '../components/AutoDeliveryDetailsFooter';
import AutoDeliveryOrder from '../components/AutoDeliveryOrder';
import AutoDeliveryOrderAddress from '../components/AutoDeliveryOrderAddress';
import AutoDeliveryOrderHeader from '../components/AutoDeliveryOrderHeader';
import AutoDeliveryOrderSummary from '../components/AutoDeliveryOrderSummary';

const StatusActiveMobile = () => {
    const {
        data: {
            autoDelivery: {minDeliveryDaysCount, maxDeliveryDaysCount, nextDeliveryDate, maxNextDeliveryDate, status},
            order: {status: orderStatus, price, deliveryAddress, products},
        },
    } = useGetAutoDeliveryDetailsExtended();
    const localizedStrings = getLocalizedStrings();

    return (
        <StickyPageLayout
            contentClassName='flex-1 text-start'
            headerOptions={{
                onBack: routerService.goBack,
                title: localizedStrings[AUTO_DELIVERY_HEADER_TITLE],
            }}
            footer={
                <AutoDeliveryDetailsFooter>
                    <AppButton primary light className='mt-0' onClick={appRouterService.forwardToAutoDeliveryAsapPage}>
                        <AemEditableText pageName='auto-delivery' itemName='deliverAsapButton' />
                    </AppButton>
                    <AppButton
                        tertiary
                        className='self-center'
                        onClick={appRouterService.forwardToAutoDeliverySkipPage}
                    >
                        <AemEditableText pageName='auto-delivery' itemName='skipDeliveryButton' />
                    </AppButton>
                </AutoDeliveryDetailsFooter>
            }
            noScrollFooterClassName='border-t border-mischka pt-4'
            footerClassName='bg-romance border-t-[1px] border-french-gray pb-safe-or-4'
        >
            <div className='p-4 pt-safe-offset-14'>
                <AutoDeliveryActiveSubtitle
                    minDeliveryDaysCount={minDeliveryDaysCount}
                    maxDeliveryDaysCount={maxDeliveryDaysCount}
                    className='text-20 font-bold tracking-02'
                />
                <AutoDeliveryActiveTitle
                    nextDeliveryDate={nextDeliveryDate}
                    maxNextDeliveryDate={maxNextDeliveryDate}
                    className='mt-2 text-28'
                />
                <AutoDeliveryActiveProgress status={status} orderStatus={orderStatus} className='mt-8 text-16' />
            </div>
            <AutoDeliveryOrder className='border-t-8 border-secondary' products={products}>
                <AutoDeliveryOrderHeader isEditAllowed />
            </AutoDeliveryOrder>
            <AutoDeliveryOrderSummary className='border-t-8 border-secondary' price={price} />
            <AutoDeliveryOrderAddress className='border-t-8 border-secondary' address={deliveryAddress} isEditAllowed />
            <PageWarningBannersContainer />
        </StickyPageLayout>
    );
};

export default StatusActiveMobile;
