import React, {useEffect} from 'react';

import StickyHeader from '../../components/Layout/StickyHeader';
import {CONTACT_US_PAGE_HEADER_TITLE} from '../../consts/localization/localizationKeys';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import amplitudeProfileTracking from '../../services/amplitude/amplitudeProfileTracking';
import {getLocalizedStrings} from '../../services/localization/localizationService';
import routerService from '../../services/route/routerService';
import ContactUsCallUs from './components/ContactUsCallUs';
import ContactUsComeVisitUs from './components/ContactUsComeVisitUs';
import ContactUsHeader from './components/ContactUsHeader';
import ContactUsSendQuestion from './components/ContactUsSendQuestion';
import ContactUsSocialChannels from './components/ContactUsSocialChannels';
import useGetContactUs from './hooks/useGetContactUs';

const ContactUsPage = () => {
    const localizedStrings = getLocalizedStrings();

    useDocumentTitle(localizedStrings[CONTACT_US_PAGE_HEADER_TITLE]);

    useEffect(() => {
        window.scrollTo(0, 0);
        amplitudeProfileTracking.trackContactUsScreen();
    }, []);

    const {imagePath, socialChannels, email, nearestStoreAction, callUs} = useGetContactUs();

    return (
        <div className='h-full w-full overflow-hidden'>
            <div className='relative mb-8 flex h-full flex-col gap-8'>
                <StickyHeader onBack={routerService.goBack} title={localizedStrings[CONTACT_US_PAGE_HEADER_TITLE]} />
                <ContactUsHeader imagePath={imagePath} />
                <div className='flex flex-col items-center gap-16 px-4'>
                    <ContactUsSocialChannels socialChannels={socialChannels} />
                    <ContactUsSendQuestion email={email} />
                    <ContactUsComeVisitUs nearestStoreAction={nearestStoreAction} />
                    <ContactUsCallUs callUs={callUs} />
                </div>
            </div>
        </div>
    );
};

export default ContactUsPage;
