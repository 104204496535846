import cn from 'classnames';
import React from 'react';
import {useSelector} from 'react-redux';

import domService from '@/services/domService';
import tncService from '@/services/tncService';
import {makeSelectTncLegalsByType, selectTncLegals} from '@/state/selectors/consumer';

import styles from './TermsOfUseDescription.module.scss';

const TermsOfUseDescription = ({innerHtml, className, tncType, onTncLinkClick}) => {
    const legals = useSelector(selectTncLegals);
    const tncLegals = useSelector(makeSelectTncLegalsByType(tncType));
    const tncLinkClass = styles.TncLink;

    const handleReadButtonClick = (e) => {
        const {target} = e;
        const tncCode = tncService.getTncCodeFromTarget(target, tncLinkClass);

        if (tncCode) {
            onTncLinkClick(tncCode);
        }
    };

    const textParsed = tncService.getParsedDescription(innerHtml, tncLinkClass, legals, tncLegals);

    const onKeyPress = (e) => domService.handleBtnKeyPress(e, handleReadButtonClick);

    return (
        <p
            className={cn('whitespace-pre-line', className)}
            dangerouslySetInnerHTML={{__html: textParsed}}
            onClick={handleReadButtonClick}
            onKeyPress={onKeyPress}
        />
    );
};

export default TermsOfUseDescription;
