import cn from 'classnames';
import React from 'react';

import AemEditableText from '@/components/Aem/AemEditableText/AemEditableText';

const LanguageSelectContentTitle = ({className}) => {
    return (
        <h2 className={cn('m-0 p-0 text-left lg:pr-8', className)}>
            <AemEditableText pageName='modals' itemName='languageselecttitle' />
        </h2>
    );
};

export default LanguageSelectContentTitle;
