import {useDocumentTitle} from 'usehooks-ts';

import {useGetAutoDeliveryDetailsExtended} from '../../../../api/consumerApi';
import * as localizationKeys from '../../../../consts/localization/localizationKeys';
import useIsDesktopLayout from '../../../../hooks/useIsDesktopLayout';
import {getLocalizedStrings} from '../../../../services/localization/localizationService';
import routerService from '../../../../services/route/routerService';
import EditDeliveryDesktop from './EditDeliveryDesktop';
import EditDeliveryMobile from './EditDeliveryMobile';

const EditDelivery = ({isAsap, isSkip}) => {
    const isDesktopLayout = useIsDesktopLayout();
    const {data} = useGetAutoDeliveryDetailsExtended();
    const localizedStrings = getLocalizedStrings();
    const {
        autoDelivery: {isSkipDeliveryAvailable},
    } = data;
    const onClose = () => {
        routerService.goBack();
    };
    const title = isAsap
        ? localizedStrings[localizationKeys.AUTO_DELIVERY_DELIVERY_ASAP_HEADER_TITLE]
        : localizedStrings[localizationKeys.AUTO_DELIVERY_SKIP_DELIVERY_CONFIRM_HEADER_TITLE];

    const isShowSkipButton = isSkip && isSkipDeliveryAvailable;
    const props = {
        onClose,
        isShowSkipButton,
        isAsap,
        isSkip,
        data,
        title,
    };

    useDocumentTitle(title);

    return isDesktopLayout ? <EditDeliveryDesktop {...props} /> : <EditDeliveryMobile {...props} />;
};

export default EditDelivery;
