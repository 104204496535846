import React from 'react';

import AemEditableText from '../../../../components/Aem/AemEditableText/AemEditableText';

const DevicesPageHeader = () => {
    return (
        <article className='pb-4 mt-safe-offset-14 md:max-w-[398px] md:mt-safe-offset-24'>
            <h1 className='mb-4 text-32 md:text-center md:leading-[40px]'>
                <AemEditableText pageName='devices' itemName='setupyouriqos' />
            </h1>
            <p className='md:text-center md:text-16 md:leading-[24px]'>
                <AemEditableText pageName='devices' itemName='connectyouriqosdesc' />
            </p>
        </article>
    );
};

export default DevicesPageHeader;
