import {useState} from 'react';

import {updateAutoDeliveryDetails} from '../../../../api/consumerApi';
import AemEditableText from '../../../../components/Aem/AemEditableText/AemEditableText';
import {AppButton} from '../../../../components/Button';
import {ConfirmationIcon, ErrorBigIcon} from '../../../../components/Icons';
import AppLoader from '../../../../components/Loader/AppLoader/AppLoader';
import amplitudeAutoDeliveryTracking from '../../../../services/amplitude/amplitudeAutoDeliveryTracking';
import iccAutoDeliveryService from '../../../../services/icc/iccAutoDeliveryService';
import routerService from '../../../../services/route/routerService';
import helpers from '../../../../utils/helpers';
import stringUtils from '../../../../utils/stringUtils';
import useDeliveryDate from '../hooks/useDeliveryDate';
import useOrderSummaryTracking from '../hooks/useOrderSummaryTracking';
import DeliveryAsapConfirmPopup from './DeliveryAsapConfirmPopup';
import EditDeliveryResultPopup from './EditDeliveryResultPopup';

const ConfirmAsapButton = ({closePopup}) => {
    const {days, asapDeliveryDay, asapDeliveryMonth, asapDeliveryDateRaw} = useDeliveryDate();
    const [isLoading, setIsLoading] = useState(false);
    const [isConfirmPopupVisible, setIsConfirmPopupVisible] = useState(false);
    const [isSuccessPopupVisible, setIsSuccessPopupVisible] = useState(false);
    const [isErrorPopupVisible, setIsErrorPopupVisible] = useState(false);
    const orderSummaryTrackingValue = useOrderSummaryTracking();

    const onConfirmClick = () => {
        amplitudeAutoDeliveryTracking.trackAutoDeliveryDeliverAsapStarted(orderSummaryTrackingValue);
        setIsConfirmPopupVisible(true);
    };
    const onClose = () => setIsConfirmPopupVisible(false);
    const onConfirmSubmit = () => {
        setIsConfirmPopupVisible(false);
        setIsLoading(true);

        iccAutoDeliveryService.deliverAsap({
            asapDeliveryDate: asapDeliveryDateRaw,
            onSuccess: (responseData) => {
                setIsLoading(false);
                setIsSuccessPopupVisible(true);
                updateAutoDeliveryDetails(responseData);
                amplitudeAutoDeliveryTracking.trackAutoDeliveryDeliverAsapCompleted({
                    success: true,
                    orderSummary: orderSummaryTrackingValue,
                });
            },
            onError: (errorMessage) => {
                setIsLoading(false);
                setIsErrorPopupVisible(true);
                amplitudeAutoDeliveryTracking.trackAutoDeliveryDeliverAsapCompleted({
                    success: false,
                    message: errorMessage,
                    orderSummary: orderSummaryTrackingValue,
                });
            },
        });
    };
    const onResultPopupClose = () => {
        setIsErrorPopupVisible(false);
        setIsSuccessPopupVisible(false);
        helpers.runFunction(closePopup);
    };
    const onBackButtonClick = () => {
        routerService.go(-2);
    };

    return (
        <>
            <AppButton primary light className='mt-0' onClick={onConfirmClick}>
                Confirm
            </AppButton>
            {isLoading && <AppLoader />}
            {isConfirmPopupVisible && <DeliveryAsapConfirmPopup onConfirm={onConfirmSubmit} onClose={onClose} />}
            {isSuccessPopupVisible && (
                <EditDeliveryResultPopup
                    icon={<ConfirmationIcon />}
                    title={<AemEditableText pageName='auto-delivery' itemName='deliverAsapPopupSuccessTitle' />}
                    description={
                        <AemEditableText
                            pageName='auto-delivery'
                            itemName='deliverAsapPopupSuccessDescription'
                            render={(text) => {
                                const textFormatted = stringUtils.formatString(
                                    text,
                                    days,
                                    asapDeliveryDay,
                                    asapDeliveryMonth
                                );

                                return textFormatted ? (
                                    <span dangerouslySetInnerHTML={{__html: textFormatted}} />
                                ) : null;
                            }}
                        />
                    }
                    button={<AemEditableText pageName='auto-delivery' itemName='deliverAsapPopupSuccessButton' />}
                    onButtonClick={onBackButtonClick}
                    onClose={onResultPopupClose}
                />
            )}
            {isErrorPopupVisible && (
                <EditDeliveryResultPopup
                    icon={<ErrorBigIcon />}
                    title={<AemEditableText pageName='auto-delivery' itemName='deliverAsapPopupErrorTitle' />}
                    description={
                        <AemEditableText pageName='auto-delivery' itemName='deliverAsapPopupErrorDescription' />
                    }
                    button={<AemEditableText pageName='auto-delivery' itemName='deliverAsapPopupErrorButton' />}
                    onButtonClick={onBackButtonClick}
                    onClose={onResultPopupClose}
                />
            )}
        </>
    );
};

export default ConfirmAsapButton;
