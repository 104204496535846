import React from 'react';
import {useSelector} from 'react-redux';

import {useGetAutoDeliveryDetails} from '@/api/consumerApi';
import {AUTO_DELIVERY_VIEW_ORDER_LINK} from '@/consts/localization/localizationKeys';
import amplitudeAutodeliveryTracking from '@/services/amplitude/amplitudeAutoDeliveryTracking';
import {selectIsMobileLayout} from '@/state/selectors/global';
import AutoDeliveryActiveProgress from '@/views/AutoDelivery/AutoDeliveryActive/AutoDeliveryActiveProgress';
import AutoDeliveryOnHoldButton from '@/views/AutoDelivery/AutoDeliveryOnHold/AutoDeliveryOnHoldButton';
import AutoDeliveryOnHoldInfoBox from '@/views/AutoDelivery/AutoDeliveryOnHold/AutoDeliveryOnHoldInfoBox';
import AutoDeliveryOnHoldSubtitle from '@/views/AutoDelivery/AutoDeliveryOnHold/AutoDeliveryOnHoldSubtitle';
import AutoDeliveryOnHoldTitle from '@/views/AutoDelivery/AutoDeliveryOnHold/AutoDeliveryOnHoldTitle';

import AutoDeliveryWidgetContainer from './components/AutoDeliveryWidgetContainer';
import AutoDeliveryWidgetHeader from './components/AutoDeliveryWidgetHeader';

const AutoDeliveryStatusOnHold = ({className}) => {
    const isMobileLayout = useSelector(selectIsMobileLayout);
    const {
        data: {
            autoDelivery: {statusUpdateReason, status},
            order: {status: orderStatus},
        },
    } = useGetAutoDeliveryDetails();

    const amplitudeEvent = (trackingValue) => {
        amplitudeAutodeliveryTracking.trackAutoDeliveryDashboardButtonClick(trackingValue);
    };

    return (
        <AutoDeliveryWidgetContainer className={className}>
            <AutoDeliveryWidgetHeader btnLocalizationKey={AUTO_DELIVERY_VIEW_ORDER_LINK} />
            <AutoDeliveryWidgetContainer.ContentContainer>
                <AutoDeliveryOnHoldInfoBox
                    statusUpdateReason={statusUpdateReason}
                    className='mt-4 md:mt-0 md:flex md:items-center md:justify-between md:gap-2'
                >
                    {!isMobileLayout && (
                        <AutoDeliveryOnHoldButton
                            statusUpdateReason={statusUpdateReason}
                            amplitudeEvent={amplitudeEvent}
                            className='mt-0 h-12 w-auto'
                        />
                    )}
                </AutoDeliveryOnHoldInfoBox>
                <AutoDeliveryOnHoldSubtitle statusUpdateReason={statusUpdateReason} className='mt-4 md:mt-8' />
                <AutoDeliveryOnHoldTitle className='md:mt-2' />
                <AutoDeliveryActiveProgress status={status} orderStatus={orderStatus} className='mt-4 md:mt-8' />
                {isMobileLayout && (
                    <AutoDeliveryOnHoldButton
                        statusUpdateReason={statusUpdateReason}
                        amplitudeEvent={amplitudeEvent}
                        className='mt-4'
                    />
                )}
            </AutoDeliveryWidgetContainer.ContentContainer>
        </AutoDeliveryWidgetContainer>
    );
};

export default AutoDeliveryStatusOnHold;
