import cn from 'classnames';
import React, {useRef, useState} from 'react';
import Slider from 'react-slick';

import useHighlightsAutoplay from '../hooks/useHighlightsAutoplay';
import HighlightItem from './HighlightItem';
import styles from './HighlightsCarousel.module.scss';

const HighlightsCarousel = ({
    initialSlide,
    onSlideClick,
    isFullscreen,
    highlights,
    autoplay,
    onSlideChange,
    currentIndex,
}) => {
    const [currentIndexBeforeChange, setCurrentIndexBeforeChange] = useState(currentIndex);
    const sliderRef = useRef(null);
    const {animationDuration, moveToNextSlide, onLoadedMetadata, onVideoError} = useHighlightsAutoplay(
        sliderRef,
        highlights,
        autoplay,
        currentIndexBeforeChange
    );

    const options = {
        arrows: false,
        infinite: true,
        dots: true,
        appendDots: (dots) => (
            <div>
                <div className={cn('absolute left-0 right-0', isFullscreen ? 'top-safe-offset-14' : 'top-10')}>
                    <ul className='flex gap-2 px-4'>{dots}</ul>
                </div>
            </div>
        ),
        customPaging: (i) => {
            return (
                <div className='h-1 cursor-pointer overflow-hidden rounded-lg bg-white/50'>
                    <span
                        onAnimationEnd={moveToNextSlide}
                        className={cn(
                            'block h-full bg-white',
                            {'w-full': currentIndexBeforeChange > i},
                            {animated: animationDuration}
                        )}
                        style={{'--duration': `${animationDuration}s`}}
                    />
                </div>
            );
        },
        initialSlide,
        afterChange: (newIndex) => {
            onSlideChange(newIndex);
        },
        beforeChange: (oldIndex, newIndex) => {
            setCurrentIndexBeforeChange(newIndex);
        },
    };
    const onClick = () => {
        if (!onSlideClick) return;
        onSlideClick();
    };

    return (
        <div
            className={cn(styles.Carousel, 'overflow-hidden', {
                'rounded-xl': !isFullscreen,
                [styles.CarouselPreview]: !isFullscreen,
                [styles.Progress]: autoplay,
            })}
        >
            <Slider {...options} ref={sliderRef}>
                {highlights.map((highlight, i) => {
                    return (
                        <HighlightItem
                            key={i}
                            onClick={onClick}
                            loop={!autoplay}
                            isFullscreen={isFullscreen}
                            isActiveSlide={currentIndex === i}
                            onEnded={moveToNextSlide}
                            onVideoError={() => onVideoError(i)}
                            onLoadedMetadata={(metadata) => onLoadedMetadata(metadata, i)}
                            {...highlight}
                        />
                    );
                })}
            </Slider>
        </div>
    );
};

export default HighlightsCarousel;
