import {createApi} from '@reduxjs/toolkit/query/react';

import appConfig from '../config/appConfig';
import {INBOX_STATUS} from '../consts/icc/iccMethods';
import objUtils from '../utils/objUtils';
import {getBaseQuery} from './apiUtils';

export const iccNotificationApi = createApi({
    reducerPath: 'iccNotificationApi',
    baseQuery: getBaseQuery(() => appConfig.getIccNotificationApiUrl()),
    endpoints: (builder) => ({
        getInboxStatus: builder.query({
            query: () => INBOX_STATUS,
            transformResponse: objUtils.objectKeysToLowerCase,
        }),
    }),
});

const POLLING_INTERVAL = 30 * 60 * 1000;

export const useGetInboxStatus = () =>
    iccNotificationApi.useGetInboxStatusQuery(null, {
        pollingInterval: POLLING_INTERVAL,
    });
