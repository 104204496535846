import React, {useEffect, useRef, useState} from 'react';
import {useSelector} from 'react-redux';

import AemComponent from '../../components/Aem/AemComponent/AemComponent';
import EditableDeviceRenameItems from '../../components/Aem/EditableDeviceRenameItems/EditableDeviceRenameItems';
import EditableText from '../../components/Aem/EditableText/EditableText';
import {AppButton} from '../../components/Button';
import {RenameBlueIcon} from '../../components/Icons';
import {StickyPageLayout} from '../../components/Layout';
import {DEVICE_RENAME_PAGE_HEADER_TITLE} from '../../consts/localization/localizationKeys';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import amplitudeDeviceTracking from '../../services/amplitude/amplitudeDeviceTracking';
import domService from '../../services/domService';
import {getLocalizedStrings} from '../../services/localization/localizationService';
import routerService from '../../services/route/routerService';
import {selectCurrentProductInfo} from '../../state/selectors/device';
import PageWarningBannersContainer from '../WarningBanners/PageWarningBannersContainer';
import useOnRenameClick from './hooks/useOnRenameClick';

const DEFAULT_NAME_OPTION = 'defaultNameOption';
const PERSONALIZED_NAME_OPTION = 'personalizedNameOption';

const DeviceRenamePage = () => {
    const device = useSelector(selectCurrentProductInfo);
    const {codentify, defaultProductName, productName} = device;

    const currentDeviceName = productName || '';
    const isDefaultName = !productName;
    const actualMode = isDefaultName ? DEFAULT_NAME_OPTION : PERSONALIZED_NAME_OPTION;

    const inputRef = useRef();
    const [currentMode, setCurrentMode] = useState(actualMode);
    const [deviceName, setDeviceName] = useState(currentDeviceName);

    const isDefaultMode = currentMode === DEFAULT_NAME_OPTION;
    const isSuccess = deviceName && deviceName === currentDeviceName;
    const deviceNameToSave = deviceName === '' ? null : deviceName;
    const localizedStrings = getLocalizedStrings();

    useDocumentTitle(localizedStrings[DEVICE_RENAME_PAGE_HEADER_TITLE]);

    useEffect(() => {
        window.scrollTo(0, 0);
        amplitudeDeviceTracking.trackRenameDeviceScreen();
    }, []);

    const {onRenameClick} = useOnRenameClick();

    const onSaveClick = () => {
        amplitudeDeviceTracking.trackRenameDeviceSave();
        onRenameClick(deviceNameToSave, isDefaultMode, codentify);
    };

    const clearInput = () => {
        const emptyDeviceName = '';

        setDeviceName(emptyDeviceName);
        setCurrentMode(PERSONALIZED_NAME_OPTION);
        domService.setFocusToDomElement(inputRef.current);
    };
    const resetToDefault = () => {
        setDeviceName('');
        setCurrentMode(DEFAULT_NAME_OPTION);
    };

    const onChange = ({target}) => {
        const name = target.value;

        setDeviceName(name);
        setCurrentMode(PERSONALIZED_NAME_OPTION);
    };

    const setPersonalizedMode = () => {
        setDeviceName(defaultProductName);
        setCurrentMode(PERSONALIZED_NAME_OPTION);
    };

    return (
        <StickyPageLayout
            className='items-center'
            headerOptions={{
                onBack: routerService.goBack,
                title: localizedStrings[DEVICE_RENAME_PAGE_HEADER_TITLE],
            }}
            contentClassName='flex flex-1 flex-col items-center'
            footer={
                <AppButton primary light onClick={onSaveClick} className='m-auto'>
                    <AemComponent component={EditableText} pageName='device-rename' itemName='devicerenamesavebutton' />
                </AppButton>
            }
        >
            <div className='mb-9 flex flex-col items-center px-8 pt-safe-offset-14'>
                <RenameBlueIcon className='h-16 w-16' />
                <h4 className='mt-4 text-28 md:mt-2 lg:mt-3 lg:text-32'>
                    <AemComponent component={EditableText} pageName='device-rename' itemName='devicerenametitle' />
                </h4>
                <p>
                    <span className='whitespace-pre-line'>
                        <AemComponent component={EditableText} pageName='device-rename' itemName='devicerenamedesc' />
                    </span>
                </p>
            </div>
            <AemComponent
                component={EditableDeviceRenameItems}
                pageName='device-rename'
                itemName='devicerenameitems'
                defaultProductName={defaultProductName}
                isDefaultMode={isDefaultMode}
                resetToDefault={resetToDefault}
                setPersonalizedMode={setPersonalizedMode}
                deviceName={deviceName}
                isSuccess={isSuccess}
                onChange={onChange}
                clearInput={clearInput}
                inputRef={inputRef}
            />
            <PageWarningBannersContainer />
        </StickyPageLayout>
    );
};

export default DeviceRenamePage;
