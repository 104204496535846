import {useGetAutoDeliveryDetailsExtended} from '../../../../api/consumerApi';
import AemEditableText from '../../../../components/Aem/AemEditableText/AemEditableText';
import {StickyHeader} from '../../../../components/Layout';
import {AUTO_DELIVERY_ON_HOLD_HEADER_TITLE} from '../../../../consts/localization/localizationKeys';
import {getLocalizedStrings} from '../../../../services/localization/localizationService';
import routerService from '../../../../services/route/routerService';
import AutoDeliveryOnHoldButton from '../../../../views/AutoDelivery/AutoDeliveryOnHold/AutoDeliveryOnHoldButton';
import AutoDeliveryOnHoldInfoBox from '../../../../views/AutoDelivery/AutoDeliveryOnHold/AutoDeliveryOnHoldInfoBox';
import AutoDeliveryStatusTitle from '../../../../views/AutoDelivery/AutoDeliveryStatusTitle';
import PageWarningBannersContainer from '../../../WarningBanners/PageWarningBannersContainer';
import AutoDeliveryDetailsFooter from '../components/AutoDeliveryDetailsFooter';
import AutoDeliveryOrder from '../components/AutoDeliveryOrder';
import AutoDeliveryOrderAddress from '../components/AutoDeliveryOrderAddress';
import AutoDeliveryOrderHeader from '../components/AutoDeliveryOrderHeader';

const StatusOnHoldMobile = ({amplitudeEvent}) => {
    const localizedStrings = getLocalizedStrings();
    const {
        data: {
            autoDelivery: {statusUpdateReason},
            order: {deliveryAddress, products},
        },
    } = useGetAutoDeliveryDetailsExtended();

    return (
        <div className='w-full text-start'>
            <StickyHeader onBack={routerService.goBack} title={localizedStrings[AUTO_DELIVERY_ON_HOLD_HEADER_TITLE]} />
            <div className='p-4 pt-safe-offset-14'>
                <AutoDeliveryStatusTitle
                    className='text-28'
                    title={<AemEditableText pageName='auto-delivery' itemName='autoDeliveryOnHoldTitle' />}
                />
                <AutoDeliveryOnHoldInfoBox statusUpdateReason={statusUpdateReason} className='mt-8'>
                    <AutoDeliveryOnHoldButton
                        statusUpdateReason={statusUpdateReason}
                        amplitudeEvent={amplitudeEvent}
                        className='mt-8'
                    />
                </AutoDeliveryOnHoldInfoBox>
            </div>
            <AutoDeliveryOrder className='border-t-8 border-secondary' products={products}>
                <AutoDeliveryOrderHeader />
            </AutoDeliveryOrder>
            <AutoDeliveryOrderAddress className='border-t-8 border-secondary' address={deliveryAddress} />
            <AutoDeliveryDetailsFooter className='border-t-8 border-secondary px-4 py-[34px]' />
            <PageWarningBannersContainer />
        </div>
    );
};

export default StatusOnHoldMobile;
