import {useSelector} from 'react-redux';

import appConfig from '@/config/appConfig';
import useConfigExternalLink from '@/hooks/externalLink/useConfigExternalLink';

import {selectDiagnosticFlowDataResult} from '../state/advancedDiagnosticSelectors';

const useADMarketScreen = () => {
    const diagnosticResult = useSelector(selectDiagnosticFlowDataResult);
    const {
        deviceInferenceCode,
        deviceInferenceCode_L1,
        holderInferenceCode,
        holderInferenceCode_L1,
        isQuestionnaireThirdStep,
    } = diagnosticResult || {};

    const supportExternalLink = appConfig.getSupportExternalLink();
    const redirectToSupport = useConfigExternalLink(supportExternalLink);

    const inferenceCode = deviceInferenceCode ? deviceInferenceCode : holderInferenceCode;
    // eslint-disable-next-line no-unused-vars
    const inferenceCode_L1 = deviceInferenceCode_L1 ? deviceInferenceCode_L1 : holderInferenceCode_L1;
    const isHolder = inferenceCode === holderInferenceCode;

    return {inferenceCode, redirectToSupport, isHolder, isQuestionnaireThirdStep};
};

export default useADMarketScreen;
