import AemComponent from '../../../components/Aem/AemComponent/AemComponent';
import EditableText from '../../../components/Aem/EditableText/EditableText';
import {AppButton} from '../../../components/Button';

const EnableNotificationModalButton = ({onClick}) => {
    return (
        <AppButton className='m-auto lg:w-full' primary light onClick={onClick}>
            <AemComponent component={EditableText} pageName='enable-notification' itemName='enableNotificationButton' />
        </AppButton>
    );
};

export default EnableNotificationModalButton;
