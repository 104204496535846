import React from 'react';
import {useSelector} from 'react-redux';

import {selectIsTncPopupVisible} from '../../../state/selectors/tnc';
import TermsOfUsePopupView from './TermsOfUsePopupView';

const TermsOfUsePopup = () => {
    const isTncPopupVisible = useSelector(selectIsTncPopupVisible);

    if (!isTncPopupVisible) return;

    return <TermsOfUsePopupView />;
};

export default TermsOfUsePopup;
