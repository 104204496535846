import React from 'react';

import domIdentifiers from '@/consts/app/domIdentifiers';
import useIsMobileLayout from '@/hooks/useIsMobileLayout';
import useOnScroll from '@/hooks/useOnScroll';
import reactUtils from '@/utils/reactUtils';
import {twx} from '@/utils/tailwindUtils';

import BackButton from '../BackButton/BackButton';

const StickyHeader = (props) => {
    const isMobileLayout = useIsMobileLayout();

    if (!isMobileLayout) return;

    return <StickyHeaderComponent {...props} />;
};

const StickyHeaderComponent = ({
    onBack,
    title,
    startIcon: startIconOrFunc,
    endIcon: endIconOrFunc,
    scrollElement = window,
    className,
}) => {
    const {opacity, isSticked} = useHeaderOpacity({scrollElement});

    const parameters = {isSticked};
    const startIcon = reactUtils.getOrCreateComponent({
        component: startIconOrFunc,
        parameters,
    });
    const endIcon = reactUtils.getOrCreateComponent({
        component: endIconOrFunc,
        parameters,
    });

    return (
        <header
            id={domIdentifiers.STICKY_HEADER_ID}
            className={twx('fixed left-0 top-0 isolate z-30 w-full pb-4 pt-safe-or-4', className)}
        >
            <div
                style={{opacity}}
                className='absolute left-0 top-0 h-full w-full border-b border-border bg-romance transition-opacity ease-out'
            />
            <div className='relative z-10 flex h-8 items-center justify-between gap-4 px-4'>
                <div className='relative w-16'>
                    {startIcon ||
                        (onBack && (
                            <BackButton onClick={onBack} className='absolute -left-3 top-1/2 -translate-y-1/2' />
                        ))}
                </div>
                {title && (
                    <div
                        style={{opacity}}
                        className='truncate text-center text-14 font-bold tracking-02 transition-opacity ease-out'
                    >
                        {title}
                    </div>
                )}
                <div className='relative flex w-16 justify-end'>{endIcon}</div>
            </div>
        </header>
    );
};

const SCROLL_TOP_FOR_VISIBLE_HEADER = 30;
const SCROLL_TOP_OFFSET = 10;
const useHeaderOpacity = ({scrollElement}) => {
    const scrollTop = useOnScroll({scrollElement});

    const scrolledValue = scrollTop - SCROLL_TOP_OFFSET;
    const opacity = scrolledValue > SCROLL_TOP_FOR_VISIBLE_HEADER ? 1 : scrolledValue / SCROLL_TOP_FOR_VISIBLE_HEADER;

    return {opacity, isSticked: opacity > 0};
};

export default StickyHeader;
