import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';

import appConfig from '@/config/appConfig';
import AMPLITUDE_EVENT_VALUES from '@/consts/amplitude/amplitudeEventValues';
import {AUTO_DELIVERY_DETAILS_INACTIVE_HEADER_TITLE} from '@/consts/localization/localizationKeys';
import amplitudeAutodeliveryTracking from '@/services/amplitude/amplitudeAutoDeliveryTracking';
import {hideAutoDeliveryStatusInactiveDetailsPopup} from '@/state/slices/modalSlice';

import AutoDeliveryDetailsStatusNoneTemplate from './components/AutoDeliveryDetailsStatusNoneTemplate';

const AutoDeliveryStatusInactiveDetailsPopup = () => {
    const dispatch = useDispatch();
    const banner = appConfig.getAutoDeliveryDetailedBannerInactive();
    const benefitsList = appConfig.getAutoDeliveryStatusInactiveBenefitsIcons();
    const howItWorksList = appConfig.getAutoDeliveryStatusInactiveHowItWorksIcons();

    const amplitudeEvent = () => {
        amplitudeAutodeliveryTracking.trackAutoDeliveryOrderButtonClick(
            AMPLITUDE_EVENT_VALUES.REACTIVATE,
            AMPLITUDE_EVENT_VALUES.PAUSED
        );
    };

    useEffect(() => {
        amplitudeAutodeliveryTracking.trackAutoDeliveryOrderScreen(AMPLITUDE_EVENT_VALUES.PAUSED);
    }, []);

    const onClose = () => dispatch(hideAutoDeliveryStatusInactiveDetailsPopup());

    return (
        <AutoDeliveryDetailsStatusNoneTemplate
            banner={banner}
            headerKey={AUTO_DELIVERY_DETAILS_INACTIVE_HEADER_TITLE}
            benefitsItemName='benefitsInactiveTitle'
            howItWorksItemName='howItWorksInactiveTitle'
            benefitsList={benefitsList}
            howItWorksList={howItWorksList}
            amplitudeEvent={amplitudeEvent}
            onClose={onClose}
        />
    );
};

export default AutoDeliveryStatusInactiveDetailsPopup;
