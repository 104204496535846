import useIsMobileLayout from '../../hooks/useIsMobileLayout';
import TncDetailsContent from './components/TncDetailsContent/TncDetailsContent';
import TncSidebar from './components/TncSidebar/TncSidebar';
import useTncDetailsPage from './useTncDetailsPage';

const TncDetailsPage = () => {
    const isMobileLayout = useIsMobileLayout();
    const {
        handlePreferenceCenterClick,
        handleTermsOfUseClick,
        handlePrivacyNoticeClick,
        handleAttributionNoticeClick,
        pageType,
    } = useTncDetailsPage();

    return (
        <div className='flex w-full flex-1 bg-romance'>
            {!isMobileLayout && (
                <div className='w-[var(--tnc-sidebar-width)] bg-secondary'>
                    <TncSidebar
                        onPreferenceCenterClick={handlePreferenceCenterClick}
                        onTermsOfUseClick={handleTermsOfUseClick}
                        onPrivacyNoticeClick={handlePrivacyNoticeClick}
                        onAttributionNoticeClick={handleAttributionNoticeClick}
                        pageType={pageType}
                    />
                </div>
            )}
            <div className='flex-1 md:flex md:max-h-screen md:flex-col md:overflow-auto'>
                <TncDetailsContent pageType={pageType} onMandatoryTermsAccept={handlePreferenceCenterClick} />
            </div>
        </div>
    );
};

export default TncDetailsPage;
