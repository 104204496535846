import useIsDesktopLayout from '@/hooks/useIsDesktopLayout';

import LanguageSelectPopover from './components/LanguageSelectPopover/LanguageSelectPopover';
import LanguageSelectPopup from './components/LanguageSelectPopup/LanguageSelectPopup';

const LanguageSelect = ({popoverAlign, popoverArrowHeight, popoverClassName, ...props}) => {
    const isDesktopLayout = useIsDesktopLayout();

    return isDesktopLayout ? (
        <LanguageSelectPopover
            align={popoverAlign}
            arrowHeight={popoverArrowHeight}
            popoverClassName={popoverClassName}
            {...props}
        />
    ) : (
        <LanguageSelectPopup.Trigger {...props} />
    );
};

export default LanguageSelect;
