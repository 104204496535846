import cn from 'classnames';
import React from 'react';
import {useSelector} from 'react-redux';

import {getLocalizedStrings} from '../../services/localization/localizationService';
import {selectIsAem} from '../../state/selectors/global';
import {twx} from '../../utils/tailwindUtils';
import RemoteIcon from '../Icon/RemoteIcon';

const ContentList = ({list, className, itemProps}) => {
    const isAem = useSelector(selectIsAem);
    const localizedStrings = getLocalizedStrings();

    return (
        <ul className={cn('flex w-full flex-col gap-4', className)}>
            {list?.map(({titleKey, descriptionKey, imagePath}) => {
                const title = localizedStrings[titleKey];
                const description = localizedStrings[descriptionKey];

                return (
                    <ItemTitle
                        key={titleKey}
                        title={title}
                        description={description}
                        imagePath={imagePath}
                        isAem={isAem}
                        {...itemProps}
                    />
                );
            })}
        </ul>
    );
};

const ItemTitle = ({title, description, imagePath, isAem, className, titleClassName, descriptionClassName}) => {
    return (
        <li
            className={twx(
                {grid: !isAem},
                {'gap-x-4': description},
                'grid-cols-[48px_1fr] items-center gap-x-2 empty:hidden',
                className
            )}
        >
            {title && (
                <>
                    <div className='row-span-2 h-auto w-full shrink-0'>
                        <RemoteIcon imagePath={imagePath} />
                    </div>
                    <p
                        className={cn(
                            {'font-bold': description},
                            {'row-span-2': !description},
                            'content-center',
                            titleClassName
                        )}
                    >
                        {title}
                    </p>
                </>
            )}
            {description && <p className={descriptionClassName}>{description}</p>}
        </li>
    );
};

export default ContentList;
