import cn from 'classnames';
import React, {useState} from 'react';

import {ButtonRaw} from '@/components/Button';
import PromotedBadge from '@/components/PromotedBadge/PromotedBadge';
import GuideVideo from '@/components/Video/GuideVideo/GuideVideo';
import appConfig from '@/config/appConfig';
import useConfigExternalLink from '@/hooks/externalLink/useConfigExternalLink';
import amplitudeHomePageTracking from '@/services/amplitude/amplitudeHomePageTracking';
import rtlService from '@/services/app/rtlService';
import {twx} from '@/utils/tailwindUtils';

import SignedImage from '../../../../../System/SignedImage';

const HomePageForYouContainer = (props) => {
    const {
        onClick,
        className,
        videoClassName,
        badgeText,
        titleText,
        descriptionText,
        isDarkText,
        imagePath,
        videoPath,
        defaultImage,
        subTitleText,
        amplitudeTrackingValue,
        externalLink,
        linearGradient,
        isBigTitle,
        isSmallTitle,
        isTertiarySubtitle,
        badgeClassName,
    } = props;
    const {externalLinkCode, ssoTemplate} = externalLink || {};
    const isDisabled = !onClick && !externalLinkCode && !ssoTemplate;
    const imageCombinedPath = appConfig.getCombinedPathAssetPath(imagePath);
    const redirectToExternalLink = useConfigExternalLink(externalLink);
    const [isVideoError, setIsVideoError] = useState(false);

    const handleVideoError = () => {
        setIsVideoError(true);
    };

    const onContainerClick = () => {
        amplitudeHomePageTracking.trackForYouTileOpened(amplitudeTrackingValue);
        redirectToExternalLink();
    };

    return (
        <div dir={rtlService.getDir()}>
            <ButtonRaw
                disabled={isDisabled}
                onClick={onClick || onContainerClick}
                className={twx(
                    'relative flex aspect-[260/360] h-full w-[calc(100vw_-_133px)] flex-col overflow-hidden rounded-[12px] p-4 sm:min-h-[358px] sm:w-[312px] md:h-[300px] md:min-h-0 md:w-[370px] md:rounded-xl',
                    isDarkText ? 'bg-silver text-primary' : 'bg-primary text-white',
                    isDisabled ? 'cursor-default' : 'cursor-pointer',
                    className
                )}
            >
                {videoPath && !isVideoError ? (
                    <GuideVideo
                        src={videoPath}
                        containerClassName='absolute top-0 left-0 w-full h-full'
                        className={twx('h-full max-h-full w-full object-cover', videoClassName)}
                        isControlsVisible={false}
                        loop
                        autoplay
                        onError={handleVideoError}
                    />
                ) : (
                    <SignedImage
                        defaultImage={defaultImage}
                        mediaData={imageCombinedPath}
                        isThumbnailAvailable
                        signIfNull
                        key={imageCombinedPath}
                        imgClassName='absolute w-full h-full object-cover shrink-0 top-0 left-0'
                    />
                )}
                {linearGradient && (
                    <div className='absolute bottom-0 left-0 h-[50%] w-full bg-gradient-to-t from-black to-transparent' />
                )}
                <div className='relative flex w-full flex-col justify-start gap-2 text-start'>
                    <PromotedBadge badgeText={badgeText} className={badgeClassName} />
                    {subTitleText && (
                        <div
                            className={cn('whitespace-pre-line text-16 leading-15', {
                                'text-tertiary': isTertiarySubtitle,
                            })}
                            dangerouslySetInnerHTML={{__html: subTitleText}}
                        />
                    )}
                    <b
                        className={twx(
                            'line-clamp-2 whitespace-pre-line leading-13',
                            isSmallTitle ? 'text-20' : isBigTitle ? 'text-28' : 'text-24',
                            'lg:text-20'
                        )}
                        dangerouslySetInnerHTML={{__html: titleText}}
                    />
                    <div
                        className='whitespace-pre-line text-16 leading-15'
                        dangerouslySetInnerHTML={{__html: descriptionText}}
                    />
                </div>
            </ButtonRaw>
        </div>
    );
};

export default HomePageForYouContainer;
