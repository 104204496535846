import React from 'react';

import {useGetAutoDeliveryDetails} from '@/api/consumerApi';
import {validateAutoDeliveryOrderStatus} from '@/consts/icc/iccAutoDeliveryStatusTypes';
import {AUTO_DELIVERY_VIEW_ORDER_LINK} from '@/consts/localization/localizationKeys';
import AutoDeliveryActiveProgress from '@/views/AutoDelivery/AutoDeliveryActive/AutoDeliveryActiveProgress';
import AutoDeliveryActiveSubtitle from '@/views/AutoDelivery/AutoDeliveryActive/AutoDeliveryActiveSubtitle';
import AutoDeliveryActiveTitle from '@/views/AutoDelivery/AutoDeliveryActive/AutoDeliveryActiveTitle';

import AutoDeliveryWidgetContainer from './components/AutoDeliveryWidgetContainer';
import AutoDeliveryWidgetHeader from './components/AutoDeliveryWidgetHeader';

const AutoDeliveryStatusActive = ({className}) => {
    const {
        data: {
            autoDelivery: {minDeliveryDaysCount, maxDeliveryDaysCount, nextDeliveryDate, maxNextDeliveryDate, status},
            order: {status: orderStatus},
        },
    } = useGetAutoDeliveryDetails();

    const isOrderStatusValid = validateAutoDeliveryOrderStatus(orderStatus);

    if (!isOrderStatusValid) return;

    return (
        <AutoDeliveryWidgetContainer className={className}>
            <AutoDeliveryWidgetHeader btnLocalizationKey={AUTO_DELIVERY_VIEW_ORDER_LINK} />
            <AutoDeliveryWidgetContainer.ContentContainer>
                <AutoDeliveryActiveSubtitle
                    minDeliveryDaysCount={minDeliveryDaysCount}
                    maxDeliveryDaysCount={maxDeliveryDaysCount}
                />
                <AutoDeliveryActiveTitle
                    nextDeliveryDate={nextDeliveryDate}
                    maxNextDeliveryDate={maxNextDeliveryDate}
                    className='md:mt-2'
                />
                <AutoDeliveryActiveProgress status={status} orderStatus={orderStatus} className='mt-4 md:mt-8' />
            </AutoDeliveryWidgetContainer.ContentContainer>
        </AutoDeliveryWidgetContainer>
    );
};

export default AutoDeliveryStatusActive;
