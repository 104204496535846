import appConfig from '../../config/appConfig';
import * as iccMethods from '../../consts/icc/iccMethods';
import * as logMessageTypes from '../../consts/log/logMessageTypes';
import {setAssetIdList} from '../../state/slices/uamSlice';
import {dispatch} from '../../state/store';
import urlUtils from '../../utils/urlUtils';
import authDataService from '../auth/authDataService';
import authDataStoreService from '../auth/authDataStoreService';
import authService from '../auth/authService';
import {getMappedInitialLanguageCode} from '../localization/localizationService';
import log from '../logger/log';
import {authMapping, authUamMapping, loginTokenMapping} from '../mapping/iccMappings';
import productHelpers from '../product/productHelpers';
import server from '../server/server';
import storageService from '../storage/storageService';
import iccResponseErrorCheckService from './iccResponseErrorCheckService';

let isTokenRefreshInProgress = false;
const getIccApiUrl = (methodName) => urlUtils.join(appConfig.getIccApiUrl(), methodName);

const getHeaders = (isAnonymous) => {
    const languageCode = getMappedInitialLanguageCode()?.toUpperCase();
    const l = languageCode || (isAnonymous ? 'EN' : undefined);

    return {
        a: appConfig.getIccApiHeader_a(),
        Accept: 'application/json',
        Authorization: appConfig.getIccApiHeaderAuthorization(),
        'Content-Type': 'application/x-www-form-urlencoded',
        l,
        m: appConfig.getIccMarketName()?.toUpperCase(),
        v: appConfig.getAppVersion(),
    };
};

const getBody = (isAnonymous) => {
    const {username, password} = authDataStoreService.getCredentials();

    return {
        scope: 'offline_access profile pwa-user-api',
        grant_type: 'password',
        password: isAnonymous ? appConfig.getIccAnonymousToken() : password,
        username: isAnonymous ? 'anonymous' : username,
    };
};

const onIccApiMethodResponse = (response, isAnonymous) => {
    const responseData = authMapping(response.data);

    authDataStoreService.setIccTokenData({...responseData, isAnonymous});

    return responseData;
};

const fetchIccToken = async (isAnonymous) => {
    const data = urlUtils.stringify(getBody(isAnonymous));
    const response = await getToken(data, getHeaders(isAnonymous));

    onIccApiMethodResponse(response, isAnonymous);
};

const refreshIccTokenIfAllowed = async () => {
    if (!isTokenRefreshInProgress) {
        isTokenRefreshInProgress = true;

        return refreshIccToken();
    }
};

const refreshIccToken = async () => {
    const isIccRefreshTokenEnabled = appConfig.getIsIccRefreshTokenEnabled();

    if (!isIccRefreshTokenEnabled) {
        log.info(`iccService: refreshIccToken, token refresh is disabled`, {logMessageType: logMessageTypes.ICC});

        authService.logout();
        return;
    }

    const refreshToken = authDataStoreService.getRefreshToken();
    const {username} = authDataStoreService.getCredentials();

    if (username && refreshToken) {
        const data = urlUtils.stringify({
            ...getBody(),
            grant_type: 'refresh_token',
            refresh_token: refreshToken,
        });

        try {
            const response = await getToken(data, getHeaders());
            const responseMapped = onIccApiMethodResponse(response);

            log.debug(
                `iccService: refreshIccToken, request: "${iccMethods.GET_TOKEN}" has successful response`,
                logMessageTypes.ICC
            );

            isTokenRefreshInProgress = false;
            return responseMapped;
        } catch (e) {
            isTokenRefreshInProgress = false;

            const errorStatus = e?.response?.status;

            storageService.removeAuthDataFromStorage();

            log.info(
                `iccService: refreshIccToken, request: "${iccMethods.GET_TOKEN}", error: ${e}`,
                logMessageTypes.ICC
            );

            if (errorStatus) {
                log.info(`iccService: refreshIccToken, BadRequest. Redirect to Login page`, {
                    logMessageType: logMessageTypes.ICC,
                });

                authService.logout();
            }

            throw e;
        }
    } else {
        isTokenRefreshInProgress = false;
        log.info(
            `iccService: refreshIccToken, required auth data is empty, username: ${username}, refreshToken: ${refreshToken}`,
            logMessageTypes.ICC
        );
        authService.logout();
        throw new Error('Required auth data is empty');
    }
};

const TRACKING = 'TRACKING';

const fetchUamToken = async ({isRetry, codentify} = {}) => {
    if (!productHelpers.isOriginalCodentifyValid(codentify)) {
        log.info(`iccService: fetchUamToken, codentify is not valid. codentify: ${codentify}`, logMessageTypes.ICC);
        return false;
    }

    const appVersion = appConfig.getAppVersion();
    const iccMethod = iccMethods.GET_UAM_TOKEN_V5;
    const message = `iccService: fetchUamToken, request: "${iccMethod}" for ${codentify}`;

    try {
        const authToken = await authDataService.getAuthToken();
        const requestConfig = {
            headers: {Authorization: 'Bearer ' + authToken, v: appVersion},
            data: {
                assets: [
                    {
                        id: codentify,
                        idtype: TRACKING,
                    },
                ],
            },
        };
        const response = await server.post(getIccApiUrl(iccMethod), {...requestConfig});
        const responseData = authUamMapping(response.data, codentify);

        dispatch(setAssetIdList({...responseData, codentify}));

        log.info(`${message} has successful response`, {logMessageType: logMessageTypes.ICC});
        return responseData;
    } catch (e) {
        log.error(`${message}, error: ${e}`, {logMessageType: logMessageTypes.ICC});
        if (isRetry) return false;

        await iccResponseErrorCheckService({status: e?.response?.status, error: e});
        return await fetchUamToken({isRetry: true, codentify});
    }
};

const fetchSSOLink = async (deeplinkId) => {
    const appVersion = appConfig.getAppVersion();
    const authToken = await authDataService.getAuthToken();
    const headers = {Authorization: 'Bearer ' + authToken, v: appVersion};

    const data = {
        AutoLoginLinkName: deeplinkId,
    };

    try {
        const response = await getLoginToken(data, headers);
        const result = loginTokenMapping(response.data);

        log.debug(
            `iccService: fetchSSOLink, request: "${iccMethods.GET_CONSUMER_LOGIN_TOKEN}" has successful response`,
            logMessageTypes.ICC
        );

        return result;
    } catch (e) {
        const errorStatus = e?.response?.status;

        log.error(
            `iccService: fetchSSOLink, request: "${iccMethods.GET_CONSUMER_LOGIN_TOKEN}", error: ${e}`,
            logMessageTypes.ICC
        );
        await iccResponseErrorCheckService({status: e?.response?.status, error: e, skipError: true});

        return {errorStatus};
    }
};

const getToken = async (data, headers) => {
    try {
        const result = await server.post(getIccApiUrl(iccMethods.GET_TOKEN), {data, headers});

        return result;
    } catch (e) {
        await iccResponseErrorCheckService({status: e?.response?.status, error: e});

        throw e;
    }
};

const getLoginToken = (data, headers) =>
    server.post(getIccApiUrl(iccMethods.GET_CONSUMER_LOGIN_TOKEN), {data, headers});

export default {
    refreshIccTokenIfAllowed,
    fetchIccToken,
    refreshIccToken,
    fetchSSOLink,
    fetchUamToken,
};
