import React, {useEffect, useState} from 'react';

import AemEditableText from '../../../../../../components/Aem/AemEditableText/AemEditableText';
import {StickyPageLayout} from '../../../../../../components/Layout';
import {PREFERENCE_CENTER_PAGE_HEADER_TITLE} from '../../../../../../consts/localization/localizationKeys';
import {WEB_PREFERENCE_CENTER} from '../../../../../../consts/tnc/tncTypes';
import useDocumentTitle from '../../../../../../hooks/useDocumentTitle';
import useIsMobileLayout from '../../../../../../hooks/useIsMobileLayout';
import useTermsOfUsePopup from '../../../../../../hooks/useTermsOfUsePopup';
import amplitudeProfileTracking from '../../../../../../services/amplitude/amplitudeProfileTracking';
import {getLocalizedStrings} from '../../../../../../services/localization/localizationService';
import TermsOfUseDescription from '../../../../../../views/Tnc/TermsOfUseDescription/TermsOfUseDescription';
import PageWarningBannersContainer from '../../../../../WarningBanners/PageWarningBannersContainer';
import DCExpansionPanel from './components/DCExpansionPanel';
import SaveContinueButton from './components/SaveContinueButton';

const PreferenceCenter = ({description, acceptedLegals, legals, onApproved, isEnableAllAvailable, onBack}) => {
    const isMobileLayout = useIsMobileLayout();
    const legalsNotNull = legals.filter((x) => !!x.settings);
    const acceptedLegalsNotNull = acceptedLegals.filter((x) => !!x.settings);
    const legalsSettingsNotNull = [...legalsNotNull, ...acceptedLegalsNotNull].sort((a, b) => a.position - b.position);
    const legalsSettingsNotNullCodes = legalsSettingsNotNull.map(({code}) => code);
    const filteredAcceptedLegals = acceptedLegalsNotNull.filter((x) => !x.isRevoked).map(({code}) => code);
    const [acceptedSettingList, setAcceptedSettingList] = useState(filteredAcceptedLegals);
    const {openPopup} = useTermsOfUsePopup({
        overlayClassName: 'md:left-[var(--tnc-sidebar-width)] md:w-[calc(100%-var(--tnc-sidebar-width))]',
    });
    const localizedStrings = getLocalizedStrings();

    useDocumentTitle(localizedStrings[PREFERENCE_CENTER_PAGE_HEADER_TITLE]);

    useEffect(() => {
        amplitudeProfileTracking.trackPreferenceCenterScreen();
    }, []);

    useEffect(() => {
        setAcceptedSettingList(filteredAcceptedLegals);
    }, [acceptedLegals]);

    const onSettingLegalChange = (code, isAccepted) => {
        if (isAccepted) {
            setAcceptedSettingList([...acceptedSettingList, code]);
        } else {
            const isCodeInList = acceptedSettingList.indexOf(code) !== -1;

            if (isCodeInList) {
                const filteredList = acceptedSettingList.filter((el) => el !== code);

                setAcceptedSettingList(filteredList);
            }
        }
    };

    return (
        <>
            <StickyPageLayout
                className='items-center'
                desktopClassName='p-16 pb-4'
                headerOptions={{
                    onBack,
                    title: localizedStrings[PREFERENCE_CENTER_PAGE_HEADER_TITLE],
                }}
                contentClassName='flex flex-1 flex-col items-center'
                footer={
                    <div className='flex flex-col gap-6 lg:mt-8 lg:flex-row-reverse'>
                        <SaveContinueButton
                            legals={legals}
                            onApproved={onApproved}
                            legalsSettingsNotNull={legalsSettingsNotNull}
                            acceptedSettingList={
                                isEnableAllAvailable ? legalsSettingsNotNullCodes : acceptedSettingList
                            }
                            itemName={isEnableAllAvailable ? 'preferenceCenterEnableAll' : 'preferenceCenterSave'}
                        />
                        {isEnableAllAvailable && (
                            <SaveContinueButton
                                isSecondary
                                legals={legals}
                                onApproved={onApproved}
                                legalsSettingsNotNull={legalsSettingsNotNull}
                                acceptedSettingList={acceptedSettingList}
                                itemName='preferenceCenterContinue'
                            />
                        )}
                    </div>
                }
            >
                <h1 className='mb-3 px-4 mt-safe-offset-14 md:mb-4 md:mt-0 md:px-0 md:text-start md:text-32'>
                    <AemEditableText pageName='tnc' itemName='preferenceCenterTitle' />
                </h1>
                <TermsOfUseDescription
                    className='mb-4 px-4 md:px-0 md:text-start'
                    innerHtml={description}
                    tncType={WEB_PREFERENCE_CENTER}
                    onTncLinkClick={openPopup}
                />
                {legalsSettingsNotNull.map(({code, title, text}) => {
                    return (
                        <div className='w-full px-4' key={code}>
                            <DCExpansionPanel
                                index={code}
                                title={title}
                                innerText={text}
                                onChange={(value) => onSettingLegalChange(code, value)}
                                isActive={acceptedSettingList.includes(code)}
                                onTncLinkClick={openPopup}
                            />
                        </div>
                    );
                })}
                {isMobileLayout && <PageWarningBannersContainer />}
            </StickyPageLayout>
            {!isMobileLayout && <PageWarningBannersContainer />}
        </>
    );
};

export default PreferenceCenter;
