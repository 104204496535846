import React from 'react';

import Icon from '../Icon/Icon';

const LoyaltyIcon = (props) => {
    return (
        <Icon width='64' height='65' fill='none' {...props}>
            <g>
                <g>
                    <path
                        d='M45.0863 38.9237L51.3986 49.8569L44.1967 48.9L41.4245 55.6154L34.0652 43.1118'
                        stroke='currentColor'
                        strokeWidth='2.5'
                        strokeLinejoin='round'
                    />
                    <path
                        d='M19.1754 38.9238L12.8632 49.8569L20.0651 48.9L22.8373 55.6155L30.4583 43.3736'
                        stroke='currentColor'
                        strokeWidth='2.5'
                        strokeLinejoin='round'
                    />
                </g>
                <g>
                    <circle cx='31.9999' cy='26.8226' r='17.0726' stroke='currentColor' strokeWidth='2.5' />
                    <circle cx='32.1308' cy='26.695' r='12.695' fill='currentColor' />
                    <g>
                        <g>
                            <path
                                d='M28.3354 29.5708H35.6645'
                                stroke='#34303D'
                                strokeWidth='2.5'
                                strokeLinecap='round'
                            />
                            <path d='M24.6709 24.336H39.329' stroke='#34303D' strokeWidth='2.5' strokeLinecap='round' />
                        </g>
                    </g>
                </g>
            </g>
        </Icon>
    );
};

export default LoyaltyIcon;
