import React from 'react';

import AemEditableText from '../../../../components/Aem/AemEditableText/AemEditableText';
import {twx} from '../../../../utils/tailwindUtils';
import AutoDeliveryEditOrderButton from './AutoDeliveryEditOrderButton';

const AutoDeliveryOrderHeader = ({titleClassName, isEditAllowed}) => {
    return (
        <div className='flex items-center justify-between gap-2'>
            <h3
                className={twx(
                    'mb-0 overflow-hidden text-ellipsis whitespace-nowrap text-24 font-bold md:text-36 md:text-black',
                    titleClassName
                )}
            >
                <AemEditableText pageName='auto-delivery' itemName='yourOrderTitle' />
            </h3>
            {isEditAllowed && <AutoDeliveryEditOrderButton />}
        </div>
    );
};

export default AutoDeliveryOrderHeader;
