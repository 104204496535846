import React, {useEffect, useId} from 'react';
import {useDispatch} from 'react-redux';

import {clearHwContainerId, setHwContainerId} from '@/state/slices/globalSlice';
import {twx} from '@/utils/tailwindUtils';

const PageWarningBannersContainer = ({className}) => {
    const id = useId();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setHwContainerId(id));

        return () => {
            dispatch(clearHwContainerId(id));
        };
    }, []);

    return <div className={twx('mt-auto flex w-full flex-col', className)} id={id} />;
};

export default PageWarningBannersContainer;
