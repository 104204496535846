import React from 'react';

import {twx} from '@/utils/tailwindUtils';

import appConfig from '../../config/appConfig';
import PageHeaderMedia from '../PageHeaderMedia/PageHeaderMedia';

const Banner = ({
    className,
    onClick,
    imagePath,
    videoPath,
    isGradient,
    isDarkBannerText,
    isVideoAvailable,
    children,
    gradient,
    ...rest
}) => {
    const imageSrc = appConfig.getCombinedPathAssetPath(imagePath);

    return (
        <article className={twx('relative', className, {'text-primary': isDarkBannerText})} onClick={onClick}>
            <PageHeaderMedia
                isVideoAvailable={isVideoAvailable ?? !!videoPath}
                className='absolute h-full'
                videoSrc={videoPath}
                imageSrc={imageSrc}
                {...rest}
            />
            {isGradient && <div className='translate-z-1 absolute h-full w-full' style={{background: gradient}} />}
            {children}
        </article>
    );
};

export default Banner;
