import CloseButton from '../../../../components/CloseButton/CloseButton';
import {StickyPageLayout} from '../../../../components/Layout';
import PageWarningBannersContainer from '../../../WarningBanners/PageWarningBannersContainer';
import AutoDeliveryDetailsFooter from '../components/AutoDeliveryDetailsFooter';
import AutoDeliveryOrder from '../components/AutoDeliveryOrder';
import AutoDeliveryOrderAddress from '../components/AutoDeliveryOrderAddress';
import AutoDeliveryOrderHeader from '../components/AutoDeliveryOrderHeader';
import AutoDeliveryOrderSummary from '../components/AutoDeliveryOrderSummary';
import ConfirmAsapButton from './ConfirmAsapButton';
import ConfirmSkipButton from './ConfirmSkipButton';
import DeliveryAsapSection from './DeliveryAsapSection';
import DeliverySkipSection from './DeliverySkipSection';

const EditDeliveryMobile = ({isAsap, isSkip, data, onClose, isShowSkipButton, title}) => {
    const {
        order: {price, deliveryAddress, products},
    } = data;

    return (
        <StickyPageLayout
            contentClassName='flex-1 text-start'
            headerOptions={{
                endIcon: <CloseButton onClick={onClose} />,
                title,
            }}
            footer={
                <AutoDeliveryDetailsFooter>
                    {isAsap && <ConfirmAsapButton closePopup={onClose} />}
                    {isShowSkipButton && <ConfirmSkipButton closePopup={onClose} />}
                </AutoDeliveryDetailsFooter>
            }
            noScrollFooterClassName='border-t border-mischka pt-4'
            footerClassName='bg-romance border-t-[1px] border-french-gray'
        >
            <div className='p-4 pt-safe-offset-14'>
                {isAsap && <DeliveryAsapSection />}
                {isSkip && <DeliverySkipSection />}
                <AutoDeliveryOrder className='border-t border-mischka px-0' products={products}>
                    <AutoDeliveryOrderHeader isEditAllowed />
                </AutoDeliveryOrder>
                <AutoDeliveryOrderSummary className='border-t border-mischka px-0' price={price} />
                <AutoDeliveryOrderAddress
                    className='border-t border-mischka px-0'
                    address={deliveryAddress}
                    isEditAllowed
                />
            </div>
            <PageWarningBannersContainer />
        </StickyPageLayout>
    );
};

export default EditDeliveryMobile;
