import {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';

import {useGetAutoDeliveryDetailsExtended} from '@/api/consumerApi';
import appRouterService from '@/services/route/appRouterService';
import {hideLoader, showLoader} from '@/state/slices/loadersSlice';

const LOADER_NAME = 'AD_DETAILS_EXTENDED_LOADER_NAME';

const useRedirectToAutoDeliveryDetails = ({disabled} = {}) => {
    const dispatch = useDispatch();
    const [isSkipped, setIsSkipped] = useState(true);
    const {data, isFetching} = useGetAutoDeliveryDetailsExtended({skip: isSkipped});

    const fetchData = () => {
        if (disabled) return;

        setIsSkipped(false);
    };

    useEffect(() => {
        if (disabled) return;

        if (isFetching) {
            dispatch(showLoader(LOADER_NAME));
        } else {
            dispatch(hideLoader(LOADER_NAME));
        }
    }, [isFetching]);

    useEffect(() => {
        if (disabled) return;
        if (!data) return;

        appRouterService.forwardToAutoDeliveryPage();
    }, [data]);

    return fetchData;
};

export default useRedirectToAutoDeliveryDetails;
