import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {selectInboxMessageId} from '../../state/selectors/inbox';
import {deleteInboxMessageId} from '../../state/slices/inboxSlice';

const useOpenInboxDetails = ({setMessageId, setIsOpen}) => {
    const messageId = useSelector(selectInboxMessageId);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!messageId) return;

        setMessageId(messageId);
        setIsOpen(true);
        dispatch(deleteInboxMessageId());
    }, [messageId]);
};

export default useOpenInboxDetails;
