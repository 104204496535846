import {useState} from 'react';

import {updateAutoDeliveryDetails} from '../../../../api/consumerApi';
import AemEditableText from '../../../../components/Aem/AemEditableText/AemEditableText';
import {AppButton} from '../../../../components/Button';
import {ErrorBigIcon, PlusOneDayIcon} from '../../../../components/Icons';
import AppLoader from '../../../../components/Loader/AppLoader/AppLoader';
import amplitudeAutoDeliveryTracking from '../../../../services/amplitude/amplitudeAutoDeliveryTracking';
import iccAutoDeliveryService from '../../../../services/icc/iccAutoDeliveryService';
import routerService from '../../../../services/route/routerService';
import helpers from '../../../../utils/helpers';
import stringUtils from '../../../../utils/stringUtils';
import useDeliveryDate from '../hooks/useDeliveryDate';
import useOrderSummaryTracking from '../hooks/useOrderSummaryTracking';
import EditDeliveryResultPopup from './EditDeliveryResultPopup';

const ConfirmSkipButton = ({closePopup}) => {
    const {startDeliveryDate} = useDeliveryDate();
    const [isLoading, setIsLoading] = useState(false);
    const [isSuccessPopupVisible, setIsSuccessPopupVisible] = useState(false);
    const [isErrorPopupVisible, setIsErrorPopupVisible] = useState(false);
    const orderSummaryTrackingValue = useOrderSummaryTracking();

    const onConfirmSubmit = () => {
        amplitudeAutoDeliveryTracking.trackAutoDeliverySkipDeliveryStarted(orderSummaryTrackingValue);
        setIsLoading(true);
        iccAutoDeliveryService.skipAutoDelivery({
            onSuccess: (responseData) => {
                setIsLoading(false);
                setIsSuccessPopupVisible(true);
                updateAutoDeliveryDetails(responseData);
                amplitudeAutoDeliveryTracking.trackAutoDeliverySkipDeliveryCompleted({
                    success: true,
                    orderSummary: orderSummaryTrackingValue,
                });
            },
            onError: (errorMessage) => {
                setIsLoading(false);
                setIsErrorPopupVisible(true);
                amplitudeAutoDeliveryTracking.trackAutoDeliverySkipDeliveryCompleted({
                    success: false,
                    message: errorMessage,
                    orderSummary: orderSummaryTrackingValue,
                });
            },
        });
    };
    const onResultPopupClose = () => {
        setIsErrorPopupVisible(false);
        setIsSuccessPopupVisible(false);
        helpers.runFunction(closePopup);
    };
    const onBackButtonClick = () => {
        routerService.go(-2);
    };

    return (
        <>
            <AppButton primary light className='mt-0' onClick={onConfirmSubmit}>
                <AemEditableText pageName='auto-delivery' itemName='skipDeliveryConfirmButton' />
            </AppButton>
            {isLoading && <AppLoader />}
            {isSuccessPopupVisible && (
                <EditDeliveryResultPopup
                    icon={<PlusOneDayIcon />}
                    title={<AemEditableText pageName='auto-delivery' itemName='skipDeliveryPopupSuccessTitle' />}
                    description={
                        <AemEditableText
                            pageName='auto-delivery'
                            itemName='skipDeliveryPopupSuccessDescription'
                            render={(text) => {
                                const textFormatted = stringUtils.formatString(text, startDeliveryDate);

                                return textFormatted ? (
                                    <span dangerouslySetInnerHTML={{__html: textFormatted}} />
                                ) : null;
                            }}
                        />
                    }
                    button={<AemEditableText pageName='auto-delivery' itemName='skipDeliveryPopupSuccessButton' />}
                    onButtonClick={onBackButtonClick}
                    onClose={onResultPopupClose}
                />
            )}
            {isErrorPopupVisible && (
                <EditDeliveryResultPopup
                    icon={<ErrorBigIcon />}
                    title={<AemEditableText pageName='auto-delivery' itemName='skipDeliveryPopupErrorTitle' />}
                    description={
                        <AemEditableText pageName='auto-delivery' itemName='skipDeliveryPopupErrorDescription' />
                    }
                    button={<AemEditableText pageName='auto-delivery' itemName='skipDeliveryPopupErrorButton' />}
                    onButtonClick={onBackButtonClick}
                    onClose={onResultPopupClose}
                />
            )}
        </>
    );
};

export default ConfirmSkipButton;
