import React, {useEffect} from 'react';

import BackButton from '../../../components/BackButton/BackButton';
import {ButtonRaw} from '../../../components/Button';
import {TrashIcon} from '../../../components/Icons';
import AppLoaderContent from '../../../components/Loader/AppLoader/AppLoaderContent';
import * as sourceTypes from '../../../consts/sourceTypes';
import useNotificationDetails from '../../../hooks/inbox/useNotificationDetails';
import amplitudeInboxTracking from '../../../services/amplitude/amplitudeInboxTracking';
import inboxMessageService from '../../Inbox/InboxView/inboxMessageService';
import NotificationDetailsMessage from '../../Inbox/NotificationDetails/components/NotificationDetailsMessage';
import InboxPopoverContent from './InboxPopoverContent';

const InboxPopoverNotificationDetails = ({messageId, closeNotificationDetails}) => {
    const {detailedMessage, isLoaderActive} = useNotificationDetails({
        messageId,
        onErrorHandler: closeNotificationDetails,
    });

    useEffect(() => {
        if (detailedMessage) {
            amplitudeInboxTracking.trackNotificationDetailedScreen(sourceTypes.INBOX, detailedMessage.campaignName);
        }
    }, [detailedMessage]);

    const onDeleteMessage = () => {
        inboxMessageService.deleteInboxMessage(detailedMessage.messageId);
        closeNotificationDetails();
    };

    return (
        <InboxPopoverContent className='h-[556px]'>
            <InboxPopoverContent.Title className='flex items-center justify-between'>
                <BackButton onClick={closeNotificationDetails} />
                <ButtonRaw onClick={onDeleteMessage}>
                    <TrashIcon />
                </ButtonRaw>
            </InboxPopoverContent.Title>
            <InboxPopoverContent.Content>
                {isLoaderActive ? (
                    <div className='flex h-full items-center justify-center'>
                        <AppLoaderContent />
                    </div>
                ) : (
                    <div className='flex flex-col pt-2 text-start'>
                        <NotificationDetailsMessage {...detailedMessage} />
                    </div>
                )}
            </InboxPopoverContent.Content>
        </InboxPopoverContent>
    );
};

export default InboxPopoverNotificationDetails;
