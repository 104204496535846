import React from 'react';
import {useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';

import appConfig from '@/config/appConfig';
import ROUTE_PARAMS from '@/consts/route/routeParams';
import MODULE_SRC from '@/consts/uwm/uwmModuleSrc';
import useConfigExternalLink from '@/hooks/externalLink/useConfigExternalLink';
import appRouterService from '@/services/route/appRouterService';
import routerService from '@/services/route/routerService';
import uwmService from '@/services/uwmService';
import {selectDeviceType} from '@/state/selectors/device';
import UwmModule from '@/views/UwmModule/UwmModule';

const TipsAndTutorials = (props) => {
    const {isQuickStartGuide, isCleaningGuide, isAdvancedDiagnostic} = props;
    const {[ROUTE_PARAMS.DEVICE_TYPE]: deviceTypeFromQuery} = useParams();
    const deviceTypeFromState = useSelector(selectDeviceType);
    const deviceType = deviceTypeFromQuery || deviceTypeFromState;

    const supportExternalLink = appConfig.getSupportExternalLink();
    const redirectToSupport = useConfigExternalLink(supportExternalLink);

    const onModuleLoaded = () => {
        uwmService.postTipsAndTutorialsInitMessage({isQuickStartGuide, isCleaningGuide, deviceType});
        uwmService.postMarketLocaleInitMessage();
    };

    const onBackButtonClick = () => {
        routerService.goBack();
    };

    const onCompleteCleaningGuide = () => {
        if (isAdvancedDiagnostic) {
            appRouterService.forwardToPostCleaningAD();
        } else {
            appRouterService.forwardToHomePage();
        }
    };

    return (
        <UwmModule
            onBackButtonClick={onBackButtonClick}
            onModuleLoaded={onModuleLoaded}
            onSupportButtonClick={redirectToSupport}
            src={MODULE_SRC.TIPS_AND_TUTORIALS}
            onCompleteCleaningGuide={onCompleteCleaningGuide}
        />
    );
};

export default TipsAndTutorials;
