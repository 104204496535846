import React from 'react';

import {BellIcon} from '../../../../components/Icons';

const InboxEmpty = ({emptyInboxText}) => {
    return (
        <div className='flex flex-col items-center gap-4 py-36 lg:h-full lg:justify-center lg:py-0'>
            <BellIcon />
            <h4 className='mb-0 text-center lg:text-20'>{emptyInboxText}</h4>
        </div>
    );
};

export default InboxEmpty;
