import cn from 'classnames';
import React, {Fragment} from 'react';
import {useSelector} from 'react-redux';

import {selectNotificationsBottom, selectNotificationsTop} from '../../state/selectors/notifications';
import NotificationBottom from './Notification/NotificationBottom';
import NotificationTop from './Notification/NotificationTop';

const Notifications = () => {
    const notificationsBottom = useSelector(selectNotificationsBottom);
    const notificationsTop = useSelector(selectNotificationsTop);

    return (
        <>
            <NotificationsContainer notifications={notificationsTop} />
            <NotificationsContainer notifications={notificationsBottom} isBottom />
        </>
    );
};

const NotificationsContainer = ({notifications, isBottom}) => {
    if (!notifications?.length) return null;

    return (
        <div
            className={cn(
                'fixed z-[110] w-full md:right-8 md:max-w-[360px]',
                {'bottom-0': isBottom},
                {'top-0': !isBottom}
            )}
        >
            <div
                className={cn(
                    'sticky flex justify-center px-4 md:px-0',
                    {'pb-safe-offset-18': isBottom},
                    {'pt-safe-offset-4 md:pt-safe-offset-8': !isBottom}
                )}
            >
                <div className='flex w-full flex-col items-center gap-3'>
                    {notifications.map((notification) => (
                        <Fragment key={notification.notificationId}>
                            {!isBottom && <NotificationTop notification={notification} />}
                            {isBottom && <NotificationBottom notification={notification} />}
                        </Fragment>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Notifications;
