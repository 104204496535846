import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';

import {useGetInboxStatus} from '../../api/iccNotificationApi';
import {ICC_INBOX_MESSAGE_STATUS_TYPES} from '../../consts/icc/iccInboxMessageStatusTypes';
import inboxMessageService from '../../containers/Inbox/InboxView/inboxMessageService';
import pushNotificationService from '../../services/pushNotificationService';
import {makeSelectInboxMessage, makeSelectNextInboxMessage} from '../../state/selectors/inbox';
import useInboxMessages from './useInboxMessages';

const useNotificationDetails = ({messageId, onErrorHandler}) => {
    const currentMessage = useSelector(makeSelectInboxMessage(messageId));
    const nextMessage = useSelector(makeSelectNextInboxMessage(messageId));
    const {refetch: refetchInboxStatus} = useGetInboxStatus();

    const [detailedMessage, setDetailedMessage] = useState(null);
    const [isLoaderActive, setIsLoaderActive] = useState(false);

    const fetchMessageData = async () => {
        setIsLoaderActive(true);
        try {
            const messageData = await inboxMessageService.fetchDetailedInboxMessage(messageId);

            setDetailedMessage(messageData);
        } catch (e) {
            onErrorHandler();
        }
        setIsLoaderActive(false);
    };

    useEffect(() => {
        fetchMessageData();
    }, [messageId]);

    useEffect(() => {
        const run = async () => {
            if (currentMessage?.status === ICC_INBOX_MESSAGE_STATUS_TYPES.UNREAD) {
                pushNotificationService.removeNotification(messageId);
                await inboxMessageService.readInboxMessage(messageId);
                refetchInboxStatus();
            }
        };

        run();
    }, [currentMessage]);

    const {inboxMessages, loadMore, isLoading, hasNextPage} = useInboxMessages();

    useEffect(() => {
        if (nextMessage) return;
        if (isLoading) return;

        if (hasNextPage || inboxMessages === undefined) {
            loadMore();
        }
    }, [hasNextPage, nextMessage, isLoading]);

    return {detailedMessage, nextMessage, isLoaderActive};
};

export default useNotificationDetails;
