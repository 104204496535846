import React, {useState} from 'react';

import {Popover, PopoverAnchor, PopoverArrow, PopoverContent} from '@/components/Popover/Popover';

import useLanguageSelect from '../../hooks/useLanguageSelect';
import LanguageSelectTrigger from '../LanguageSelectTrigger';
import LanguageSelectPopoverContent from './LanguageSelectPopoverContent';

const LanguageSelectPopover = ({isDark, align, arrowHeight, popoverClassName}) => {
    const [isOpen, setIsOpen] = useState(false);
    const onOpenChange = (value) => setIsOpen(value);
    const {languageCode, languageName, selectListData, handleLanguageChange} = useLanguageSelect();

    const handleLanguageChangeAndClose = async (code) => {
        await handleLanguageChange(code);
        onOpenChange(false);
    };

    return (
        <div className='self-end'>
            <Popover open={isOpen} onOpenChange={onOpenChange}>
                <PopoverAnchor>
                    <LanguageSelectTrigger
                        languageName={languageName}
                        isDark={isDark}
                        onClick={() => onOpenChange(true)}
                    />
                </PopoverAnchor>
                <PopoverContent align={align} className={popoverClassName}>
                    <LanguageSelectPopoverContent
                        selectedLanguageCode={languageCode}
                        selectListData={selectListData}
                        onLanguageChange={handleLanguageChangeAndClose}
                    />
                    <PopoverArrow width={34} height={arrowHeight} className='-mr-8' />
                </PopoverContent>
            </Popover>
        </div>
    );
};

export default LanguageSelectPopover;
