import cn from 'classnames';

import Banner from '@/components/Banner/Banner';
import {AppButton} from '@/components/Button';
import useConfigExternalLink from '@/hooks/externalLink/useConfigExternalLink';
import {getLocalizedStrings} from '@/services/localization/localizationService';
import {twx} from '@/utils/tailwindUtils';

const TYPES = {
    TEXT_BOTTOM: 'textBottom',
    TEXT_TOP: 'textTop',
};

const HighlightItem = (props) => {
    const {
        firstButtonAction,
        firstButtonActionKey,
        banner,
        secondButtonAction,
        secondButtonActionKey,
        type,
        loop,
        isFullscreen,
        onClick,
        onEnded,
        onVideoError,
        isActiveSlide,
        isFullscreenGradient,
        onLoadedMetadata,
    } = props;
    const {titleKey, subTitleKey, descriptionKey, imagePath, videoPath, isGradient} = banner || {};
    const localizedStrings = getLocalizedStrings();
    const ContentComponent = type === TYPES.TEXT_TOP && isFullscreen ? ContentTextTop : ContentTextBottom;
    const isVideoAvailable = videoPath && (!imagePath || isFullscreen);

    return (
        <Banner
            className={cn(
                'h-full bg-primary text-white',
                isFullscreen ? 'h-screen' : 'h-[540px] overflow-hidden rounded-xl'
            )}
            onClick={onClick}
            isControlsVisible={false}
            loop={loop}
            onEnded={onEnded}
            isVideoAvailable={isVideoAvailable}
            autoplay
            isVideoPlay={isActiveSlide}
            isVideoPaused={!isActiveSlide}
            videoPath={videoPath}
            imagePath={imagePath}
            onLoadedMetadata={onLoadedMetadata}
            onVideoError={onVideoError}
            isGradient={isFullscreen ? isFullscreenGradient : isGradient}
            gradient={
                isFullscreen
                    ? 'linear-gradient(0deg, rgba(0, 0, 0, 0.40) 0%, rgba(0, 0, 0, 0.40) 100%)'
                    : 'linear-gradient(180deg, rgba(52, 48, 61, 0.00) 42.18%, #1C1A21 82.12%)'
            }
        >
            <ContentComponent
                isFullscreen={isFullscreen}
                subTitle={localizedStrings[subTitleKey]}
                title={localizedStrings[titleKey]}
                description={localizedStrings[descriptionKey]}
                firstButtonAction={firstButtonAction}
                firstButtonText={localizedStrings[firstButtonActionKey]}
                secondButtonAction={secondButtonAction}
                secondButtonText={localizedStrings[secondButtonActionKey]}
            />
        </Banner>
    );
};

const ContentTextTop = ({
    isFullscreen,
    title,
    subTitle,
    description,
    firstButtonAction,
    firstButtonText,
    secondButtonAction,
    secondButtonText,
}) => {
    return (
        <ContentContainer isFullscreen={isFullscreen} className='gap-4'>
            <h3 className={twx('text-14 leading-[10px]', {'text-24 leading-[18px]': isFullscreen})}>{subTitle}</h3>
            <h2 className={twx('mb-0 mt-10 text-36', {'text-56 leading-[64px]': isFullscreen})}>{title}</h2>
            <p className='flex-1'>{description}</p>
            <Buttons
                firstButtonAction={firstButtonAction}
                firstButtonText={firstButtonText}
                secondButtonAction={secondButtonAction}
                secondButtonText={secondButtonText}
            />
        </ContentContainer>
    );
};

const ContentTextBottom = ({isFullscreen, title, subTitle, description}) => {
    return (
        <ContentContainer isFullscreen={isFullscreen}>
            <h3
                className={twx('flex-1 text-14 leading-[10px]', {
                    'text-24 leading-[18px]': isFullscreen,
                })}
            >
                {subTitle}
            </h3>
            <h2
                className={twx('mb-0 text-24', {
                    'text-36': isFullscreen,
                    'line-clamp-2': !isFullscreen,
                })}
            >
                {title}
            </h2>
            <p
                className={cn({
                    'line-clamp-3': !isFullscreen,
                })}
            >
                {description}
            </p>
        </ContentContainer>
    );
};

const ContentContainer = ({isFullscreen, className, children}) => {
    return (
        <div
            className={twx(
                'translate-z-1 relative z-[1] flex h-full flex-col px-4 text-start',
                {'py-4': !isFullscreen},
                {'pt-safe-offset-5 pb-safe-or-8': isFullscreen},
                className
            )}
        >
            {children}
        </div>
    );
};

const Buttons = ({firstButtonAction, firstButtonText, secondButtonAction, secondButtonText}) => {
    return (
        <>
            {firstButtonText && firstButtonAction && (
                <Button primary dark text={firstButtonText} action={firstButtonAction} />
            )}
            {secondButtonText && secondButtonAction && (
                <Button secondary colored text={secondButtonText} action={secondButtonAction} />
            )}
        </>
    );
};

const Button = ({text, action, ...rest}) => {
    const redirectToExternalLink = useConfigExternalLink(action);
    const onClick = (e) => {
        e.stopPropagation();
        redirectToExternalLink();
    };

    return (
        <AppButton className='mt-0' {...rest} onClick={onClick}>
            {text}
        </AppButton>
    );
};

export default HighlightItem;
