import React from 'react';

import appRouterService from '../../../services/route/appRouterService';
import InboxIconComponent from './InboxIconComponent';

const InboxIconMobile = ({isUnreadExists}) => {
    const onClick = () => {
        appRouterService.forwardToInboxPage();
    };

    return <InboxIconComponent onClick={onClick} isUnreadExists={isUnreadExists} />;
};

export default InboxIconMobile;
