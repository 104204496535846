import React, {useRef} from 'react';
import {useOnClickOutside} from 'usehooks-ts';

import PopupCentered from './PopupCentered';

const PopupOutline = ({className, onClose, contentRef, ...rest}) => {
    const ref = useRef();

    useOnClickOutside(ref, onClose);

    return (
        <PopupCentered
            isCloseVisible
            onClose={onClose}
            className={className}
            contentRef={(node) => {
                contentRef?.(node);
                ref.current = node;
            }}
            {...rest}
        />
    );
};

export default PopupOutline;
