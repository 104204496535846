import React from 'react';

import useIsMobileLayout from '../../hooks/useIsMobileLayout';
import PopupFullPage from './PopupFullPage';
import PopupOutline from './PopupOutline';

const PopupResponsive = (props) => {
    const isMobile = useIsMobileLayout();

    if (isMobile) {
        return <PopupFullPage {...props} />;
    } else {
        return <PopupOutline {...props} />;
    }
};

export default PopupResponsive;
