import {useGetAutoDeliveryDetailsExtendedLazyQuery, useGetAutoDeliveryDetailsLazyQuery} from '@/api/consumerApi';
import {AUTO_DELIVERY_STATUS_TYPES} from '@/consts/icc/iccAutoDeliveryStatusTypes';
import appRouterService from '@/services/route/appRouterService';
import {
    showAutoDeliveryStatusInactiveDetailsPopup,
    showAutoDeliveryStatusNoneDetailsPopup,
} from '@/state/slices/modalSlice';
import {dispatch} from '@/state/store';

import useExternalLinkRedirectLoader from './useExternalLinkRedirectLoader';

const useExternalLinkRedirectToAutoDelivery = () => {
    const {showLoader, hideLoader} = useExternalLinkRedirectLoader();
    const [getAutoDeliveryDetails] = useGetAutoDeliveryDetailsLazyQuery();
    const [getAutoDeliveryDetailsExtended] = useGetAutoDeliveryDetailsExtendedLazyQuery();

    const redirectToAutoDelivery = async () => {
        showLoader();

        try {
            const {data: autoDeliveryData} = await getAutoDeliveryDetails(null, true);
            const {autoDelivery: {status: autoDeliveryStatus} = {}} = autoDeliveryData || {};

            if (!autoDeliveryStatus) return;

            if (autoDeliveryStatus === AUTO_DELIVERY_STATUS_TYPES.NONE) {
                dispatch(showAutoDeliveryStatusNoneDetailsPopup());
            } else if (autoDeliveryStatus === AUTO_DELIVERY_STATUS_TYPES.INACTIVE) {
                dispatch(showAutoDeliveryStatusInactiveDetailsPopup());
            } else {
                const {data: autoDeliveryExtendedData} = await getAutoDeliveryDetailsExtended(null, true);

                if (autoDeliveryExtendedData) {
                    appRouterService.forwardToAutoDeliveryPage();
                }
            }
        } finally {
            hideLoader();
        }
    };

    return redirectToAutoDelivery;
};

export default useExternalLinkRedirectToAutoDelivery;
