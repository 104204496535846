import React from 'react';
import {useSelector} from 'react-redux';

import AemComponent from '../../../components/Aem/AemComponent/AemComponent';
import EditableText from '../../../components/Aem/EditableText/EditableText';
import {AppButton} from '../../../components/Button';
import DeviceIcon from '../../../components/DeviceIcon/DeviceIcon';
import {StickyHeader} from '../../../components/Layout';
import appConfig from '../../../config/appConfig';
import {AD_SELECT_ISSUE_HEADER_TITLE} from '../../../consts/localization/localizationKeys';
import useDocumentTitle from '../../../hooks/useDocumentTitle';
import amplitudeADTracking from '../../../services/amplitude/amplitudeADTracking';
import {getLocalizedStrings} from '../../../services/localization/localizationService';
import routerService from '../../../services/route/routerService';
import {selectDeviceType} from '../../../state/selectors/device';
import AdvancedDiagnosticContainer from '../components/AdvancedDiagnosticContainer';
import AdvancedDiagnosticDescription from '../components/AdvancedDiagnosticDescription';
import AdvancedDiagnosticTitle from '../components/AdvancedDiagnosticTitle';
import useRedirectToPhase2 from '../hooks/useRedirectToPhase2';
import AdvancedDiagnosticIssueList from './components/AdvancedDiagnosticIssueList';

const AdvancedDiagnosticSelectIssue = () => {
    const deviceType = useSelector(selectDeviceType);
    const diagnosticData = appConfig.getAdConfig(deviceType, 'step2');
    const redirectToPhase2 = useRedirectToPhase2();
    const localizedStrings = getLocalizedStrings();

    const onNextButtonClick = async () => {
        amplitudeADTracking.trackADNoneOfTheAboveClick();
        redirectToPhase2();
    };

    useDocumentTitle(localizedStrings[AD_SELECT_ISSUE_HEADER_TITLE]);

    return (
        <AdvancedDiagnosticContainer>
            <StickyHeader onBack={routerService.goBack} title={localizedStrings[AD_SELECT_ISSUE_HEADER_TITLE]} />
            <DeviceIcon mode='diagnostics' className='h-16 w-16' />
            <AdvancedDiagnosticTitle itemName='selectissuetitle' />
            <AdvancedDiagnosticDescription itemName='selectissuedesc' />
            <AdvancedDiagnosticIssueList issuesData={diagnosticData} />
            <AppButton secondary light onClick={onNextButtonClick} className='mx-auto mt-6'>
                <AemComponent component={EditableText} pageName='ad' itemName='selectissuebutton' />
            </AppButton>
        </AdvancedDiagnosticContainer>
    );
};

export default AdvancedDiagnosticSelectIssue;
