import AutoDeliveryDetailsFooter from '../components/AutoDeliveryDetailsFooter';
import AutoDeliveryOrder from '../components/AutoDeliveryOrder';
import AutoDeliveryOrderAddress from '../components/AutoDeliveryOrderAddress';
import AutoDeliveryOrderHeader from '../components/AutoDeliveryOrderHeader';
import AutoDeliveryOrderSummary from '../components/AutoDeliveryOrderSummary';
import AutoDeliveryPageDesktopLayout from '../components/AutoDeliveryPageDesktopLayout';
import ConfirmAsapButton from './ConfirmAsapButton';
import ConfirmSkipButton from './ConfirmSkipButton';
import DeliveryAsapSection from './DeliveryAsapSection';
import DeliverySkipSection from './DeliverySkipSection';

const EditDeliveryDesktop = ({isAsap, isSkip, data, onClose, isShowSkipButton}) => {
    const {
        order: {price, deliveryAddress, products},
    } = data;

    return (
        <AutoDeliveryPageDesktopLayout>
            <AutoDeliveryPageDesktopLayout.Start>
                {isAsap && <DeliveryAsapSection />}
                {isSkip && <DeliverySkipSection />}
                <AutoDeliveryOrder className='mt-8 p-0' products={products}>
                    <AutoDeliveryOrderHeader isEditAllowed titleClassName='md:text-24 md:mb-2' />
                </AutoDeliveryOrder>
                <AutoDeliveryDetailsFooter>
                    {isAsap && <ConfirmAsapButton closePopup={onClose} />}
                    {isShowSkipButton && <ConfirmSkipButton closePopup={onClose} />}
                </AutoDeliveryDetailsFooter>
            </AutoDeliveryPageDesktopLayout.Start>
            <AutoDeliveryPageDesktopLayout.End>
                <AutoDeliveryOrderSummary className='p-0 pb-4' price={price} />
                <AutoDeliveryOrderAddress
                    className='border-t border-secondary p-0 pt-4'
                    address={deliveryAddress}
                    isEditAllowed
                />
            </AutoDeliveryPageDesktopLayout.End>
        </AutoDeliveryPageDesktopLayout>
    );
};

export default EditDeliveryDesktop;
