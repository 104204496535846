import React, {useEffect} from 'react';

import PageWarningBannersContainer from '@/containers/WarningBanners/PageWarningBannersContainer';
import useConsumerProductsLoader from '@/hooks/useConsumerProductsLoader';
import useRefetchConsumerProducts from '@/hooks/useRefetchConsumerProducts';
import amplitudeDevicesPageTracking from '@/services/amplitude/amplitudeDevicesPageTracking';
import HomePageLayout from '@/views/Home/HomePageLayout';

import MyDevicesView from './components/MyDevicesView/MyDevicesView';
import NoDevicesView from './components/NoDevicesView/NoDevicesView';
import useGetDeviceSectionList from './hooks/useGetDeviceSectionList';

const DevicesPage = () => {
    const deviceList = useGetDeviceSectionList();
    const isDeviceListEmpty = !deviceList?.length;

    useEffect(() => {
        amplitudeDevicesPageTracking.trackDevicesPageScreen();
    }, []);

    useRefetchConsumerProducts();
    useConsumerProductsLoader();

    return (
        <HomePageLayout className='bg-secondary'>
            {isDeviceListEmpty ? <NoDevicesView /> : <MyDevicesView />}
            <div className='md:px-16 md:pb-4 md:pt-2'>
                <PageWarningBannersContainer />
            </div>
        </HomePageLayout>
    );
};

export default DevicesPage;
