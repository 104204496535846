import React from 'react';

import InputFieldWarning from '../../../containers/LoginPage/components/InputFieldWarning';
import {ButtonRaw} from '../../Button';
import {ErrorIcon, RoundedCancelIcon} from '../../Icons';
import Input from '../../InputNBW/InputNBW';

export const FormInput = (props) => {
    const {
        isValid,
        isEntered,
        label,
        inputClassName,
        onInputChange,
        placeholder,
        warningNotValid,
        warningRequiredField,
        clearInput,
        type,
        value,
    } = props;

    const iconClassName =
        'absolute right-3 top-1/2 z-[11] m-0 h-6 w-6 -translate-y-1/2 p-0 rtl:left-3 rtl:right-auto md:rtl:right-auto';

    return (
        <>
            <div className='mb-2 text-16 text-primary'>{label}</div>
            <div className='relative w-full'>
                <Input
                    className={inputClassName}
                    onChange={onInputChange}
                    placeholder={placeholder}
                    type={type}
                    maxLength={50}
                    minLength={3}
                    value={value}
                />
                {value && isValid && (
                    <ButtonRaw onClick={clearInput} className={iconClassName}>
                        <RoundedCancelIcon className='h-full w-full cursor-pointer' />
                    </ButtonRaw>
                )}
                {!isValid && <ErrorIcon className={iconClassName} />}
            </div>
            <div className='mb-6 mt-1 min-h-2 md:mb-0 lg:empty:hidden'>
                <InputFieldWarning
                    isValid={isValid}
                    isEntered={isEntered}
                    warningNotValid={warningNotValid}
                    warningRequiredField={warningRequiredField}
                />
            </div>
        </>
    );
};
