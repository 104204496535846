import useIsMobileLayout from '@/hooks/useIsMobileLayout';

const {useRef, useState} = require('react');

const useSwiperNavigation = () => {
    const prevRef = useRef(null);
    const nextRef = useRef(null);
    const [isBeginning, setIsBeginning] = useState(true);
    const [isEnd, setIsEnd] = useState(false);
    const isMobile = useIsMobileLayout();
    const isNavigationEnabled = !isMobile;

    const swiperOptions = isNavigationEnabled
        ? {
              navigation: true,
              onInit: (swiper) => {
                  swiper.params.navigation.prevEl = prevRef.current;
                  swiper.params.navigation.nextEl = nextRef.current;
                  swiper.navigation.init();
                  swiper.navigation.update();
              },
              onSlideChange: (swiper) => {
                  setIsBeginning(swiper.progress <= 0); // At the beginning
                  setIsEnd(swiper.progress >= 1); // At the end
              },
              onProgress: (swiper, progress) => {
                  const {virtualSize, width} = swiper;
                  const isEnd = progress >= 1 || virtualSize <= width;

                  setIsBeginning(progress <= 0);
                  setIsEnd(isEnd);
              },
              onSetTranslate: (swiper, translate) => {
                  // Fine-tune behavior based on the exact translate value
                  setIsBeginning(translate >= 0); // If translate is 0 or more, we're at the start
                  setIsEnd(translate <= swiper.maxTranslate()); // If translate equals max, we're at the end
              },
          }
        : {};

    return {
        prevRef,
        nextRef,
        isBeginning,
        setIsBeginning,
        isEnd,
        setIsEnd,
        swiperOptions,
        isNavigationEnabled,
    };
};

export default useSwiperNavigation;
