import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';

import queryParameters from '../../consts/route/queryParameters';
import {TERMS_OF_USE, WEB_PREFERENCE_CENTER} from '../../consts/tnc/tncTypes';
import amplitudeProfileTracking from '../../services/amplitude/amplitudeProfileTracking';
import appErrorService from '../../services/app/appErrorService';
import routerService from '../../services/route/routerService';
import tncService from '../../services/tncService';
import {selectAttributionNoticeUrl, selectPrivacyNoticeUrl} from '../../state/selectors/consumer';
import urlUtils from '../../utils/urlUtils';

const useTncDetailsPage = () => {
    const [pageTypeFromUrl] = urlUtils.getDataFromUrl(queryParameters.TNC_PAGE_TYPE);

    const [pageType, setPageType] = useState(pageTypeFromUrl);
    const privacyNoticeUrl = useSelector(selectPrivacyNoticeUrl);
    const attributionNoticeUrl = useSelector(selectAttributionNoticeUrl);

    useEffect(() => {
        setPageType(pageTypeFromUrl);
    }, [pageTypeFromUrl]);

    useEffect(() => {
        tncService.getTncData(null, appErrorService.showGlobalError);
    }, []);

    const handlePreferenceCenterClick = () => {
        setPageType(WEB_PREFERENCE_CENTER);
    };

    const handleTermsOfUseClick = () => {
        amplitudeProfileTracking.trackTermsOfUseClick();
        setPageType(TERMS_OF_USE);
    };

    const handlePrivacyNoticeClick = () => {
        amplitudeProfileTracking.trackPrivacyNoticeClick();
        routerService.blankLinkOpen(privacyNoticeUrl);
    };

    const handleAttributionNoticeClick = () => {
        amplitudeProfileTracking.trackAttributionNoticeClick();
        routerService.blankLinkOpen(attributionNoticeUrl);
    };

    return {
        handlePreferenceCenterClick,
        handlePrivacyNoticeClick,
        handleTermsOfUseClick,
        handleAttributionNoticeClick,
        pageType,
    };
};

export default useTncDetailsPage;
