import {useSelector} from 'react-redux';
import {useLocation} from 'react-router-dom';

import {selectIsAem, selectLanguageCode} from '@/state/selectors/global';

const useLocationPathname = () => {
    const languageCode = useSelector(selectLanguageCode);
    const isAem = useSelector(selectIsAem);
    const {pathname} = useLocation();

    if (isAem) {
        return {pathname: pathname.replace(`/${languageCode}/`, '/')};
    }

    return {pathname};
};

export default useLocationPathname;
