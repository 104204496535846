import history from '../history';

const forwardTo = (location, state = null) => history.getHistory().push(location, state);

const goBack = () => history.getHistory().goBack();

const go = (n) => history.getHistory().go(n);

const reload = () => window.location.reload();

const replace = (location) => history.getHistory().replace(location);

const redirect = (location) => {
    window.location.href = location;
};

const blankLinkOpen = (url) => {
    window.open(url, '_blank');
};

const getCurrent = () => history.getHistory().location?.pathname;

export default {
    blankLinkOpen,
    forwardTo,
    getCurrent,
    go,
    goBack,
    redirect,
    reload,
    replace,
};
