import React from 'react';

import {PopupFullPage} from '@/components/Popup';
import useModalRoute from '@/hooks/useModalRoute';
import modalService from '@/services/modalService';

import useLanguageSelect from '../../hooks/useLanguageSelect';
import LanguageSelectPopupContent from './LanguageSelectPopupContent';
import LanguageSelectPopupTrigger from './LanguageSelectPopupTrigger';

const LanguageSelectPopup = ({modalType}) => {
    const {languageCode, selectListData, handleLanguageChange} = useLanguageSelect();
    const closeModal = () => modalService.hideModal(modalType);
    const {onClose} = useModalRoute({modalType, closeModal});

    return (
        <PopupFullPage>
            <LanguageSelectPopupContent
                selectedLanguageCode={languageCode}
                selectListData={selectListData}
                onLanguageChange={handleLanguageChange}
                onClose={onClose}
            />
        </PopupFullPage>
    );
};

LanguageSelectPopup.Trigger = LanguageSelectPopupTrigger;

export default LanguageSelectPopup;
