import cn from 'classnames';
import React, {useState} from 'react';
import {NavLink, useLocation} from 'react-router-dom';

import AemEditableText from '@/components/Aem/AemEditableText/AemEditableText';
import RemoteIcon from '@/components/Icon/RemoteIcon';
import {DevicesIcon, HomeIcon, MyIqosIcon} from '@/components/Icons';
import appConfig from '@/config/appConfig';
import ROUTE_PATHS from '@/consts/route/routePaths';
import useWithLocale from '@/hooks/useWithLocale';
import {twx} from '@/utils/tailwindUtils';

const HomePageNavigationBar = ({activePath}) => {
    const withLocale = useWithLocale();
    const [navbarRef, setNavbarRef] = useState(null);

    return (
        <>
            <div style={{height: navbarRef?.offsetHeight}} className='lg:hidden' />
            <div
                ref={(ref) => setNavbarRef(ref)}
                className='fixed bottom-0 left-0 z-[1] w-full lg:top-0 lg:z-[102] lg:w-[--sidebar-width]'
            >
                <div
                    className={`border-input-border flex w-full 
                                border-t border-border bg-white lg:h-full lg:flex-col lg:gap-4 lg:border-none lg:px-6 lg:py-8 lg:text-black`}
                >
                    <NavLink to={withLocale(ROUTE_PATHS.HOME_PAGE)} className='hidden lg:block'>
                        <RemoteIcon imagePath={appConfig.getLogoImagePath()} className='m-auto mb-4' />
                    </NavLink>
                    <Link
                        className='pl-2 lg:pl-0'
                        to={withLocale(ROUTE_PATHS.HOME_PAGE)}
                        activePath={withLocale(activePath)}
                        icon={HomeIcon}
                        itemName='forYouMenuItem'
                    />
                    <Link
                        iconClassName='my-1 mr-0.5 ml-[1px] lg:m-0'
                        to={withLocale(ROUTE_PATHS.SHOP_PAGE)}
                        activePath={withLocale(activePath)}
                        icon={MyIqosIcon}
                        itemName='shopMenuItem'
                    />
                    <Link
                        className='pr-2 lg:pr-0'
                        to={withLocale(ROUTE_PATHS.DEVICES_PAGE)}
                        activePath={withLocale(activePath)}
                        icon={DevicesIcon}
                        itemName='deviceMenuItem'
                    />
                </div>
            </div>
        </>
    );
};

const Link = ({to, icon: Icon, className, iconClassName, itemName, activePath}) => {
    const {pathname} = useLocation();
    const isActive = to === pathname || to === activePath;

    return (
        <NavLink
            to={to}
            className={twx(
                'flex w-full flex-col items-center gap-1 pt-3 text-12 pb-safe-or-3 lg:gap-2 lg:p-0 lg:text-14 lg:tracking-04',
                className
            )}
        >
            <div
                className={cn('flex h-8 w-16  items-center justify-center rounded-full lg:h-10 lg:w-22', {
                    'bg-tertiary-a30': isActive,
                })}
            >
                <Icon className={twx('text-romance', iconClassName, {'text-tertiary-a30': isActive})} />
            </div>
            <div className={cn(isActive && 'font-bold')}>
                <AemEditableText pageName='home' itemName={itemName} />
            </div>
        </NavLink>
    );
};

export default HomePageNavigationBar;
