import {useEffect} from 'react';

import appRouterService from '@/services/route/appRouterService';
import userBrowserService from '@/services/user/userBrowserService';

const useInstalledAppRedirection = () => {
    useEffect(() => {
        const handleAppInstalled = () => {
            appRouterService.replaceToLoginPage();
        };

        window.addEventListener('appinstalled', handleAppInstalled);

        return () => {
            window.removeEventListener('appinstalled', handleAppInstalled);
        };
    }, []);

    useEffect(() => {
        const handleVisibilityChange = () => {
            if (document.visibilityState === 'visible' && userBrowserService.isInStandaloneMode()) {
                appRouterService.replaceToLoginPage();
            }
        };

        document.addEventListener('visibilitychange', handleVisibilityChange);

        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, []);
};

export default useInstalledAppRedirection;
