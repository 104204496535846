import {combineReducers, configureStore, createDynamicMiddleware} from '@reduxjs/toolkit';

import {iccApi} from '../api/iccApi';
import {iccNotificationApi} from '../api/iccNotificationApi';
import appConfig from '../config/appConfig';
import advancedDiagnostic from '../containers/AdvancedDiagnosticPage/state/advancedDiagnosticSlice';
import * as slices from './slices';

let dispatch;
let getState;

const rootReducer = (state, action) => {
    const LOGOUT_ACTION = 'global/logoutClearData';

    if (action.type === LOGOUT_ACTION) {
        const {isAppLoading, isConfigInitialized, languageCode, layout} = state.global;

        state = {
            auth: {},
            modal: state.modal,
            global: {
                isAppLoading,
                isConfigInitialized,
                languageCode,
                layout,
                notifications: [],
                loaders: [],
                hwContainerIds: [],
            },
        };
    }

    return combineReducers({
        ...slices,
        [iccApi.reducerPath]: iccApi.reducer,
        [iccNotificationApi.reducerPath]: iccNotificationApi.reducer,
        advancedDiagnostic,
    })(state, action);
};

const dynamicMiddleware = createDynamicMiddleware();

const configureAppStore = (initialState = {}) => {
    const isDebug = appConfig.getIsDebug();

    const store = configureStore({
        reducer: rootReducer,
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware()
                .prepend(dynamicMiddleware.middleware)
                .concat(iccApi.middleware)
                .concat(iccNotificationApi.middleware),
        devTools: isDebug,
        preloadedState: initialState,
    });

    dispatch = store.dispatch;
    getState = store.getState;

    return store;
};

const addReduxLoggerIfEnabled = async () => {
    if (appConfig.getIsDebug()) {
        const {logger} = await import(/* webpackChunkName: "lazy-chunk.logger" */ 'redux-logger');

        dynamicMiddleware.addMiddleware(logger);
    }
};

export {addReduxLoggerIfEnabled, configureAppStore as configureStore, dispatch, getState};
