import cn from 'classnames';
import React from 'react';

import {ButtonRaw} from '../../../../components/Button';
import AppLoaderContent from '../../../../components/Loader/AppLoader/AppLoaderContent';
import InboxNotificationItem from './InboxNotificationItem/InboxNotificationItem';

const InboxNotificationsList = ({notifications, sentryRef, hasNextPage, onDeleteMessage, ...rest}) => {
    return (
        <ul>
            {notifications?.map((notification) => (
                <NotificationListItem
                    key={notification.messageId}
                    notification={notification}
                    onDeleteMessage={onDeleteMessage}
                    {...rest}
                />
            ))}
            {hasNextPage && (
                <li ref={sentryRef} className={cn(notifications === undefined ? 'mt-72 lg:mt-40' : 'mt-5')}>
                    <AppLoaderContent className='flex justify-center' />
                </li>
            )}
        </ul>
    );
};

export default InboxNotificationsList;

const NotificationListItem = ({notification, onDeleteMessage, onMessageClick, ...rest}) => {
    const {messageId} = notification;

    const onClick = () => {
        onMessageClick(messageId);
    };

    return (
        <li>
            <ButtonRaw className='w-full' onClick={onClick}>
                <InboxNotificationItem {...notification} {...rest} />
            </ButtonRaw>
            <hr className='h-px bg-mischka' />
        </li>
    );
};
