import {AppButton} from '../../../../components/Button';
import CloseButton from '../../../../components/CloseButton/CloseButton';
import {StickyPageLayout} from '../../../../components/Layout';
import PopupResponsive from '../../../../components/Popup/PopupResponsive';

const EditDeliveryResultPopup = ({icon, title, description, button, onClose, onButtonClick}) => {
    return (
        <PopupResponsive
            onClose={onClose}
            className='z-[21] flex flex-col bg-romance md:ml-[calc(var(--sidebar-width)/2)] md:w-full md:max-w-[494px] md:rounded-xl md:p-8 md:pt-19 md:text-center'
        >
            <StickyPageLayout
                contentClassName='flex-1 p-8 flex flex-col justify-center items-center'
                desktopContentClassName='flex flex-col items-center'
                desktopFooterClassName='flex flex-col items-center'
                headerOptions={{
                    endIcon: <CloseButton onClick={onClose} />,
                }}
                footer={
                    <AppButton primary light className='mt-0 md:mt-10 md:min-w-78' onClick={onButtonClick}>
                        {button}
                    </AppButton>
                }
            >
                {icon}
                <h2 className='mb-0 mt-8 text-32 md:mt-6'>{title}</h2>
                <p className='text-20 tracking-02 md:mt-6'>{description}</p>
            </StickyPageLayout>
        </PopupResponsive>
    );
};

export default EditDeliveryResultPopup;
