import cn from 'classnames';
import React from 'react';

import AemEditableText from '@/components/Aem/AemEditableText/AemEditableText';
import {ButtonRaw} from '@/components/Button';
import appConfig from '@/config/appConfig';
import SignedImage from '@/containers/System/SignedImage';
import useConfigExternalLink from '@/hooks/externalLink/useConfigExternalLink';
import amplitudeShopPageTracking from '@/services/amplitude/amplitudeShopPageTracking';
import {getLocalizedStrings} from '@/services/localization/localizationService';
import arrayUtils from '@/utils/arrayUtils';

let productsSorted;

const getProducts = () => {
    if (productsSorted) return productsSorted;
    const productsFromConfig = appConfig.getShopByProductSection();
    const productsWithAction = productsFromConfig?.filter((p) => p?.action);

    if (!productsWithAction || !productsWithAction.length) return null;

    const productsWithOrder = arrayUtils.sortArrayWithOrderField(
        productsWithAction?.filter((p) => p?.order !== undefined)
    );
    const productsWithoutOrder = productsWithAction.filter((p) => p?.order === undefined);

    productsSorted = [...productsWithOrder, ...productsWithoutOrder];
    return productsSorted;
};

const ShopByProductSection = ({className}) => {
    const products = getProducts();
    const localizedStrings = getLocalizedStrings();

    if (!products || !products.length) return;

    return (
        <section className={cn('bg-romance px-4 py-8', className)}>
            <h2 className='mb-0 text-24'>
                <AemEditableText pageName='shop' itemName='shopByProductTitle' />
            </h2>
            <div className='mt-4 grid grid-cols-2 gap-x-2 gap-y-4 md:grid-cols-4 lg:flex lg:flex-wrap'>
                {products.map(({imagePath, titleKey, action, amplitudeTrackingValue}, index) => (
                    <ShopByProductTile
                        imagePath={imagePath}
                        title={localizedStrings[titleKey]}
                        action={action}
                        amplitudeTrackingValue={amplitudeTrackingValue}
                        key={index}
                    />
                ))}
            </div>
        </section>
    );
};

const ShopByProductTile = ({imagePath, title, action, amplitudeTrackingValue}) => {
    const combinedImagePath = appConfig.getCombinedPathAssetPath(imagePath);
    const redirectToExternalLink = useConfigExternalLink(action);
    const onTileClick = () => {
        amplitudeShopPageTracking.trackShopByProductBannerClick(amplitudeTrackingValue);
        redirectToExternalLink();
    };

    return (
        <ButtonRaw
            className='flex max-w-[250px] flex-col items-start gap-4 rounded-xl bg-secondary p-4 lg:w-full'
            onClick={action && onTileClick}
        >
            <span className='line-clamp-2 w-full text-14 font-bold leading-[20px] text-black'>{title}</span>
            <div className='aspect-square w-full'>
                <SignedImage imgClassName='h-full object-contain' mediaData={combinedImagePath} signIfNull />
            </div>
        </ButtonRaw>
    );
};

export default ShopByProductSection;
