import React from 'react';

import AemComponent from '@/components/Aem/AemComponent/AemComponent';
import EditableText from '@/components/Aem/EditableText/EditableText';
import {AppButton} from '@/components/Button';
import appConfig from '@/config/appConfig';
import useConfigExternalLink from '@/hooks/externalLink/useConfigExternalLink';

const LeviaLearnMoreButtons = ({onCancel}) => {
    const checkCompatibilityExternalLink = appConfig.getLeviaCheckCompatibilityExternalLink();
    const learnMoreExternalLink = appConfig.getLeviaLearnMoreExternalLink();
    const redirectToExternalLink = useConfigExternalLink(checkCompatibilityExternalLink);
    const redirectToLearnMore = useConfigExternalLink(learnMoreExternalLink);

    return (
        <div className='m-auto flex max-w-[312px] flex-col items-center gap-4'>
            {checkCompatibilityExternalLink && (
                <AppButton className='mt-0 ' primary light onClick={redirectToExternalLink}>
                    <AemComponent component={EditableText} pageName='modals' itemName='leviaCompatibilityButton' />
                </AppButton>
            )}
            {learnMoreExternalLink && (
                <AppButton className='mt-0' secondary light onClick={redirectToLearnMore}>
                    <AemComponent component={EditableText} pageName='modals' itemName='leviaLearnMoreButton' />
                </AppButton>
            )}
            <AppButton tertiary className='mt-0 text-center' onClick={onCancel}>
                <AemComponent component={EditableText} pageName='modals' itemName='leviaSkipButton' />
            </AppButton>
        </div>
    );
};

export default LeviaLearnMoreButtons;
