import ModalTypes from '../../consts/app/modalTypes';
import {showModalAction} from '../../state/slices/modalSlice';
import {dispatch} from '../../state/store';

const ModalsPage = () => {
    const showPopup = (modalType) => {
        dispatch(showModalAction({modalType}));
    };

    return (
        <section className='flex flex-col gap-2'>
            <ul>
                <li className='cursor-pointer' onClick={() => showPopup(ModalTypes.LEVIA_LEAR_MORE)}>
                    LEVIA_LEAR_MORE
                </li>
                <li className='cursor-pointer' onClick={() => showPopup(ModalTypes.FIRMWARE_AVAILABLE)}>
                    FIRMWARE_AVAILABLE
                </li>
                <li className='cursor-pointer' onClick={() => showPopup(ModalTypes.BROWSER_NOT_COMPATIBLE)}>
                    BROWSER_NOT_COMPATIBLE
                </li>
                <li className='cursor-pointer' onClick={() => showPopup(ModalTypes.UWM_UNAVAILABILITY)}>
                    UWM_UNAVAILABILITY
                </li>
                <li className='cursor-pointer' onClick={() => showPopup(ModalTypes.CONSUMER_FEEDBACK_GATHERING)}>
                    CONSUMER_FEEDBACK_GATHERING
                </li>
                <li className='cursor-pointer' onClick={() => showPopup(ModalTypes.LANGUAGE_SELECT)}>
                    LANGUAGE_SELECT
                </li>
            </ul>
        </section>
    );
};

export default ModalsPage;
