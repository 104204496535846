import {addSignedMedia} from '../state/slices/mediaSlice';
import {dispatch} from '../state/store';
import helpers from '../utils/helpers';
import iccMarketService from './icc/iccMarketService';

const MAX_RETRY_COUNT = 5;
const SIGNED_MEDIA_URL_WAIT_MS = 200;

let retryCount = 0;
let scheduledMediaIds = []; //example: {mediaId: <mediaId>, data: <result data>}
let scheduler;
let isSchedulerInProgress;

const getSignedMediaUrl = async (mediaIds, isRetry = false) => {
    if (!isRetry) {
        setupSignedMediaUrlScheduler();

        const mediaIdsToAdd = mediaIds
            .map((x) => {
                if (x) return {mediaId: x};
                return undefined;
            })
            .filter((x) => !!x);

        scheduledMediaIds = scheduledMediaIds.filter((y) => !mediaIds.includes(y.mediaId));
        scheduledMediaIds.push(...mediaIdsToAdd);
    }

    //only undefined - start of query
    const data = scheduledMediaIds.filter((x) => mediaIds.includes(x.mediaId) && x.data !== undefined);

    if (data?.length === mediaIds?.length) {
        //not undefined but check for valid response
        const isNoResponseData = data.find((x) => !x.data);

        if (isNoResponseData) return;

        return data;
    }

    await helpers.timeout(SIGNED_MEDIA_URL_WAIT_MS);

    return getSignedMediaUrl(mediaIds, true);
};

const setupSignedMediaUrlScheduler = () => {
    if (scheduler) return;

    scheduler = setInterval(async () => {
        if (isSchedulerInProgress) return;

        //only undefined - start of query
        const mediaNameList = scheduledMediaIds
            .filter((x) => x.data === undefined && !!x.mediaId)
            .map((x) => x.mediaId);

        if (!mediaNameList?.length) return;

        isSchedulerInProgress = true;

        const data = await iccMarketService.getMediaUrl({mediaNameList});

        const setSignedMedias = (signedMedias) => {
            signedMedias?.forEach((signedMedia) => {
                const {mediaId, url} = signedMedia;

                scheduledMediaIds = scheduledMediaIds.filter((y) => y.mediaId !== mediaId);
                scheduledMediaIds.push({mediaId, data: url});
            });
        };

        if (data) {
            retryCount = 0;

            dispatch(addSignedMedia(data));

            setSignedMedias(data);
        } else {
            retryCount++;

            if (retryCount >= MAX_RETRY_COUNT) {
                setSignedMedias(
                    mediaNameList.map((x) => {
                        return {mediaId: x, url: null};
                    })
                );
            }
        }

        isSchedulerInProgress = false;
    }, SIGNED_MEDIA_URL_WAIT_MS);
};

export default {
    getSignedMediaUrl,
};
