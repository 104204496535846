import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Router} from 'react-router-dom';

import {selectIsAem, selectIsAppLoading} from '@/state/selectors/global';
import {setIsAppLoaded} from '@/state/slices/globalSlice';
import {hideLoader} from '@/state/slices/loadersSlice';

import AppLoaderRaw from '../../components/Loader/AppLoader/AppLoaderRaw';
import useFeedbackPopup from '../../hooks/useFeedbackPopup';
import useLayoutChange from '../../hooks/useLayoutChange';
import useSWMessage from '../../hooks/useSWMessage';
import useSWMessageHandler from '../../hooks/useSWMessageHandler';
import appService from '../../services/appService';
import history from '../../services/history';
import log from '../../services/logger/log';
import appRouterService from '../../services/route/appRouterService';
import userBrowserService from '../../services/user/userBrowserService';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';
import Routes from '../Routes/Routes';
import AppUpdatePopup from './components/AppUpdatePopup/AppUpdatePopup';
import useAmplitudeInit from './hooks/amplitude/useAmplitudeInit';
import useConsumerSettingsChecker from './hooks/useConsumerSettingsChecker';
import useFetchConsumerProducts from './hooks/useFetchConsumerProducts';
import useInitSelectedDeviceCodentify from './hooks/useInitSelectedDeviceCodentify';
import useUpdateBadges from './hooks/useUpdateBadges';

const App = () => {
    const isAppLoading = useSelector(selectIsAppLoading);
    const isAem = useSelector(selectIsAem);
    const dispatch = useDispatch();

    const onInit = async () => {
        dispatch(setIsAppLoaded());

        const isInStandaloneMode = userBrowserService.isInStandaloneMode();

        log.info(`appService: app is initialized, ua: ${userBrowserService.getUserAgent()}`);
        log.info(`App type: ${isInStandaloneMode ? 'PWA' : 'In-Browser'}`);

        if (!isAem) {
            appRouterService.forwardToRootPage();
        }
        await appService.onAppStart();

        log.info(`App: app is started successfully`);

        dispatch(hideLoader());
    };

    useEffect(() => {
        onInit();
    }, []);

    useSWMessage();
    useSWMessageHandler();
    useUpdateBadges();
    useAmplitudeInit();
    useFeedbackPopup();
    useFetchConsumerProducts();
    useConsumerSettingsChecker();
    useInitSelectedDeviceCodentify();

    return (
        <ErrorBoundary>
            <LayoutChangeListener />
            <AppUpdatePopup />
            {isAppLoading ? (
                <AppLoaderRaw />
            ) : (
                <Router history={history.getHistory()}>
                    <Routes />
                </Router>
            )}
        </ErrorBoundary>
    );
};

export default App;

const LayoutChangeListener = () => {
    useLayoutChange();
};
