import React, {useEffect} from 'react';

import {ButtonRaw} from '@/components/Button';
import {UserIcon} from '@/components/Icons';
import {StickyHeader} from '@/components/Layout';
import DesktopStickyHeader from '@/components/Layout/DesktopStickyHeader';
import useIsDesktopLayout from '@/hooks/useIsDesktopLayout';
import homePageNavigationService from '@/services/homePageNavigationService';
import appRouterService from '@/services/route/appRouterService';
import {twx} from '@/utils/tailwindUtils';

import InboxIcon from './InboxIcon/InboxIcon';

const HomePageTopNavigation = ({source, title, children, className, profileControlsClassName}) => {
    const isDesktop = useIsDesktopLayout();

    useEffect(() => {
        if (isDesktop) return;
        homePageNavigationService.setSource(source);
    }, [source, isDesktop]);

    const endIcon = ({isSticked}) => <ProfileControls className={isSticked ? '' : profileControlsClassName} />;

    return isDesktop ? (
        <DesktopStickyHeader
            endIcon={endIcon}
            className={className}
            fixedClassName='w-[calc(100%-var(--sidebar-width))]'
        >
            {children}
        </DesktopStickyHeader>
    ) : (
        <StickyHeader title={title} endIcon={endIcon} className={className} />
    );
};

const ProfileControls = ({className}) => {
    const onConsumerProfileClick = () => {
        appRouterService.forwardToConsumerProfilePage();
    };

    return (
        <div className={twx('flex gap-2 rounded-full bg-secondary p-1', className)}>
            <InboxIcon />
            <ButtonRaw onClick={onConsumerProfileClick}>
                <UserIcon className='h-6 w-6' />
            </ButtonRaw>
        </div>
    );
};

export default HomePageTopNavigation;
