import React from 'react';

import AemComponent from '../../../components/Aem/AemComponent/AemComponent';
import EditableNotificationsInbox from '../../../components/Aem/EditableNotificationsInbox/EditableNotificationsInbox';
import useInboxListView from '../../../hooks/inbox/useInboxListView';
import InboxPopoverContent from './InboxPopoverContent';
import InboxPopoverMainTitle from './InboxPopoverMainTitle';

const InboxPopoverList = ({onMessageClick}) => {
    const {inboxMessages, sentryRef, hasNextPage, onDeleteMessage} = useInboxListView();

    return (
        <InboxPopoverContent>
            <InboxPopoverContent.Title>
                <InboxPopoverMainTitle />
            </InboxPopoverContent.Title>
            <InboxPopoverContent.Content>
                <AemComponent
                    component={EditableNotificationsInbox}
                    pageName='home'
                    itemName='notificationsinbox'
                    notifications={inboxMessages}
                    sentryRef={sentryRef}
                    hasNextPage={hasNextPage}
                    onDeleteMessage={onDeleteMessage}
                    onMessageClick={onMessageClick}
                />
            </InboxPopoverContent.Content>
        </InboxPopoverContent>
    );
};

export default InboxPopoverList;
