import React, {useEffect} from 'react';

import CloseButton from '../../../components/CloseButton/CloseButton';
import {StickyPageLayout} from '../../../components/Layout';
import {PopupFullPage} from '../../../components/Popup';
import {FIRMWARE_AVAILABLE_POPUP_HEADER_TITLE} from '../../../consts/localization/localizationKeys';
import {getLocalizedStrings} from '../../../services/localization/localizationService';
import modalService from '../../../services/modalService';
import ModalWarningBanners from '../../WarningBanners/ModalWarningBanners';
import FirmwareAvailableArticle from './FirmwareAvailableArticle/FirmwareAvailableArticle';
import FirmwareAvailableButtons from './FirmwareAvailableArticle/FirmwareAvailableButtons';

const FirmwareAvailablePopup = ({modalType, source}) => {
    const onClose = () => modalService.hideModal(modalType);
    const localizedStrings = getLocalizedStrings();

    useEffect(() => {
        // TODO: Add amplitude tracking
        // amplitudeInboxTracking.trackActionScreen(source);
    }, []);

    return (
        <PopupFullPage className='z-20 flex flex-col bg-romance'>
            <StickyPageLayout
                className='items-center'
                headerOptions={{
                    endIcon: <CloseButton onClick={onClose} />,
                    title: localizedStrings[FIRMWARE_AVAILABLE_POPUP_HEADER_TITLE],
                }}
                contentClassName='flex flex-1 flex-col items-center'
                footer={<FirmwareAvailableButtons onCancel={onClose} />}
            >
                <FirmwareAvailableArticle />
                <ModalWarningBanners modalType={modalType} />
            </StickyPageLayout>
        </PopupFullPage>
    );
};

export default FirmwareAvailablePopup;
