import {useGetAutoDeliveryDetailsExtended} from '../../../../api/consumerApi';
import AemEditableText from '../../../../components/Aem/AemEditableText/AemEditableText';
import {StickyHeader} from '../../../../components/Layout';
import {AUTO_DELIVERY_PAYMENT_PENDING_HEADER_TITLE} from '../../../../consts/localization/localizationKeys';
import {getLocalizedStrings} from '../../../../services/localization/localizationService';
import routerService from '../../../../services/route/routerService';
import AutoDeliveryActiveProgress from '../../../../views/AutoDelivery/AutoDeliveryActive/AutoDeliveryActiveProgress';
import AutoDeliveryPaymentPendingButton from '../../../../views/AutoDelivery/AutoDeliveryPaymentPending/AutoDeliveryPaymentPendingButton';
import AutoDeliveryStatusTitle from '../../../../views/AutoDelivery/AutoDeliveryStatusTitle';
import PageWarningBannersContainer from '../../../WarningBanners/PageWarningBannersContainer';
import AutoDeliveryDetailsFooter from '../components/AutoDeliveryDetailsFooter';
import AutoDeliveryOrder from '../components/AutoDeliveryOrder';
import AutoDeliveryOrderAddress from '../components/AutoDeliveryOrderAddress';
import AutoDeliveryOrderHeader from '../components/AutoDeliveryOrderHeader';

const StatusPaymentPendingMobile = ({amplitudeEvent}) => {
    const localizedStrings = getLocalizedStrings();
    const {
        data: {
            autoDelivery: {status},
            order: {deliveryAddress, products},
        },
    } = useGetAutoDeliveryDetailsExtended();

    return (
        <div className='w-full text-start'>
            <StickyHeader
                onBack={routerService.goBack}
                title={localizedStrings[AUTO_DELIVERY_PAYMENT_PENDING_HEADER_TITLE]}
            />
            <div className='p-4 pt-safe-offset-14'>
                <AutoDeliveryStatusTitle
                    className='text-28'
                    title={<AemEditableText pageName='auto-delivery' itemName='autoDeliveryPaymentPendingTitle' />}
                />
                <AutoDeliveryActiveProgress status={status} className='mt-8' />
                <AutoDeliveryPaymentPendingButton className='mt-8' amplitudeEvent={amplitudeEvent} />
            </div>
            <AutoDeliveryOrder className='border-t-8 border-secondary' products={products}>
                <AutoDeliveryOrderHeader />
            </AutoDeliveryOrder>
            <AutoDeliveryOrderAddress className='border-t-8 border-secondary' address={deliveryAddress} />
            <AutoDeliveryDetailsFooter className='border-t-8 border-secondary px-4 py-[34px]' />
            <PageWarningBannersContainer />
        </div>
    );
};

export default StatusPaymentPendingMobile;
