import cn from 'classnames';
import React from 'react';

import domService from '../../services/domService';
import {getLocalizedStrings} from '../../services/localization/localizationService';
import helpers from '../../utils/helpers';
import {twx} from '../../utils/tailwindUtils';
import {CheckmarkIcon} from '../Icons';
import styles from './SwitchToggle.module.scss';

const SwitchToggle = (props) => {
    const {
        onChange,
        className,
        isActive,
        testId,
        ariaLabelKey,
        labelId,
        ariaLabelActiveKey,
        isDisabled,
        onDisableClick,
        ...rest
    } = props;
    const localizedStrings = getLocalizedStrings();
    const ariaLabel = localizedStrings[isActive ? ariaLabelActiveKey : ariaLabelKey];
    const onSwitchToggleClick = () => {
        if (isDisabled) {
            onDisableClick();
            return;
        }
        helpers.runFunction(onChange, !isActive);
    };
    const onKeyPress = (e) => domService.handleCheckboxKeyPress(e, onSwitchToggleClick);

    return (
        <div
            className={twx(
                'flex items-center justify-center py-2 hover:cursor-pointer md:block md:p-0 md:pr-4',
                className
            )}
            aria-label={ariaLabel}
            aria-labelledby={labelId}
            data-testid={testId}
            onClick={onSwitchToggleClick}
            onKeyPress={onKeyPress}
            role='switch'
            aria-checked={!!isActive}
            tabIndex={isDisabled ? -1 : 0}
            {...rest}
        >
            <div className={cn(styles.Toggle, {[styles.Active]: isActive})}>
                <div className={styles.Slider}>
                    <CheckmarkIcon />
                </div>
            </div>
        </div>
    );
};

export default SwitchToggle;
