import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    tncPopupsList: [],
};

export const slice = createSlice({
    name: 'tnc',
    initialState,
    reducers: {
        setTncPopup: (state, action) => {
            const {code, className, overlayClassName} = action.payload;

            state.tncPopupsList.push({code, className, overlayClassName});

            return state;
        },
        removeLastTncPopup: (state) => {
            state.tncPopupsList = state.tncPopupsList.slice(0, -1);

            return state;
        },
    },
});

export const {setTncPopup, removeLastTncPopup} = slice.actions;

export default slice.reducer;
