import cn from 'classnames';
import React, {useState} from 'react';

import InputFieldWarning from '../../../containers/LoginPage/components/InputFieldWarning';
import {AppButton} from '../../Button';
import {InputInvisibleEyeIcon, InputVisibleEyeIcon} from '../../Icons';
import Input from '../../InputNBW/InputNBW';

export const FormInputPassword = (props) => {
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const {
        isValid,
        isEntered,
        label,
        inputClassName,
        onInputChange,
        placeholder,
        customIconClassName,
        warningNotValid,
        warningRequiredField,
        forgotPasswordLinkCode,
        onClick,
        forgotPassword,
        value,
    } = props;

    return (
        <>
            <div className='mb-2 text-16 text-primary lg:mt-6'>{label}</div>

            <div className='relative flex'>
                <Input
                    className={inputClassName}
                    onChange={onInputChange}
                    placeholder={placeholder}
                    type={isPasswordVisible ? 'text' : 'password'}
                    maxLength={50}
                    minLength={3}
                    value={value}
                />
                <PasswordVisibleToggle
                    isPasswordVisible={isPasswordVisible}
                    onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                    customIconClassName={customIconClassName}
                />
            </div>
            <div className='mt-1 min-h-2'>
                <InputFieldWarning
                    isValid={isValid}
                    isEntered={isEntered}
                    warningNotValid={warningNotValid}
                    warningRequiredField={warningRequiredField}
                />
                {forgotPasswordLinkCode && (
                    <AppButton
                        className='m-0 block p-0 text-left text-14 leading-[24px] tracking-0 text-primary'
                        onClick={onClick}
                    >
                        {forgotPassword}
                    </AppButton>
                )}
            </div>
        </>
    );
};

const PasswordVisibleToggle = ({isPasswordVisible, onClick, customIconClassName}) => {
    const iconClassName = cn(
        'absolute top-1/2 right-0 rtl:right-auto rtl:left-0 translate-x-[-50%] rtl:translate-x-[50%] translate-y-[-50%]',
        customIconClassName
    );

    return (
        <div onClick={onClick} className='cursor-pointer'>
            {isPasswordVisible ? (
                <InputInvisibleEyeIcon className={iconClassName} />
            ) : (
                <InputVisibleEyeIcon className={iconClassName} />
            )}
        </div>
    );
};
