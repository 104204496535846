import React from 'react';

import AemEditableText from '@/components/Aem/AemEditableText/AemEditableText';
import {AppButton} from '@/components/Button';
import {LocationIcon} from '@/components/Icons';
import useConfigExternalLink from '@/hooks/externalLink/useConfigExternalLink';

const ContactUsComeVisitUs = ({nearestStoreAction}) => {
    const redirectToExternalLink = useConfigExternalLink(nearestStoreAction);

    if (!nearestStoreAction) return null;

    return (
        <div className='flex w-full flex-col items-center'>
            <LocationIcon className={'mb-3 min-h-12'} />
            <h3 className='text-24'>
                <AemEditableText pageName='contact-us' itemName='comeVisitUsTitle' />
            </h3>
            <p className='mt-4'>
                <AemEditableText pageName='contact-us' itemName='comeVisitUsDesc' />
            </p>
            <div className='h-full w-full md:w-auto'>
                <AppButton
                    secondary
                    light
                    className='mx-auto mt-6 md:min-w-[312px]'
                    onClick={redirectToExternalLink}
                    isExternalLink
                >
                    <AemEditableText pageName='contact-us' itemName='comeVisitUsButton' />
                </AppButton>
            </div>
        </div>
    );
};

export default ContactUsComeVisitUs;
