import React from 'react';

import {TERMS_OF_USE, WEB_PREFERENCE_CENTER} from '../../../../consts/tnc/tncTypes';
import TncDetailsPreferenceCenter from './components/TncDetailsPreferenceCenter';
import TncDetailsTermsOfUse from './components/TncDetailsTermsOfUse';

const getContentComponent = (pageType) => {
    switch (pageType) {
        case WEB_PREFERENCE_CENTER:
            return TncDetailsPreferenceCenter;
        case TERMS_OF_USE:
        default:
            return TncDetailsTermsOfUse;
    }
};

const TncDetailsContent = ({pageType, onMandatoryTermsAccept}) => {
    const Component = getContentComponent(pageType);

    return <Component onMandatoryTermsAccept={onMandatoryTermsAccept} />;
};

export default TncDetailsContent;
