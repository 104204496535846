import useInfiniteScroll from 'react-infinite-scroll-hook';

import inboxMessageService from '../../containers/Inbox/InboxView/inboxMessageService';
import useInboxMessages from './useInboxMessages';

const useInboxListView = () => {
    const {inboxMessages, loadMore, isLoading, hasNextPage} = useInboxMessages();

    const onDeleteMessage = (messageId) => {
        inboxMessageService.deleteInboxMessage(messageId);
    };

    const [sentryRef] = useInfiniteScroll({
        loading: isLoading,
        hasNextPage,
        onLoadMore: loadMore,
    });

    return {inboxMessages, sentryRef, hasNextPage, onDeleteMessage};
};

export default useInboxListView;
