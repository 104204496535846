import React from 'react';

import AemComponent from '../../../components/Aem/AemComponent/AemComponent';
import AemEditableText from '../../../components/Aem/AemEditableText/AemEditableText';
import EditableNotificationsInbox from '../../../components/Aem/EditableNotificationsInbox/EditableNotificationsInbox';
import {StickyHeader} from '../../../components/Layout';
import {INBOX_PAGE_HEADER_TITLE} from '../../../consts/localization/localizationKeys';
import useDocumentTitle from '../../../hooks/useDocumentTitle';
import {HOME_PAGE_NAVIGATION_SOURCE_TYPES} from '../../../services/homePageNavigationService';
import {getLocalizedStrings} from '../../../services/localization/localizationService';
import appRouterService from '../../../services/route/appRouterService';

const InboxView = ({notifications, sentryRef, hasNextPage, onCloseClick, onDeleteMessage}) => {
    const localizedStrings = getLocalizedStrings();

    const onMessageClick = (messageId) => {
        appRouterService.forwardToNotificationDetailsPage(messageId, HOME_PAGE_NAVIGATION_SOURCE_TYPES.INBOX);
    };

    useDocumentTitle(localizedStrings[INBOX_PAGE_HEADER_TITLE]);

    return (
        <div className='px-layout pb-2 text-start'>
            <StickyHeader title={localizedStrings[INBOX_PAGE_HEADER_TITLE]} onBack={onCloseClick} />
            <h2 className='text-32 mt-safe-offset-14'>
                <AemEditableText pageName='home' itemName='notificationsinboxtitle' />
            </h2>
            <AemComponent
                component={EditableNotificationsInbox}
                pageName='home'
                itemName='notificationsinbox'
                notifications={notifications}
                sentryRef={sentryRef}
                hasNextPage={hasNextPage}
                onDeleteMessage={onDeleteMessage}
                onMessageClick={onMessageClick}
            />
        </div>
    );
};

export default InboxView;
