import {useSelector} from 'react-redux';

import appConfig from '@/config/appConfig';
import {filterSectionsById} from '@/services/pageSectionService';
import {
    selectIsAnyConsumerProducts,
    selectIsConsumerProductsFetched,
    selectIsLoyaltyMember,
    selectLoyaltyPoints,
} from '@/state/selectors/consumer';

import useLoyaltyButtonsBar from './useLoyaltyButtonsBar';

const useTopBanner = ({id}) => {
    const banners = appConfig.getTopBanners();
    const topBannerData = filterSectionsById({list: banners, id})[0];
    const isLoyaltyEnabled = appConfig.getIsLoyaltyEnabled();
    const isLoyaltyApiEnabled = appConfig.getIsLoyaltyApiEnabled();
    const isLoyaltyUser = useSelector(selectIsLoyaltyMember);
    const loyaltyPoints = useSelector(selectLoyaltyPoints);
    const isConsumerProductsFetched = useSelector(selectIsConsumerProductsFetched);
    const isAnyConsumerProducts = useSelector(selectIsAnyConsumerProducts);

    const {loyaltyButtonsBar, topBanner, loyaltyTopBanner, noLoyaltyTopBanner, loyaltyNoPointsTopBanner} =
        topBannerData || {};

    const loyaltyButtons = useLoyaltyButtonsBar({loyaltyButtonsBar});

    if (topBanner && !loyaltyNoPointsTopBanner && !loyaltyTopBanner && !noLoyaltyTopBanner) return {topBanner};

    if (!isLoyaltyEnabled || !isLoyaltyApiEnabled) return {topBanner};

    if (!isLoyaltyUser) return {topBanner: noLoyaltyTopBanner};

    if (!isConsumerProductsFetched) return {};

    if (loyaltyPoints > 0 && isAnyConsumerProducts)
        return {
            topBanner: loyaltyTopBanner,
            isLoyaltyBanner: true,
            loyaltyButtons,
        };

    return {topBanner: loyaltyNoPointsTopBanner};
};

export default useTopBanner;
