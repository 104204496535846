import React from 'react';

import {AppButton} from '../../../components/Button';
import RemoteIcon from '../../../components/Icon/RemoteIcon';
import PopupSticked from '../../../components/Popup/PopupSticked';
import appConfig from '../../../config/appConfig';
import * as localizationKeys from '../../../consts/localization/localizationKeys';
import {getLocalizedStrings} from '../../../services/localization/localizationService';

const MaintenancePopup = () => {
    const localizedStrings = getLocalizedStrings();
    const {titleKey, descriptionKey, imagePath} = appConfig.getMaintenancePopupIcon();

    const onClick = () => {
        window.location.reload();
    };

    return (
        <PopupSticked className='flex flex-col px-4 py-8 md:max-w-[458px] md:px-12 md:pt-16'>
            <div className='mx-auto h-12 w-12'>
                <RemoteIcon imagePath={imagePath} />
            </div>
            <h2 className='mb-0 mt-4 text-24'>{localizedStrings[titleKey]}</h2>
            <p className='mt-4 text-start'>{localizedStrings[descriptionKey]}</p>
            <AppButton primary light className='mt-8 md:mt-16' onClick={onClick}>
                {localizedStrings[localizationKeys.MAINTENANCE_POPUP_BUTTON]}
            </AppButton>
        </PopupSticked>
    );
};

export default MaintenancePopup;
