import cn from 'classnames';
import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';

import AemEditableText from '../../components/Aem/AemEditableText/AemEditableText';
import {AppButton} from '../../components/Button';
import DeviceHero from '../../components/DeviceHero/DeviceHero';
import {MaxWidthContainer, StickyPageLayout} from '../../components/Layout';
import {ABOUT_YOUR_DEVICE_TITLE} from '../../consts/localization/localizationKeys';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import deviceTypesService from '../../services/device/deviceTypesService';
import {getLocalizedStrings} from '../../services/localization/localizationService';
import productHelpers from '../../services/product/productHelpers';
import routerService from '../../services/route/routerService';
import uamClientService from '../../services/uam/uamClientService';
import {selectCurrentProductInfo, selectDeviceData, selectHolderData} from '../../state/selectors/device';
import useOnConnectClick from '../DevicesPage/hooks/useOnConnectClick';
import PageWarningBannersContainer from '../WarningBanners/PageWarningBannersContainer';
import DeviceInfoBlock from './components/DeviceInfoBlock';
import FirmwareVersionWarning from './components/FirmwareVersionWarning';

const AboutDevicePage = () => {
    const localizedStrings = getLocalizedStrings();
    const device = useSelector(selectCurrentProductInfo);
    const deviceData = useSelector(selectDeviceData) || {};
    const holderData = useSelector(selectHolderData) || {};
    const {codentify, holder, defaultHolderType, type} = device || {};
    const holderNode = holder || (defaultHolderType ? {type: defaultHolderType, isHolder: true} : undefined);
    const deviceName = productHelpers.getProductName(device);
    const holderDefaultData = deviceTypesService.getDeviceTypesDataByType(holderNode?.type);

    const [loading, setLoading] = useState(false);
    const [isChargerError, setIsChargerError] = useState(false);
    const [isHolderError, setIsHolderError] = useState(false);
    const {onConnectClick} = useOnConnectClick(type);

    useDocumentTitle(localizedStrings[ABOUT_YOUR_DEVICE_TITLE]);

    useEffect(() => {
        const getUamInfo = async () => {
            setLoading(true);

            const promises = [
                uamClientService.getUamInfo({
                    codentify,
                    onError: () => {
                        setIsChargerError(true);
                    },
                }),
            ];

            if (holder) {
                promises.push(
                    uamClientService.getUamInfo({
                        codentify: holder?.codentify,
                        isHolder: true,
                        onError: () => {
                            setIsHolderError(true);
                        },
                    })
                );
            } else {
                setIsHolderError(true);
            }

            await Promise.all(promises);
            setLoading(false);
        };

        getUamInfo();
    }, []);

    return (
        <StickyPageLayout
            className='items-center'
            contentClassName='flex flex-1 flex-col items-center'
            headerOptions={{
                onBack: routerService.goBack,
                title: localizedStrings[ABOUT_YOUR_DEVICE_TITLE],
            }}
            footer={
                <AppButton className='m-auto' primary light onClick={onConnectClick}>
                    <AemEditableText pageName='about-device' itemName='connectDeviceButton' />
                </AppButton>
            }
        >
            <DeviceHero containerClassName='w-full mb-8' />

            <MaxWidthContainer className='mx-auto w-full px-4'>
                <h4 className='mb-0 flex flex-col leading-[36px]'>
                    <span>
                        <AemEditableText pageName='about-device' itemName='aboutYour' />
                    </span>
                    <span>{deviceName}</span>
                </h4>

                <FirmwareVersionWarning className='mt-4' />

                <div className={cn('mt-4 w-full text-start')}>
                    <DeviceInfoBlock
                        modelLabelKey='chargerModel'
                        codentifyLabelKey='chargerCodentify'
                        firmwareLabelKey='chargerFirmware'
                        node={device}
                        nodeData={isChargerError ? undefined : deviceData}
                        loading={loading}
                    />
                    {holderNode && (
                        <DeviceInfoBlock
                            modelLabelKey='holderModel'
                            codentifyLabelKey='holderCodentify'
                            firmwareLabelKey='holderFirmware'
                            node={holderNode}
                            nodeData={isHolderError ? undefined : holderData}
                            holderDefaultData={holderDefaultData}
                            loading={loading}
                        />
                    )}
                </div>
            </MaxWidthContainer>
            <PageWarningBannersContainer />
        </StickyPageLayout>
    );
};

export default AboutDevicePage;
