import appConfig from '@/config/appConfig';
import arrayUtils from '@/utils/arrayUtils';

const useLoyaltyButtonsBar = ({loyaltyButtonsBar}) => {
    const isLoyaltyEnabled = appConfig.getIsLoyaltyEnabled();
    const isLoyaltyApiEnabled = appConfig.getIsLoyaltyApiEnabled();
    const isLoyaltyButtonsBarEnabled = appConfig.getIsLoyaltyButtonsBarEnabled();

    if (!isLoyaltyEnabled || !isLoyaltyApiEnabled || !isLoyaltyButtonsBarEnabled) return null;

    const iconsWithAction = loyaltyButtonsBar?.filter((i) => i?.action);

    if (!iconsWithAction?.length) return null;

    const iconsWithOrder = arrayUtils.sortArrayWithOrderField(iconsWithAction.filter((i) => i?.order !== undefined));
    const iconsWithoutOrder = iconsWithAction.filter((i) => i?.order === undefined);

    return [...iconsWithOrder, ...iconsWithoutOrder].slice(0, 3);
};

export default useLoyaltyButtonsBar;
