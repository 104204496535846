import React from 'react';

import setUpIqos from '@/../assets/images/devices/setUpYourIqos/set-up-iqos.png';
import {ContentWrapper, MaxWidthContainer} from '@/components/Layout';
import appConfig from '@/config/appConfig';
import {NO_DEVICES_PAGE_HEADER_TITLE} from '@/consts/localization/localizationKeys';
import HomePageTopNavigation from '@/containers/HomePageTopNavigation/HomePageTopNavigation';
import SignedImage from '@/containers/System/SignedImage';
import useDocumentTitle from '@/hooks/useDocumentTitle';
import {HOME_PAGE_NAVIGATION_SOURCE_TYPES} from '@/services/homePageNavigationService';
import {getLocalizedStrings} from '@/services/localization/localizationService';

import DevicesPageButtons from './DevicesPageButtons';
import DevicesPageHeader from './DevicesPageHeader';

const NoDevicesView = () => {
    const localizedStrings = getLocalizedStrings();
    const imagePath = appConfig.getSetUpYourIqosImagePath();
    const imagePathCombined = appConfig.getCombinedPathAssetPath(imagePath);

    useDocumentTitle(localizedStrings[NO_DEVICES_PAGE_HEADER_TITLE]);

    return (
        <div className='flex h-screen w-full flex-1 flex-col pb-[79px] lg:pb-0'>
            <ContentWrapper className='mx-4 h-full flex-1 items-start pb-6'>
                <MaxWidthContainer className='mx-auto w-full flex-1' size='l'>
                    <HomePageTopNavigation
                        title={localizedStrings[NO_DEVICES_PAGE_HEADER_TITLE]}
                        source={HOME_PAGE_NAVIGATION_SOURCE_TYPES.DEVICES}
                        profileControlsClassName='bg-romance'
                    />
                    <DevicesPageHeader />
                    <div className='relative min-h-[200px] w-full flex-1'>
                        <SignedImage
                            imgClassName='m-auto absolute left-0 right-0 h-full max-w-[327px] object-contain md:m-0 md:left-1/2 md:right-none md:-translate-x-1/2 md:max-w-none'
                            mediaData={imagePathCombined}
                            defaultImage={setUpIqos}
                            signIfNull
                        />
                    </div>
                    <DevicesPageButtons />
                </MaxWidthContainer>
            </ContentWrapper>
        </div>
    );
};

export default NoDevicesView;
