import React from 'react';

import {ButtonRaw} from '../../../components/Button';
import {AlertIcon} from '../../../components/Icons';

const InboxIconComponent = ({onClick, isUnreadExists}) => {
    return (
        <ButtonRaw onClick={onClick} className='relative'>
            <AlertIcon className='h-6 w-6' />
            {isUnreadExists && <div className='absolute right-0 top-0 h-1.5 w-1.5 rounded-full bg-bright-sun' />}
        </ButtonRaw>
    );
};

export default InboxIconComponent;
