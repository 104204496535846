import React, {useState} from 'react';
import {useDispatch} from 'react-redux';

import CloseButton from '../../../components/CloseButton/CloseButton';
import {ErrorIcon, SuccessIcon, WarningIcon} from '../../../components/Icons';
import * as notificationTypes from '../../../consts/app/notificationTypes';
import {removeNotification} from '../../../state/slices/notificationsSlice';
import helpers from '../../../utils/helpers';
import {twx} from '../../../utils/tailwindUtils';
import useHideNotification from './useHideNotification';

const NotificationTop = (props) => {
    const {notification, onClose} = props;
    const {isInline, notificationId, text, type, testId, autoFocus} = notification;
    const [isActive, setIsActive] = useState(false);
    const dispatch = useDispatch();
    let icon;
    const iconStyles = 'w-6 h-6 shrink-0';
    const DISPLAY_TIME = 5000;

    switch (type) {
        case notificationTypes.FAILED:
            icon = <ErrorIcon className={iconStyles} />;
            break;

        case notificationTypes.SUCCESS:
            icon = <SuccessIcon className={iconStyles} />;
            break;

        case notificationTypes.INFO:
            icon = <WarningIcon className={iconStyles} />;
            break;

        case notificationTypes.CUSTOM:
            icon = null;
            break;

        default:
            break;
    }

    const onNotificationClick = () => {
        if (!isActive) {
            setIsActive(true);

            setTimeout(() => {
                hideNotification();
                helpers.runFunction(onClose);
            }, 500);
        }
    };

    const hideNotification = () => {
        dispatch(removeNotification(notificationId));
    };

    useHideNotification({
        displayTime: DISPLAY_TIME,
        hideNotification,
    });

    return (
        <div
            className={twx('relative flex w-full items-center gap-4 rounded bg-secondary py-5 pl-6 pr-11 md:gap-6', {
                'animate-hide': isActive,
                'rounded-xl py-4 pr-12': isInline,
            })}
            data-testid={testId}
        >
            {icon}
            <div
                className='leading-14_3 flex-1 text-start text-14 tracking-04 text-primary'
                role={autoFocus ? '' : 'status'}
            >
                {text}
            </div>
            <CloseButton onClick={onNotificationClick} className='absolute right-2 top-2' size='small' />
        </div>
    );
};

export default NotificationTop;
