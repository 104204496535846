import React from 'react';

import {AppButton} from '@/components/Button';
import AppImage from '@/components/Image/AppImage';
import GuideVideo from '@/components/Video/GuideVideo/GuideVideo';
import queryParameters from '@/consts/route/queryParameters';
import * as sourceTypes from '@/consts/sourceTypes';
import useConfigExternalLink from '@/hooks/externalLink/useConfigExternalLink';
import amplitudeInboxTracking from '@/services/amplitude/amplitudeInboxTracking';
import urlUtils from '@/utils/urlUtils';

import NotificationDetailsNewFeatures from './NotificationDetailsNewFeatures';

const NotificationDetailsMessage = (props) => {
    const {
        className,
        subTitle,
        title,
        campaignName,
        detailedText,
        detailedImage,
        detailedVideo,
        newFeatures,
        newFeaturesTitle,
        actionButton,
    } = props;
    const {actionLink, buttonLabel, linkType} = actionButton || {};
    const isShowButton = actionLink && buttonLabel && linkType;
    const isShowFeatures = !!newFeatures?.length;

    const redirectToExternalLink = useConfigExternalLink({
        url: actionLink,
        ssoTemplate: actionLink,
        linkType,
        source: sourceTypes.INBOX,
    });

    const onClick = () => {
        const [source] = urlUtils.getDataFromUrl(queryParameters.SOURCE);

        amplitudeInboxTracking.trackInboxMessageButtonClicked(source, campaignName);
        redirectToExternalLink();
    };

    return (
        <div className={className}>
            <h3 className='primary-tins-slate text-20 text-jumbo lg:text-14'>{subTitle}</h3>
            <h1 className='mb-8 text-32 leading-[40px] lg:mb-4 lg:text-18 lg:leading-15'>{title}</h1>

            {detailedImage && (
                <AppImage src={detailedImage} className='mb-4 w-full shrink-0 rounded-[12px] object-cover lg:mb-2' />
            )}

            {detailedVideo && (
                <GuideVideo src={detailedVideo} containerClassName='w-full h-full mb-4 lg:mb-2' className='w-full' />
            )}

            {detailedText && <div className='whitespace-pre-line' dangerouslySetInnerHTML={{__html: detailedText}} />}

            {isShowFeatures && (
                <NotificationDetailsNewFeatures newFeatures={newFeatures} newFeaturesTitle={newFeaturesTitle} />
            )}

            {isShowButton && (
                <AppButton
                    onClick={onClick}
                    primary
                    light
                    isTargetBlank
                    isExternalLink
                    className='my-8 min-h-12 border lg:mb-0 lg:mt-4'
                >
                    {buttonLabel}
                </AppButton>
            )}
        </div>
    );
};

export default NotificationDetailsMessage;
