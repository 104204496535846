import useLocationPathname from '@/hooks/useLocationPathname';

import appConfig from '../../../config/appConfig';

const useGetPageWarningBanners = () => {
    const {pathname} = useLocationPathname();
    const warningBanners = appConfig.getWarningBanners();

    const result = warningBanners?.filter((b) => b.pages?.some((page) => pathname.startsWith(page)));

    return result;
};

export default useGetPageWarningBanners;
