import {useSelector} from 'react-redux';

import {selectIsDesktopLayout} from '../state/selectors/global';

const useIsDesktopLayout = () => {
    const isDesktopLayout = useSelector(selectIsDesktopLayout);

    return isDesktopLayout;
};

export default useIsDesktopLayout;
