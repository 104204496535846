import React from 'react';
import {useSelector} from 'react-redux';

import {useGetAutoDeliveryDetails} from '@/api/consumerApi';
import AMPLITUDE_EVENT_VALUES from '@/consts/amplitude/amplitudeEventValues';
import {AUTO_DELIVERY_VIEW_ORDER_LINK} from '@/consts/localization/localizationKeys';
import amplitudeAutodeliveryTracking from '@/services/amplitude/amplitudeAutoDeliveryTracking';
import {selectIsMobileLayout} from '@/state/selectors/global';
import AutoDeliveryActiveProgress from '@/views/AutoDelivery/AutoDeliveryActive/AutoDeliveryActiveProgress';
import AutoDeliveryPaymentPendingButton from '@/views/AutoDelivery/AutoDeliveryPaymentPending/AutoDeliveryPaymentPendingButton';
import AutoDeliveryPaymentPendingTitle from '@/views/AutoDelivery/AutoDeliveryPaymentPending/AutoDeliveryPaymentPendingTitle';

import AutoDeliveryWidgetContainer from './components/AutoDeliveryWidgetContainer';
import AutoDeliveryWidgetHeader from './components/AutoDeliveryWidgetHeader';

const AutoDeliveryStatusPaymentPending = ({className}) => {
    const isMobileLayout = useSelector(selectIsMobileLayout);
    const {
        data: {
            autoDelivery: {status},
        },
    } = useGetAutoDeliveryDetails();

    const amplitudeEvent = () => {
        amplitudeAutodeliveryTracking.trackAutoDeliveryDashboardButtonClick(AMPLITUDE_EVENT_VALUES.CHECK_ORDER_STATUS);
    };

    return (
        <AutoDeliveryWidgetContainer className={className}>
            <AutoDeliveryWidgetHeader btnLocalizationKey={AUTO_DELIVERY_VIEW_ORDER_LINK} />
            <AutoDeliveryWidgetContainer.ContentContainer>
                <div className='md:flex md:items-center md:justify-between'>
                    <AutoDeliveryPaymentPendingTitle className='mt-6 md:mt-0' />
                    {!isMobileLayout && (
                        <AutoDeliveryPaymentPendingButton amplitudeEvent={amplitudeEvent} className='mt-0 w-auto' />
                    )}
                </div>
                <AutoDeliveryActiveProgress status={status} className='mt-4 md:mt-10' />
                {isMobileLayout && (
                    <AutoDeliveryPaymentPendingButton amplitudeEvent={amplitudeEvent} className='mt-8' />
                )}
            </AutoDeliveryWidgetContainer.ContentContainer>
        </AutoDeliveryWidgetContainer>
    );
};

export default AutoDeliveryStatusPaymentPending;
