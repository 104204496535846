import {useState} from 'react';
import {useSelector} from 'react-redux';

import inboxMessageService from '../../containers/Inbox/InboxView/inboxMessageService';
import appErrorService from '../../services/app/appErrorService';
import {selectInboxMessagesNextPageStartKey, selectInboxMessagesSorted} from '../../state/selectors/inbox';

const useInboxMessages = () => {
    const [isLoading, setIsLoading] = useState();
    const [isError, setIsError] = useState(false);
    const inboxMessages = useSelector(selectInboxMessagesSorted);
    const nextPageStartKey = useSelector(selectInboxMessagesNextPageStartKey);
    const hasNextPage = !isError && (nextPageStartKey || inboxMessages === undefined);

    const loadMore = async () => {
        setIsLoading(true);

        try {
            await inboxMessageService.fetchInboxMessages({nextPageStartKey});
        } catch (e) {
            appErrorService.showGlobalError();
            setIsError(true);
        }

        setIsLoading(false);
    };

    return {inboxMessages, loadMore, isLoading, hasNextPage};
};

export default useInboxMessages;
