import React from 'react';

import useGreeting from '@/views/Greeting/useGreeting';

const GreetingAsSeparateSection = () => {
    const {title, description} = useGreeting();

    return (
        <article className='bg-romance px-4 pb-4 mt-safe-offset-14 md:mt-24 md:bg-transparent md:px-16'>
            <h1 className='mb-2 text-32'>{title}</h1>
            <p>{description}</p>
        </article>
    );
};

export default GreetingAsSeparateSection;
