import cn from 'classnames';
import React from 'react';
import {SwiperSlide} from 'swiper/react';

import AemComponent from '@/components/Aem/AemComponent/AemComponent';
import EditableText from '@/components/Aem/EditableText/EditableText';
import SwiperCarousel from '@/components/Swiper/SwiperCarousel';
import SwiperNavigationButton from '@/components/Swiper/SwiperNavigationButton';

import styles from './HomePageSwiperCarousel.module.scss';
import useGetForYouSectionList from './hooks/useGetForYouSectionList';
import useSwiperNavigation from './useSwiperNavigation';

const HomePageForYouSection = ({id}) => {
    const forYouSectionList = useGetForYouSectionList({id});
    const {prevRef, nextRef, isBeginning, isEnd, swiperOptions, isNavigationEnabled} = useSwiperNavigation();

    if (!forYouSectionList?.length) return null;

    return (
        <div id={id} className='bg-romance px-4 pb-8 pt-8 md:bg-transparent md:px-16'>
            <div className='mb-4 text-start md:mb-8 md:flex md:justify-between'>
                <h1 className='mb-0 text-24 md:text-32'>
                    <AemComponent component={EditableText} pageName='home' itemName='foryoutitle' />
                </h1>
                {isNavigationEnabled && (
                    <div className='flex shrink-0 gap-4'>
                        <SwiperNavigationButton ref={prevRef} disabled={isBeginning} className='rotate-180' />
                        <SwiperNavigationButton ref={nextRef} disabled={isEnd} />
                    </div>
                )}
            </div>
            <div className={cn(styles.HomePageSwiperCarousel, 'isolate')}>
                <SwiperCarousel options={swiperOptions}>
                    {forYouSectionList.map((slide, index) => (
                        <SwiperSlide key={index}>{slide}</SwiperSlide>
                    ))}
                </SwiperCarousel>
            </div>
        </div>
    );
};

export default HomePageForYouSection;
