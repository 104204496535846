import {useSelector} from 'react-redux';

import appConfig from '../../../config/appConfig';
import appErrorService from '../../../services/app/appErrorService';
import iccMarketService from '../../../services/icc/iccMarketService';
import languageDataService from '../../../services/localization/languageDataService';
import {initLocalizations} from '../../../services/localization/localizationService';
import marketService from '../../../services/marketService';
import appRouterService from '../../../services/route/appRouterService';
import {selectIsLoggedIn} from '../../../state/selectors/auth';
import {selectIsAem, selectLanguageCode} from '../../../state/selectors/global';

const useLanguageSelect = () => {
    const marketLanguages = appConfig.getMarketLanguages();

    const languageCode = useSelector(selectLanguageCode);
    const languageName = getLanguageName(marketLanguages, languageCode);
    const selectListData = getSelectListData(marketLanguages);
    const handleLanguageChange = useHandleLanguageChange();

    return {languageCode, languageName, selectListData, handleLanguageChange};
};

const getLanguageName = (marketLanguages, languageCode) => {
    const marketLanguage = languageDataService.getMarketLanguageByLanguageCode(marketLanguages, languageCode);
    const {languageTitle} = marketLanguage || {};
    const languageName = languageTitle || '';

    return languageName;
};

const getSelectListData = (marketLanguages) => {
    return marketLanguages.map(({languageTitle, languageCode}) => ({
        title: languageTitle,
        value: languageCode,
    }));
};

const useHandleLanguageChange = () => {
    const isLoggedIn = useSelector(selectIsLoggedIn);
    const isAem = useSelector(selectIsAem);

    const handleLanguageChange = async (languageCode) => {
        try {
            if (isLoggedIn) {
                await iccMarketService.setConsumerLanguageCode({languageCode, throwException: true});
            }

            await initLocalizations(marketService.getMarketCode(), languageCode);

            if (isAem) {
                appRouterService.forwardToRootPage();
            }
        } catch (e) {
            appErrorService.showGlobalError();
        }
    };

    return handleLanguageChange;
};

export default useLanguageSelect;
