import React from 'react';

import {AppButton} from '../../../../components/Button';
import {FirmwareUpdateIcon} from '../../../../components/Icons';
import PopupSticked from '../../../../components/Popup/PopupSticked';
import * as localizationKeys from '../../../../consts/localization/localizationKeys';
import {getLocalizedStrings} from '../../../../services/localization/localizationService';
import useAppUpdate from './useAppUpdate';

const AppUpdatePopup = () => {
    const isNewVersionAvailable = useAppUpdate();

    if (!isNewVersionAvailable) return null;

    const localizedStrings = getLocalizedStrings();

    const onClick = () => {
        if (navigator.serviceWorker.controller) {
            navigator.serviceWorker.controller.postMessage({type: 'SKIP_WAITING'});
        }
        window.location.reload();
    };

    return (
        <PopupSticked className='flex flex-col px-4 py-8 md:max-w-[458px] md:px-12 md:pt-16'>
            <FirmwareUpdateIcon className='mx-auto h-16 w-16' />
            <h2 className='mb-0 mt-4 text-24'>{localizedStrings[localizationKeys.APP_UPDATE_POPUP_TITLE]}</h2>
            <p className='mt-4 text-start'>{localizedStrings[localizationKeys.APP_UPDATE_POPUP_DESCRIPTION]}</p>
            <AppButton primary light className='mt-8 md:mt-16' onClick={onClick}>
                {localizedStrings[localizationKeys.APP_UPDATE_POPUP_BUTTON]}
            </AppButton>
        </PopupSticked>
    );
};

export default AppUpdatePopup;
