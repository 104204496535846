import {useGetAutoDeliveryDetailsExtended} from '../../../../api/consumerApi';
import AemEditableText from '../../../../components/Aem/AemEditableText/AemEditableText';
import {AppButton} from '../../../../components/Button';
import appRouterService from '../../../../services/route/appRouterService';
import AutoDeliveryActiveProgress from '../../../../views/AutoDelivery/AutoDeliveryActive/AutoDeliveryActiveProgress';
import AutoDeliveryActiveSubtitle from '../../../../views/AutoDelivery/AutoDeliveryActive/AutoDeliveryActiveSubtitle';
import AutoDeliveryActiveTitle from '../../../../views/AutoDelivery/AutoDeliveryActive/AutoDeliveryActiveTitle';
import AutoDeliveryDetailsFooter from '../components/AutoDeliveryDetailsFooter';
import AutoDeliveryOrder from '../components/AutoDeliveryOrder';
import AutoDeliveryOrderAddress from '../components/AutoDeliveryOrderAddress';
import AutoDeliveryOrderHeader from '../components/AutoDeliveryOrderHeader';
import AutoDeliveryOrderSummary from '../components/AutoDeliveryOrderSummary';
import AutoDeliveryPageDesktopLayout from '../components/AutoDeliveryPageDesktopLayout';

const StatusActiveDesktop = () => {
    const {
        data: {
            autoDelivery: {minDeliveryDaysCount, maxDeliveryDaysCount, nextDeliveryDate, maxNextDeliveryDate, status},
            order: {status: orderStatus, price, deliveryAddress, products},
        },
    } = useGetAutoDeliveryDetailsExtended();

    return (
        <AutoDeliveryPageDesktopLayout>
            <AutoDeliveryPageDesktopLayout.Start>
                <AutoDeliveryOrderHeader isEditAllowed />
                <AutoDeliveryActiveSubtitle
                    minDeliveryDaysCount={minDeliveryDaysCount}
                    maxDeliveryDaysCount={maxDeliveryDaysCount}
                    className='mt-8'
                />
                <AutoDeliveryActiveTitle
                    nextDeliveryDate={nextDeliveryDate}
                    maxNextDeliveryDate={maxNextDeliveryDate}
                    className='mt-2'
                />
                <AutoDeliveryActiveProgress status={status} orderStatus={orderStatus} className='mt-8' />
                <AutoDeliveryOrder className='mt-8 p-0' products={products} />
                <AutoDeliveryDetailsFooter>
                    <div className='flex gap-4'>
                        <AppButton
                            primary
                            light
                            className='mt-0 min-w-[180px]'
                            onClick={appRouterService.forwardToAutoDeliveryAsapPage}
                        >
                            <AemEditableText pageName='auto-delivery' itemName='deliverAsapButton' />
                        </AppButton>
                        <AppButton
                            tertiary
                            className='self-center'
                            onClick={appRouterService.forwardToAutoDeliverySkipPage}
                        >
                            <AemEditableText pageName='auto-delivery' itemName='skipDeliveryButton' />
                        </AppButton>
                    </div>
                </AutoDeliveryDetailsFooter>
            </AutoDeliveryPageDesktopLayout.Start>
            <AutoDeliveryPageDesktopLayout.End>
                <AutoDeliveryOrderSummary className='p-0 pb-4' price={price} />
                <AutoDeliveryOrderAddress
                    className='border-t border-secondary p-0 pt-4'
                    address={deliveryAddress}
                    isEditAllowed
                />
            </AutoDeliveryPageDesktopLayout.End>
        </AutoDeliveryPageDesktopLayout>
    );
};

export default StatusActiveDesktop;
