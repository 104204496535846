import pushNotificationService from '../../../services/pushNotificationService';
import storageService from '../../../services/storage/storageService';
import {showEnableNotificationModal} from '../../../state/slices/modalSlice';
import {dispatch} from '../../../state/store';

const useShowEnableNotificationModal = async () => {
    const isPermissionDenied = storageService.getPermissionDeniedLocalStorage();

    const shouldNotificationScreenBeDisplayed =
        await pushNotificationService.checkIfNotificationScreenShouldBeDisplayed();

    if (isPermissionDenied || !shouldNotificationScreenBeDisplayed) {
        return;
    }

    dispatch(showEnableNotificationModal());
};

export default useShowEnableNotificationModal;
