import {Children} from 'react';

const filterChildrenByType = (children, types) => {
    const matched = [];

    types.forEach((type) => {
        let matchedChild = undefined;

        Children.forEach(children, (child) => {
            if (child?.type === type) {
                matchedChild = child;
                return;
            }
        });
        matched.push(matchedChild);
    });

    return matched;
};

const getOrCreateComponent = ({component, parameters}) => {
    return typeof component === 'function' ? component(parameters) : component;
};

export default {
    filterChildrenByType,
    getOrCreateComponent,
};
