import uaUtils from '@/utils/uaUtils';

import appConfig from '../config/appConfig';
import userBrowserService from './user/userBrowserService';

const getDeviceData = (type) => {
    const deviceSupport = appConfig.getDeviceSupport();

    return deviceSupport.find((x) => x.deviceType === type);
};

const isDeviceSupported = (type) => {
    return !!getDeviceData(type);
};

const deviceOrder = (type) => {
    return getDeviceData(type)?.order;
};

const isInstallPwaEnabled = () => {
    const currentGetStartedPage = getGetStartedPage();

    return !!currentGetStartedPage && !userBrowserService.isInStandaloneMode();
};

const getGetStartedPage = () => {
    const getStartedPage = appConfig.getGetStartedPage();
    const currentPlatform = getPlatformName();

    return getStartedPage?.find((i) => i.page?.platform?.name === currentPlatform);
};

const getPlatformName = () => {
    const CHROME_NAME = 'Chrome';
    const EDGE_NAME = 'Edge';
    const SAFARI_NAME = 'Safari';
    const IOS_SAFARI_NAME = 'Ios Safari';
    const IOS_CHROME_NAME = 'Ios Chrome';
    const BLUEFY_NAME = 'Bluefy';
    const DEFAULT = 'Default';
    const isMobile = uaUtils.isMobile();

    if (userBrowserService.isBluefyBrowser()) {
        return BLUEFY_NAME;
    }

    if (userBrowserService.isIosSafariBrowser()) {
        return IOS_SAFARI_NAME;
    }

    if (userBrowserService.isIosChromeBrowser()) {
        return IOS_CHROME_NAME;
    }

    if (isMobile) return DEFAULT;

    if (userBrowserService.isChromeBrowser()) {
        return CHROME_NAME;
    }

    if (userBrowserService.isEdgeBrowser()) {
        return EDGE_NAME;
    }

    if (userBrowserService.isSafariBrowser()) {
        return SAFARI_NAME;
    }

    return DEFAULT;
};

export default {
    isDeviceSupported,
    deviceOrder,
    getDeviceData,
    getGetStartedPage,
    isInstallPwaEnabled,
};
