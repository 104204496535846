import cn from 'classnames';
import React from 'react';

import {getAutoDeliveryOnHoldContent} from './autoDeliveryOnHoldService';

const AutoDeliveryOnHoldSubtitle = ({className, statusUpdateReason}) => {
    const {title} = getAutoDeliveryOnHoldContent(statusUpdateReason);

    return <div className={cn('text-jumbo md:text-20 md:font-bold', className)}>{title}</div>;
};

export default AutoDeliveryOnHoldSubtitle;
