import React from 'react';
import {useDispatch, useSelector} from 'react-redux';

import InfoBox from '@/components/InfoBox/InfoBox';
import {
    AUTO_DELIVERY_INACTIVE_DESCRIPTION,
    AUTO_DELIVERY_INACTIVE_TITLE,
    AUTO_DELIVERY_LEARN_MORE_LINK,
} from '@/consts/localization/localizationKeys';
import {getLocalizedStrings} from '@/services/localization/localizationService';
import {selectIsMobileLayout} from '@/state/selectors/global';
import {showAutoDeliveryStatusInactiveDetailsPopup} from '@/state/slices/modalSlice';
import AutoDeliveryInactiveButton from '@/views/AutoDelivery/AutoDeliveryInactive/AutoDeliveryInactiveButton';

import AutoDeliveryWidgetContainer from '../components/AutoDeliveryWidgetContainer';
import AutoDeliveryWidgetHeader from '../components/AutoDeliveryWidgetHeader';
import AutoDeliveryStatusInactiveBenefits from './AutoDeliveryStatusInactiveBenefits';

const AutoDeliveryStatusInactive = ({className}) => {
    const dispatch = useDispatch();
    const localizedStrings = getLocalizedStrings();
    const title = localizedStrings[AUTO_DELIVERY_INACTIVE_TITLE];
    const description = localizedStrings[AUTO_DELIVERY_INACTIVE_DESCRIPTION];
    const isMobileLayout = useSelector(selectIsMobileLayout);
    const redirect = () => dispatch(showAutoDeliveryStatusInactiveDetailsPopup());

    return (
        <AutoDeliveryWidgetContainer className={className}>
            <AutoDeliveryWidgetHeader btnLocalizationKey={AUTO_DELIVERY_LEARN_MORE_LINK} redirect={redirect} />
            <AutoDeliveryWidgetContainer.ContentContainer>
                <InfoBox className='mt-6 tracking-04 md:mt-0 md:items-start md:bg-transparent md:p-0'>
                    <div className='md:flex md:items-center md:justify-between'>
                        <div>
                            {title && <h4 className='mt-0 md:mb-0'>{title}</h4>}
                            {description && <div className='mt-2'>{description}</div>}
                        </div>
                        {!isMobileLayout && <AutoDeliveryInactiveButton quaternary className='mt-0 h-12 w-auto' />}
                    </div>
                    <AutoDeliveryStatusInactiveBenefits className='mt-8' />
                </InfoBox>
                {isMobileLayout && <AutoDeliveryInactiveButton primary light className='mt-8' />}
            </AutoDeliveryWidgetContainer.ContentContainer>
        </AutoDeliveryWidgetContainer>
    );
};

export default AutoDeliveryStatusInactive;
