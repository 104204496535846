import React from 'react';
import {useSelector} from 'react-redux';

import AemEditableText from '@/components/Aem/AemEditableText/AemEditableText';
import {ButtonRaw} from '@/components/Button';
import DeviceIcon, {MODES} from '@/components/DeviceIcon/DeviceIcon';
import {
    ArrowSmallIcon,
    EllipsisMenuIcon,
    MyDevicesIcon,
    OtherDeviceConnectedIcon,
    RenameBlueIcon,
} from '@/components/Icons';
import {ContentWrapper, MaxWidthContainer} from '@/components/Layout';
import {MY_DEVICES_PAGE_HEADER_TITLE} from '@/consts/localization/localizationKeys';
import advanceDiagnosticService from '@/containers/AdvancedDiagnosticPage/advanceDiagnosticService';
import DeviceImage from '@/containers/DeviceImage/DeviceImage';
import HomePageTopNavigation from '@/containers/HomePageTopNavigation/HomePageTopNavigation';
import useDocumentTitle from '@/hooks/useDocumentTitle';
import amplitudeDevicesPageTracking from '@/services/amplitude/amplitudeDevicesPageTracking';
import amplitudeDeviceTracking from '@/services/amplitude/amplitudeDeviceTracking';
import {HOME_PAGE_NAVIGATION_SOURCE_TYPES} from '@/services/homePageNavigationService';
import {getLocalizedStrings} from '@/services/localization/localizationService';
import productHelpers from '@/services/product/productHelpers';
import appRouterService from '@/services/route/appRouterService';
import {selectCurrentProductInfo} from '@/state/selectors/device';

import useOnConnectClick from '../../hooks/useOnConnectClick';
import DevicesListPopup from './DevicesListPopup/DevicesListPopup';
import useDevicesListPopup from './useDevicesListPopup';

const MyDevicesView = () => {
    const localizedStrings = getLocalizedStrings();
    const {isPopupOpened, openPopup, closePopup} = useDevicesListPopup();
    const device = useSelector(selectCurrentProductInfo);
    const {deviceSerialNumber, type} = device || {};
    const isDiagnosticEnabled = advanceDiagnosticService.isEnabled(type);
    const name = productHelpers.getProductName(device);
    const {onConnectClick} = useOnConnectClick(type);

    const onConnectButtonClick = () => {
        amplitudeDeviceTracking.trackPairDeviceClick(deviceSerialNumber, type);
        onConnectClick();
    };

    const onRenameClick = () => {
        appRouterService.forwardToDeviceRenamePage();
    };

    const onAboutClick = () => {
        amplitudeDevicesPageTracking.trackAboutMyDeviceScreen();
        appRouterService.forwardToAboutDevicePage();
    };

    const onAdClick = () => {
        appRouterService.forwardToADPage();
    };

    const onTipsAndTutorialsClick = () => {
        amplitudeDevicesPageTracking.trackTipsAndTutorialsScreen();
        appRouterService.forwardToTipsAndTutorialsList(type);
    };

    const onMoreDevicesClick = () => {
        openPopup();
    };

    useDocumentTitle(localizedStrings.formatString(localizedStrings[MY_DEVICES_PAGE_HEADER_TITLE], name));

    return (
        <ContentWrapper className='mx-4 items-start'>
            <MaxWidthContainer className='mx-auto w-full'>
                <HomePageTopNavigation
                    title={localizedStrings.formatString(localizedStrings[MY_DEVICES_PAGE_HEADER_TITLE], name)}
                    source={HOME_PAGE_NAVIGATION_SOURCE_TYPES.DEVICES}
                    profileControlsClassName='bg-romance'
                />
                <div className='flex w-full items-center justify-between mt-safe-offset-14'>
                    <h1 className='mb-0 overflow-hidden text-ellipsis text-center text-28 leading-[40px]'>{name}</h1>
                    <ButtonRaw className='shrink-0' onClick={onMoreDevicesClick}>
                        <EllipsisMenuIcon />
                    </ButtonRaw>
                </div>
                <DeviceImage
                    className='relative mt-8 aspect-square w-full max-w-[298px]'
                    device={device}
                    holder={device?.holder}
                    imgClassName='max-h-[298px] even:top-0 even:absolute'
                    ignoreRestrictedMarket
                    showHolder
                />
                <div className='mt-8 w-full rounded-xl bg-romance p-4'>
                    <MyDevicesButton
                        itemName='connectYourIqos'
                        icon={OtherDeviceConnectedIcon}
                        onClick={onConnectButtonClick}
                    />
                </div>
                <div className='mt-4 w-full rounded-xl bg-romance p-4'>
                    <MyDevicesButton itemName='renamemydevice' icon={RenameBlueIcon} onClick={onRenameClick} />
                    <MyDevicesButton itemName='aboutmydevice' mode={MODES.ABOUT} onClick={onAboutClick} />
                    {isDiagnosticEnabled && (
                        <MyDevicesButton itemName='diagnoseissue' mode={MODES.DIAGNOSTICS} onClick={onAdClick} />
                    )}
                    <MyDevicesButton
                        itemName='tipsandtutorials'
                        icon={MyDevicesIcon}
                        onClick={onTipsAndTutorialsClick}
                    />
                </div>
                {isPopupOpened && <DevicesListPopup onClose={closePopup} />}
            </MaxWidthContainer>
        </ContentWrapper>
    );
};

const MyDevicesButton = ({itemName, mode, icon: IconComponent, onClick}) => {
    return (
        <div className='w-full border-b border-mischka py-4 first:pt-0 last:border-0 last:pb-0'>
            <ButtonRaw className='flex w-full items-center justify-between gap-2' onClick={onClick}>
                {mode ? <DeviceIcon mode={mode} /> : <IconComponent />}
                <AemEditableText className='flex-1 text-14 font-bold' pageName='devices' itemName={itemName} />
                <ArrowSmallIcon />
            </ButtonRaw>
        </div>
    );
};

export default MyDevicesView;
