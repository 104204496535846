import React from 'react';

import {useGetAutoDeliveryDetailsExtended} from '../../api/consumerApi';
import AppLoader from '../../components/Loader/AppLoader/AppLoader';
import {AUTO_DELIVERY_STATUS_TYPES} from '../../consts/icc/iccAutoDeliveryStatusTypes';
import routerService from '../../services/route/routerService';
import StatusActive from './AutoDeliveryDetailsPages/StatusActive/StatusActive';
import StatusOnHold from './AutoDeliveryDetailsPages/StatusOnHold/StatusOnHold';
import StatusPaymentPending from './AutoDeliveryDetailsPages/StatusPaymentPending/StatusPaymentPending';

const getAutoDeliveryDetailsComponent = (status) => {
    switch (status) {
        case AUTO_DELIVERY_STATUS_TYPES.ACTIVE:
            return StatusActive;
        case AUTO_DELIVERY_STATUS_TYPES.PAYMENT_PENDING:
            return StatusPaymentPending;
        case AUTO_DELIVERY_STATUS_TYPES.ON_HOLD:
            return StatusOnHold;
        default:
            return null;
    }
};

const AutoDeliveryPage = () => {
    const {data, isLoading, isError} = useGetAutoDeliveryDetailsExtended();

    if (isLoading || !data) return <AppLoader />;
    if (isError) {
        routerService.goBack();
        return null;
    }

    const {
        autoDelivery: {status},
    } = data;

    const AutoDeliveryDetailsComponent = getAutoDeliveryDetailsComponent(status);

    if (!AutoDeliveryDetailsComponent) {
        routerService.goBack();
        return;
    }

    return <AutoDeliveryDetailsComponent />;
};

export default AutoDeliveryPage;
