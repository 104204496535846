import {useSelector} from 'react-redux';

import {selectIsMobileLayout} from '../state/selectors/global';

const useIsMobileLayout = () => {
    const isMobileLayout = useSelector(selectIsMobileLayout);

    return isMobileLayout;
};

export default useIsMobileLayout;
